import React, { Component } from 'react';
import ArrowDown from '../ArrowDown/ArrowDown';

interface MagicFilterButtonProps {
  className: string;
  filtersCount?: number;
  isDisabled?: boolean;
  onClick: () => void;
  styleDiv?:  React.CSSProperties | undefined;
  styleLabel?:  React.CSSProperties | undefined;
  styleParagraph?:  React.CSSProperties | undefined;
  styleArrow?:  React.CSSProperties | undefined;
}

class MagicFilterButton extends Component<MagicFilterButtonProps>
{
  render() {
    const {
      className,
      filtersCount,
      isDisabled,
      onClick,
      styleDiv,
      styleLabel,
      styleParagraph,
      styleArrow,
    } = this.props;

    const fCount = filtersCount && filtersCount > 0 ? filtersCount : null;

    return (
      <div
        className={className}
        style={styleDiv ?? {
          backgroundColor: isDisabled ? '#F2F2F2' : '#FFFFFF',
          color: '#000000',
          cursor: 'pointer !important',
          padding: '10px 8px 3px 10px',
        }}
        onClick={!isDisabled ? onClick : () => {}}
      >
        <label
          style={styleLabel ?? { marginLeft: '7px', display: 'flex', justifyContent: 'space-between' }}
        >
          <p style={styleParagraph ?? { fontWeight: 'bolder', margin: 0 }}>Magic Filters {fCount && `(${fCount})`}</p>
          <ArrowDown style={styleArrow ?? { color: '#CCCCCC', fontWeight: 'normal !important', marginLeft: '5px' }} />
        </label>
      </div>
    )
  }
}

export default MagicFilterButton;
