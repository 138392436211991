import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import AsyncLoader from './Common/AsyncLoader';
import { History, Location } from 'history';
import { getPartner } from '../services/partner/partnerApi';

interface NonAuthLayoutProps {
    location: Location;
    history: History;
}

class NonAuthLayout extends Component <NonAuthLayoutProps>{
    capitalizeFirstLetter = (string: string) => {
        return string.charAt(1).toUpperCase() + string.slice(2);
      };

    async componentDidMount() {
        const currentage = this.capitalizeFirstLetter(this.props.location.pathname);
        document.title = currentage;
        const partner = await getPartner();
        document.title = `${currentage} | ${partner?.name || 'Meetric'}`;
    }

    render() {
        return <React.Fragment>
            {this.props.children}
            <AsyncLoader />
        </React.Fragment>;
    }
}

export default withRouter<any, any>(NonAuthLayout);
