import destiny from "../../../assets/images/destiny.png";
import destinyLogo from "../../../assets/images/destiny-small.png";
import tele2 from "../../../assets/images/tele2.png";
import tele2Logo from "../../../assets/images/tele2LogoSmall.png";
import telia from "../../../assets/images/telia.png";
import teliaLogo from "../../../assets/images/teliaLogoSmall.png";
import teleproffs from "../../../assets/images/teleproffs.png";
import teleproffsLogo from "../../../assets/images/teleproffsLogoSmall.png";

import telavox from "../../../assets/images/telavox.png";
import telavoxLogo from "../../../assets/images/telavox-small.png";
import pingaway from "../../../assets/images/pingaway.png";
import pingawayLogo from "../../../assets/images/pingawayLogo.png";

import telink from "../../../assets/images/telinkLogo.png";
import telinkLogo from "../../../assets/images/telinkLogoSmall.png";
import lynes from "../../../assets/images/lynes.png";
import lynesLogo from "../../../assets/images/lynesLogoSmall.png";
import { getPartnerFromLocal } from '../../partner/partnerService';

export interface IntegrationConfiguration {
  type: string;
  name: string;
  smallLogo: any;
  mainLogo: any;
  imageAlt: string;
  imageWidth?: number;
  imageHeight?: number;
}

export interface TollringIntegrationConfiguration extends IntegrationConfiguration {
  type: 'destiny' | 'tele2' | 'telia' | 'teleproffs';
}

export interface LynesIntegrationConfiguration extends IntegrationConfiguration {
  type: 'lynes' | 'telink';
}

export interface TelavoxIntegrationConfiguration extends IntegrationConfiguration {
  type: 'telavox' | 'pingaway_no' | 'pingaway_se';
}

export const TOLLRING_INTEGRATION_TYPES = [
  'destiny',
  'tele2',
  'telia',
  'teleproffs',
];

export const LYNES_INTEGRATION_TYPES = [
  'lynes',
  'telink',
];

export const TELAVOX_INTEGRATION_TYPES = [
  'telavox',
  'pingaway_no',
  'pingaway_se',
];

export const TOLLRING_INTEGRATIONS: TollringIntegrationConfiguration[] = [
  {
    type: 'destiny',
    name: 'Dstny',
    smallLogo: destinyLogo,
    mainLogo: destiny,
    imageAlt: 'Dstny',
    imageHeight: 70,
    imageWidth: 200,
  },
  {
    type: 'tele2',
    name: 'Tele2',
    smallLogo: tele2Logo,
    mainLogo: tele2,
    imageAlt: 'Tele2',
    imageHeight: undefined,
    imageWidth: 100,
  },
  {
    type: 'telia',
    name: 'Telia',
    smallLogo: teliaLogo,
    mainLogo: telia,
    imageAlt: 'Telia',
    imageHeight: undefined,
    imageWidth: 200,
  },
  {
    type: 'teleproffs',
    name: 'Teleproffs',
    smallLogo: teleproffsLogo,
    mainLogo: teleproffs,
    imageAlt: 'Teleproffs',
    imageHeight: undefined,
    imageWidth: 200,
  },
];

export const TELAVOX_INTEGRATIONS: TelavoxIntegrationConfiguration[] = [
  {
    type: 'pingaway_se',
    name: 'Pingaway (SE)',
    smallLogo: pingawayLogo,
    mainLogo: pingaway,
    imageAlt: 'Pingaway',
    imageWidth: 200,
  },
  {
    type: 'pingaway_no',
    name: 'Pingaway (NO)',
    smallLogo: pingawayLogo,
    mainLogo: pingaway,
    imageAlt: 'Pingaway',
    imageWidth: 200,
  },
  {
    type: 'telavox',
    name: 'Telavox',
    smallLogo: telavoxLogo,
    mainLogo: telavox,
    imageAlt: 'Telavox',
    imageWidth: 200,
  },
]

export const LYNES_INTEGRATIONS: LynesIntegrationConfiguration[] = [
  {
    type: 'telink',
    name: 'Telink',
    smallLogo: telinkLogo,
    mainLogo: telink,
    imageAlt: 'Telink',
    imageHeight: undefined,
    imageWidth: 200,
  }, {
    type: 'lynes',
    name: 'Lynes',
    smallLogo: lynesLogo,
    mainLogo: lynes,
    imageAlt: 'Lynes',
    imageHeight: undefined,
    imageWidth: 200,
  },
]

export const isIntegrationBlacklisted = (integration: string): boolean => {
  const partner = getPartnerFromLocal();

  if (
    !partner
    || !partner.integrationsBlacklist
    || partner.integrationsBlacklist.length === 0
  ) {
    return false;
  }

  return !!partner.integrationsBlacklist.find((i) => i === integration);
}

export const getDefaultTollringIntegration = () => {
  return TOLLRING_INTEGRATIONS.find(
    integration => !isIntegrationBlacklisted(integration.type),
  ) || null;
};

export const areAllCalendarAndCRMBlacklisted = (): boolean => {
  const partner = getPartnerFromLocal();

  if (
    !partner
    || !partner.integrationsBlacklist
    || partner.integrationsBlacklist.length === 0
  ) {
    return false;
  }

  const requiredIntegrations = [
    'google_calendar',
    'outlook',
    'hubspot',
    'pipedrive',
    'salesforce',
    'zoho',
    'dynamics365'
  ];

  return requiredIntegrations.every(integration =>
    partner.integrationsBlacklist!.includes(integration)
  );
}

export const areAllDialersBlacklisted = (): boolean => {
  const partner = getPartnerFromLocal();

  if (
    !partner
    || !partner.integrationsBlacklist
    || partner.integrationsBlacklist.length === 0
  ) {
    return false;
  }

  const requiredIntegrations = [
    'adversus',
    's2',
    'telink',
    'lynes',
    'telenor',
    'destiny',
    'tele2',
    'telia',
    'teleproffs',
    'telavox',
    'pingaway_no',
    'pingaway_se',
    'infracom',
    'hitrate',
    'aircall',
  ];

  return requiredIntegrations.every(integration =>
    partner.integrationsBlacklist!.includes(integration)
  );
}

export const isTollringIntegration = (integration: string): boolean => {
  return !!TOLLRING_INTEGRATION_TYPES.find((i) => i === integration);
}

export const getTollringIntegration = (
  integration: string
): TollringIntegrationConfiguration | undefined => {
  return TOLLRING_INTEGRATIONS.find((i) => i.type === integration);
}

export const isLynesIntegration = (integration: string): boolean => {
  return !!LYNES_INTEGRATION_TYPES.find((i) => i === integration);
}

export const getLynesIntegration = (
  integration: string
): LynesIntegrationConfiguration | undefined => {
  return LYNES_INTEGRATIONS.find((i) => i.type === integration);
}

export const isTelavoxIntegration = (integration: string): boolean => {
  return !!TELAVOX_INTEGRATION_TYPES.find((i) => i === integration);
}

export const getTelavoxIntegration = (
  integration: string
): TelavoxIntegrationConfiguration | undefined => {
  return TELAVOX_INTEGRATIONS.find((i) => i.type === integration);
}

export const getBlacklistId = (integration: string) => {
  return `blacklist-id=${integration}`;
}

