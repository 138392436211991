import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { IGlobalFilters } from '../Widgets/utils/constants';
import { getRecordingsWithTranscript } from '../../services/userservice';
import { OptionType, RecordingBasicData } from './constants';
import { formatIsoDate } from '../../services/utilities/utilservice';
import { IAIAgent, ICreateAIAgent } from '../../store/AIAgent/interface';
import { cancellable } from '../../utilities/helperFunctions';

interface RecordingsSearcherProps {
  accountId: string;
  aiAgent?: ICreateAIAgent|IAIAgent;
  globalFilters?: IGlobalFilters;
  sessionId?: string;
  isClearable?: boolean;
  placeholder?: string;
  customStyles?: any;
  classNamePrefix?: string;
  onSessionChange: (sessionId: string) => void;
  onOpenClose: (isOpen: boolean) => void;
}

interface RecordingsSearcherState {
  selectedSessionId?: string;
  selectedOption?: OptionType | null;
  cancelPrevious?: Function | null;
}

class RecordingsSearcher extends Component<RecordingsSearcherProps, RecordingsSearcherState> {
  constructor(props: RecordingsSearcherProps) {
    super(props);
    this.state = {
      selectedOption: null,
    };

    this.fetchSelectedOption();
  }

  componentDidUpdate(prevProps: RecordingsSearcherProps) {
    if (
      prevProps.sessionId !== this.props.sessionId
      || prevProps.aiAgent?.type !== this.props.aiAgent?.type
    ) {
      this.fetchSelectedOption();
    }
  }

  fetchSelectedOption = async () => {
    const { aiAgent, sessionId, accountId, globalFilters } = this.props;

    try {
      this.state.cancelPrevious?.();
      const { resultPromise, cancel } = cancellable(getRecordingsWithTranscript(accountId, globalFilters, sessionId, '', aiAgent?.type));
      this.setState({ cancelPrevious: cancel });

      const recordingList: RecordingBasicData[] = await resultPromise;
      const options = this.createOptionsFromRecordingList(recordingList);
      const selectedOption = options.find(option => option.value === sessionId) || null;
      this.setState({ selectedOption });
    } catch (error: any) {
      console.error('Error fetching recordings:', error);
    }
  };

  handleChange = (selectedOption: OptionType | null) => {
    if (selectedOption) {
      this.setState({ selectedOption });
      this.props.onSessionChange(selectedOption.value);
    } else {
      this.setState({ selectedOption: null });
      this.props.onSessionChange('');
    }
  };

  _loadOptions = (inputValue: string, callback: (options: OptionType[]) => void) => {
    const { selectedSessionId } = this.state;
    const { accountId, aiAgent, globalFilters } = this.props;

    getRecordingsWithTranscript(accountId, globalFilters, selectedSessionId, inputValue, aiAgent?.type)
      .then((recordingList: RecordingBasicData[]) => {
        const options = this.createOptionsFromRecordingList(recordingList);
        callback(options);
      })
      .catch((error: any) => {
        console.error('Error fetching recordings:', error);
        callback([]);
      });
  };

  loadOptions = debounce(this._loadOptions, 500);

  createOptionsFromRecordingList = (recordings: RecordingBasicData[]): OptionType[] => {
    return recordings.map((recording) => {
      const formattedDate = formatIsoDate(new Date(recording.created_at));
      return { value: recording.session_id, label: `${recording.name} | ${formattedDate}` };
    });
  };

  customStyles = {
    menu: (provided: any) => ({
      ...provided,
      maxHeight: '300px',
      overflowY: 'auto',
      marginTop: '0',
      width: 'unset',
      minWidth: '450px',
      zIndex: 10000,
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '300px',
    }),
    container: (provided: any) => ({
      ...provided,
      maxWidth: '100%',
    }),
    ...(this.props.customStyles || {}),
  };

  render() {
    return (
      <AsyncSelect
        loadOptions={this.loadOptions}
        placeholder={this.props.placeholder || 'Search by meeting name, users, participants...'}
        onChange={this.handleChange}
        defaultOptions
        isClearable={this.props.isClearable}
        value={this.state.selectedOption}
        styles={this.customStyles}
        menuPlacement="auto"
        classNamePrefix={this.props.classNamePrefix}
        onMenuOpen={() => this.props.onOpenClose(true)}
        onMenuClose={() => this.props.onOpenClose(false)}
      />
    );
  }
}

export default RecordingsSearcher;
