import axios from "axios";
import { isEmpty } from "lodash";

import { endpoints } from "../utilities/endpoints";
import { urls } from "../utilities/urls";
import { commonheaders } from "../utilities/apiConfig";
import {
  FilterObject,
  IAccount,
  IBranding,
  IFiles,
  IMeetingCalendarSyncData as IMeetingCalendarSyncInfoData,
  IRecordingTranscriptFilters,
  IUser,
  SendResourcesData,
  SendRoomData,
} from "../store/interface";
import { Filters } from "../store/layout/interface";
import { IntegrationType } from "../store/settings/Integration/interface";
import {
  GROUP_VALUE,
  IGlobalFilters,
  IGlobalInterval,
  TOTAL,
} from "../components/Widgets/utils/constants";
import { IWidgetLayout } from "../components/Widgets/utils/IWidget";
import { createMeetingQueryStringFromParams } from "../components/GlobalFilters/utils/helperfunctions";
import { STATISTIC_WIDGET_TYPES } from '../pages/Home/utils/constants';
import { ParamsType } from './widgets/interface';
import { RecordingBasicData } from '../components/RecordingsSearcher/constants';
import { ICalendarFilters, IMeetingBot } from '../store/settings/Calendars/interface';
import { mapKeysToCamelCase } from "../components/AiChat/Chat/utils/helperFunctions";
import { InfracomToken } from "../pages/Settings/Integrations";
import { CalendarData, Event } from "../pages/Calendars/interface";
import {formatDate} from '../pages/Calendars/helperFunctions';
import { VARIABLE_WIDGET } from '../components/Widgets/WidgetLayoutItem/constants';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  count?: number;
  headers?: any;
  request?: any;
  status?: number;
  error?: any;
  errors?: any;
  statusText?: string;
  JWT?: String;
  page?: number;
  maxPage?: number;
  total?: number;
  URL?: string;
}

export function* registerUser(user: Object) {
  const registerApi: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.register}`,
      JSON.stringify(user),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return registerApi;
}

export function* registerMultipleUsers(payload: Object) {
  const registerMultipleApi: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.editAccountDetails}/create-multiple-users`,
      JSON.stringify(payload),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return registerMultipleApi;
}

export function* sendActivationEmail(payload: IUser[]) {
  const sendActivationEmailApi: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.getUsers}/resendInvitation`,
      JSON.stringify({
        users: payload.map((item) => {
          return { id: item.id };
        }),
      }),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return sendActivationEmailApi;
}

export function* getPresentations(id: String, filter: String) {
  if (filter) {
    const response: ResponseGenerator = yield axios
      .get(
        `${urls.baseApiURL}${endpoints.getUsers}/${id}/${endpoints.getPresentations}?${filter}`,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    return response;
  } else {
    const response: ResponseGenerator = yield axios
      .get(
        `${urls.baseApiURL}${endpoints.getUsers}/${id}/${endpoints.getPresentations}`,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    return response;
  }
}

export function* setPresentation(presentation: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.setPresentations}`,
      JSON.stringify(presentation),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* setImportPresentation(
  data: {
    file: File;
    folder_id: null | string;
  },
  id: String
) {
  var formData = new FormData();
  formData.append("file", data.file);
  if (data?.folder_id !== null) {
    formData.append("folder_id", data?.folder_id!);
  }
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.getTeams}/${id}/${endpoints.import}`,
      formData,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* editPresentation(presentation: Object, pid: String) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.setPresentations}/${pid}`,
      JSON.stringify(presentation),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* duplicatePresentation(data: Object, presentation: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.setPresentations}/${presentation}/${endpoints.duplicatePresentation}`,
      JSON.stringify({ data }),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* removePresentation(presentation: String) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}${endpoints.removePresentations}/${presentation}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getCurrentUserDetails() {
  const currentDetail: ResponseGenerator = yield axios
    .get(`${urls.baseApiURL}${endpoints.verifyUser}`)

    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return currentDetail;
}

export function* editUserProfile(user: Object, id: String) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.editUserProfile}/${id}`,
      JSON.stringify(user),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* changeProfilePassword(passwordData: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.changeUserProfilePassword}`,
      JSON.stringify(passwordData),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* deleteUserProfile(accountID: String, params: Object) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountID}/${endpoints.editUserProfile}`,
      { data: JSON.stringify(params) }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* createUserProfile(user: Object, id: String) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.createUserProfile}`,
      JSON.stringify(user),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* setProfilePic(pic: any) {
  var formData = new FormData();
  formData.append("file", pic.file);
  formData.append("type", pic.type);
  let customURL = `${urls.baseApiURL}${endpoints.files}?account_id=${pic.accountId}`;
  if (pic.folder_id) {
    customURL = `${customURL}&folder_id=${pic.folder_id}`;
  }
  const response: ResponseGenerator = yield axios
    .post(customURL, formData, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getFilesByUser(filter: String) {
  const response: ResponseGenerator = yield axios
    .get(`${urls.baseApiURL}${endpoints.files}?${filter}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* deleteFile(id: String) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const response: ResponseGenerator = yield axios
    .delete(`${urls.baseApiURL}${endpoints.files}/${id}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* editFile(id: String, data: IFiles) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.files}/${id}`,
      JSON.stringify(data),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getAccountDetails(id: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.getAccountDetails}/${id}/accounts`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}


export function* getDealStages(accountId: string) {
  const response: ResponseGenerator = yield axios
    .get(`${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.dealStages}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getAllAccountsDetails() {
  const response: ResponseGenerator = yield axios
    .get(`${urls.baseApiURL}${endpoints.editAccountDetails}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* setAccountBranding(
  id: String,
  data: {
    background: string;
    logo: string;
  },
  isMetting: Boolean
) {
  var formData = new FormData();
  data.background && formData.append("background", data.background);
  data.logo && formData.append("logo", data.logo);
  if (isMetting) {
    const response: ResponseGenerator = yield axios
      .post(
        `${urls.baseApiURL}${endpoints.meetings}/${id}/${endpoints.brand}`,
        formData,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    return response;
  } else {
    const response: ResponseGenerator = yield axios
      .post(
        `${urls.baseApiURL}${endpoints.branding}/${id}`,
        formData,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    return response;
  }
}

export function* deleteAccountBrandingImage(
  id: String,
  data: Object,
  isMetting: Boolean
) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  if (isMetting) {
    const response: ResponseGenerator = yield axios
      .delete(
        `${urls.baseApiURL}${endpoints.meetings}/${id}/${endpoints.brand}?${data}`,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    return response;
  } else {
    const response: ResponseGenerator = yield axios
      .delete(
        `${urls.baseApiURL}${endpoints.branding}/${id}?${data}`,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    return response;
  }
}

export function* editAccountDetails(account: IAccount, id: String) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.editAccountDetails}/${id}`,
      JSON.stringify(account),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* createAccountDetails(account: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.editAccountDetails}`,
      JSON.stringify(account),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getTeams(id: String) {
  const response: ResponseGenerator = yield axios
    .get(`${urls.baseApiURL}${endpoints.getTeams}/${id}/teams`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getFilterTeams(id: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.getTeams}/${id}/filter-teams`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getAssociatedUsersService(id: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${id}/associated-users`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getAssociatedTeamsService(id: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${id}/associated-teams`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getManagedTeams(id: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.getUsers}/${id}/${endpoints.managedTeam}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* addManagerToTeam(teamId: String, userId: String) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.createTeam}/${teamId}/${endpoints.manager}/${userId}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* removeUserFromTeam(teamId: String, userId: String) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}${endpoints.createTeam}/${teamId}/${endpoints.manager}/${userId}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* createNewTeam(team: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.createTeam}`,
      JSON.stringify(team),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* editTeam(teamId: String, team: Object) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.createTeam}/${teamId}`,
      JSON.stringify(team),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getResources(id: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.resources}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* createNewResource(
  accountId: String,
  resource: SendResourcesData
) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.resources}`,
      JSON.stringify(resource),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* editResource(
  accountId: String,
  resourceId: String,
  resource: SendResourcesData
) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.resources}/${resourceId}`,
      JSON.stringify(resource),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* deleteResource(accountId: String, resourceId: String) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.resources}/${resourceId}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getSharedLinks(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.rooms}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.rooms}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getSharedLinksDaily(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.roomsDaily}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.roomsDaily}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* shareRecordingService(payload: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}recording-share-links`,
      JSON.stringify(payload),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* getRecordingShareLinksForRecording(
  recordingSessionId: string
) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}recording-share-links/recording/${recordingSessionId}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* getRecordingShareLinkByLinkHash(linkHash: string) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}recording-share-links/link-hash/${linkHash}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* updateRecordingShareLink(id: string, payload: Object) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}recording-share-links/${id}`,
      JSON.stringify(payload),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* deleteRecordingShareLink(recordingShareLinkId: string) {
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}recording-share-links/${recordingShareLinkId}`,
      commonheaders
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* getRecordingSnippets(recordingSessionId: string) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}recordings/${recordingSessionId}/snippets/`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* getSnippet(recordingSessionId: string) {
  const response: ResponseGenerator = yield axios
    .get(`${urls.baseApiURL}snippets/${recordingSessionId}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* postRecordingSnippet(sessionId: string, payload: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}recordings/${sessionId}/snippets/`,
      JSON.stringify(payload),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* createRecordingSnippet(
  recordingSessionId: string,
  payload: Object
) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}recordings/${recordingSessionId}/snippets/`,
      JSON.stringify(payload),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* putSnippet(snippetId: string, payload: Object) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}snippets/${snippetId}`,
      JSON.stringify(payload),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* getSnippetShareLinksForSnippet(snippetUuid: string) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}snippet-share-links/snippet/${snippetUuid}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* getSnippetShareLinkByLinkHash(linkHash: string) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}snippet-share-links/link-hash/${linkHash}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* createSnippetShareLink(payload: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}snippet-share-links/`,
      JSON.stringify(payload),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* updateSnippetShareLink(id: string, data: Object) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}snippet-share-links/${id}`,
      JSON.stringify(data),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* deleteSnippetShareLink(id: string) {
  const response: ResponseGenerator = yield axios
    .delete(`${urls.baseApiURL}snippet-share-links/${id}`, commonheaders)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* getStatsRoomSessions(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.roomSessions}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.roomSessions}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsRoomSessionsDaily(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.roomSessionsDaily}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.roomSessionsDaily}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getRecordingsService(id: String) {
  const response: ResponseGenerator = yield axios
    .get(`${urls.baseApiURL}${endpoints.recordings}/${id}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* deleteRecordingService(id: String) {
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}${endpoints.deleterecordings}/${id}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* addEmptySlide(id: String) {
  let request = {
    name: "Slide 1",
    presentation_id: id,
    slide_position: 1,
    width: 100,
    height: 100,
    slide_content: {
      elements: {},
    },
  };

  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.addSlides}`,
      JSON.stringify(request),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getStatsUser(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.user}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.user}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };

  return finalResponse;
}

export async function getUserTeamCompareWidgetData(
  id: String,
  widgetType: string,
  filters: Filters,
  globalFilters: IGlobalFilters
) {
  try {
    const filtersObject = {
      group: filters.groupBy,
      period: filters.period ?? "",
    };

    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(filtersObject)) {
      if (value) {
        params.append(key, value);
      }
    }

    for (const [key, value] of Object.entries(globalFilters)) {
      if (key === "global_period" || key === "queryBuilderObject") {
        continue;
      }

      if (!isEmpty(value)) {
        if (key === "global_start" || key === "global_end") {
          params.append(key, value?.toString());
        } else if (Array.isArray(value)) {
          value.forEach((item) => {
            params.append(`${key}[]`, item);
          });
        } else {
          params.append(key, value);
        }
      }
    }

    const specificCallOutcomes = filters.specificCallOutcomes;
    if (
      Array.isArray(specificCallOutcomes) &&
      specificCallOutcomes.length > 0
    ) {
      specificCallOutcomes.forEach((outcome) => {
        if (outcome) {
          params.append("specific_call_outcomes[]", outcome);
        }
      });
    }

    const specificDealStatus = filters.specificDealStatuses;
    if (
      Array.isArray(specificDealStatus) &&
      specificDealStatus.length > 0
    ) {
      specificDealStatus.forEach((deal) => {
        if (deal) {
          params.append("specific_deal_statuses[]", deal);
        }
      });
    }

    if (globalFilters.queryBuilderObject) {
      params.append('queryBuilderObject', JSON.stringify(globalFilters.queryBuilderObject));
    }

    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.analyticStats}?type=${widgetType}`,
      {
        params,
        // @ts-ignore
        headers: commonheaders,
      }
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      console.log(error.response.data);
    } else {
      console.error(error);
      throw error;
    }
  }
}

export function* getStatsUserDaily(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.userDaily}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.userDaily}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsTeam(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.team}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.team}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsTeamDaily(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.teamDaily}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.teamDaily}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsMeetings(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.meetings}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.meetings}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsMeetingsCount(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.meetings}-total?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.meetings}-total`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsMeetingsDaily(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.meetingsDaily}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.meetingsDaily}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsMeetingsLive(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.meetingsLive}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.meetingsLive}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsInteractions(id: String, filter: String) {
  if (filter) {
    const response: ResponseGenerator = yield axios
      .get(
        `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.interactions}?${filter}`,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    const finalResponse = {
      data: response,
    };
    return finalResponse;
  } else {
    const response: ResponseGenerator = yield axios
      .get(
        `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.interactions}`,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    const finalResponse = {
      data: response,
    };
    return finalResponse;
  }
}

export function* getStatsInteractionsDaily(id: String, filter: String) {
  if (filter) {
    const response: ResponseGenerator = yield axios
      .get(
        `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.interactionsDaily}?${filter}`,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    const finalResponse = {
      data: response,
    };
    return finalResponse;
  } else {
    const response: ResponseGenerator = yield axios
      .get(
        `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.interactionsDaily}`,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    const finalResponse = {
      data: response,
    };
    return finalResponse;
  }
}

export function* getStatsParticipants(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.participants}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.participants}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsParticipantsDaily(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.participantsDaily}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.participantsDaily}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsMembers(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.members}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.members}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsMembersDaily(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.membersDaily}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.membersDaily}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsContacts(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.contacts}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.contacts}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsContactsDaily(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.contactsDaily}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.contactsDaily}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsCompanies(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.companies}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.companies}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsCompaniesDaily(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.companiesDaily}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.companiesDaily}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsPresentations(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.documents}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.documents}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsPresentationsDaily(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.documentsDaily}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.documentsDaily}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsSlides(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.pages}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.pages}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsSlidesDaily(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.pagesDaily}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.pagesDaily}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getStatsViews(id: String, filter: String) {
  let statsViewURL = `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.view}`;

  if (filter) {
    statsViewURL = `${statsViewURL}?${filter}`;
  }
  const response: ResponseGenerator = yield axios
    .get(statsViewURL, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* setAddUserToTeam(teamId: String, userIds: Object[]) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.createTeam}/${teamId}/${endpoints.getUsers}`,
      JSON.stringify({ userIds: userIds }),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getUserByAccount(id: String) {
  const response: ResponseGenerator = yield axios
    .get(`${urls.baseApiURL}${endpoints.getTeams}/${id}/users`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getFilterUsersService(id: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.getTeams}/${id}/filter-users`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* userRole(accountId: String, userId: String, params: Object) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.getAccountDetails}/${userId}/${endpoints.role}`,
      JSON.stringify(params),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getCategories(id: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.editAccountDetails}/${id}/${endpoints.categories}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* userSetPassword(
  token: String,
  value: Object,
  pathString: string
) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${
        pathString === "/setPassword"
          ? endpoints.register
          : endpoints.forgotPassword
      }/${token}`,
      JSON.stringify(value),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* createAccountRights(value: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.accountRights}`,
      JSON.stringify(value),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* updateARights(value: Object, rightId: String) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.accountRights}/${rightId}`,
      JSON.stringify(value),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getRights(id: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accountRights}?account_id=${id}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* deleteRights(id: String) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const response: ResponseGenerator = yield axios
    .delete(`${urls.baseApiURL}${endpoints.accountRights}/${id}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getFonts(id: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.font}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* uploadFonts(id: String, fontFile: File) {
  var formData = new FormData();
  formData.append("file", fontFile);
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.font}`,
      formData,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* editFonts(id: String, params: Object, fontId: String) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.font}/${fontId}`,
      JSON.stringify(params),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export async function getMeetings(
  id: String,
  data: FilterObject,
  meetingType?: string,
  limit?: number,
  query?: string
) {
  const globalFilterString = createMeetingQueryStringFromParams(data);
  const queryString = `${!isEmpty(query) ? `query=${query}&` : ""}`;

  try {
    const url = `${urls.baseApiURL}${endpoints.getUsers}/${id}/${endpoints.meetings}?time=${meetingType}&limit=${limit}&${queryString}${globalFilterString}`;

    const response = await axios.get(url, {
      // @ts-ignore
      headers: {
        ...commonheaders,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error.message;
  }
}

export async function getTodaysMeetings(userId: string) {
  const today = new Date().toISOString().substring(0, 10);
  try {
    const url = `${urls.baseApiURL}${endpoints.getUsers}/${userId}/${endpoints.meetings}`
      + `?start_time=${today}&end_time=${today}&users[]=${userId}&include_without_bot=1`;

    const response = await axios.get(url, {
      // @ts-ignore
      headers: {
        ...commonheaders,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error.message;
  }
}

export function* getMeetingTypes(
  accountId: String,
  options?: { disabled: Boolean; teams: Boolean }
) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.meetingTypes}`,
      {
        // @ts-ignore
        headers: commonheaders,
        params: {
          disabled: options?.disabled || false,
          teams: options?.teams || false,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export async function getCampaign(accountId: String) {
  try {
    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.campaigns}`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export function* getDefaultMeetingTypes(accountId: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.defaultMeetingTypes}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* createNewMeeting(accountId: String, meeting: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.meetings}`,
      JSON.stringify(meeting),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* createNewMeetingType(accountId: String, meetingType: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.meetingTypes}`,
      JSON.stringify(meetingType),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return false;
    });
  return response;
}

export async function editRecordingType(
  accountId: String,
  meetingTypeId: String,
  sessionId: String
) {
  try {
    const response: ResponseGenerator = await axios.put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/session/${sessionId}/${endpoints.meetingType}/${meetingTypeId}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export function* editMeetingType(
  accountId: String,
  meetingTypeId: String,
  meetingType: Object
) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.meetingTypes}/${meetingTypeId}`,
      JSON.stringify(meetingType),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return false;
    });
  return response;
}

export function* editMeeting(
  accountId: String,
  meetingId: String,
  meeting: Object
) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.meetings}/${meetingId}`,
      JSON.stringify(meeting),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

// TODO: fix this insanity and keep generators restricted to sagas
export async function editMeetingAsync(
  accountId: string,
  meetingId: string,
  meeting: Object
) {
  const { data } = await axios
    .put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.meetings}/${meetingId}`,
      JSON.stringify(meeting),
      commonheaders
    );
  return data;
}

export async function updateMeetingParticipants(
  meetingId: string,
  participants: any[],
  fromStart?: boolean
) {
  const { data } = await axios
    .put(
      `${urls.baseApiURL}${endpoints.meetings}/${meetingId}/receivers${fromStart ? '-from-start' : ''}`,
      JSON.stringify(participants),
      commonheaders
    );
  return data;
}

export function* deleteMeeting(accountId: String, meetingId: String) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.meetings}/${meetingId}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getBranding(id: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.branding}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* createNewBranding(accountId: String, branding: IBranding) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.branding}`,
      JSON.stringify(branding),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* editBranding(
  accountId: String,
  brandingId: String,
  branding: IBranding
) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.branding}/${brandingId}`,
      JSON.stringify(branding),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* deleteBranding(accountId: String, brandingId: String) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.branding}/${brandingId}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getRooms(id: String, filter: String) {
  if (filter) {
    const response: ResponseGenerator = yield axios
      .get(
        `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.rooms}?query=${filter}`,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    return response;
  } else {
    const response: ResponseGenerator = yield axios
      .get(
        `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.rooms}`,
        commonheaders
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
    return response;
  }
}

export function* createNewRoom(accountId: String, room: SendRoomData | File) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.rooms}`,
      JSON.stringify(room),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* editRoom(
  accountId: String,
  roomId: String,
  room: SendRoomData | File
) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.rooms}/${roomId}`,
      JSON.stringify(room),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* deleteRoom(accountId: String, roomId: String) {
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.rooms}/${roomId}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* duplicateRoom(roomId: string) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.rooms}/${endpoints.duplicateRoom}`,
      JSON.stringify({ room_id: roomId }),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* createRoomFromTemplateService(roomId: string) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.rooms}/${endpoints.create}`,
      JSON.stringify({ template_id: roomId }),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getFolder(data: { id: String; query: String }) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.folders}?account_id=${data.id}&query=${data.query}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* moveFolders(data: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.folders}/${endpoints.move}`,
      JSON.stringify(data),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getUserFolders() {
  const response: ResponseGenerator = yield axios
    .get(`${urls.baseApiURL}${endpoints.userFolder}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getCustomFolders(id: String, folderId: String) {
  const response: ResponseGenerator = yield axios
    .get(`${urls.baseApiURL}${endpoints.folders}/${folderId}?account_id=${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* uploadFolder(folder: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.folders}`,
      JSON.stringify(folder),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* editFolder(id: String, folder: Object) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.folders}/${id}`,
      JSON.stringify(folder),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* removeFolder(id: String) {
  const response: ResponseGenerator = yield axios
    .delete(`${urls.baseApiURL}${endpoints.folders}/${id}`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* createRights(value: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.rights}`,
      JSON.stringify(value),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getPresentationRights(params: any) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${params?.id}/${endpoints.rights}?object_id=${params.presentationId}&right_type=${params.type}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* updateRights(value: Object, rightId: String) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.rights}/${rightId}`,
      JSON.stringify(value),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* removeRights(rightId: String, right_type: String) {
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}${endpoints.rights}/${rightId}?right_type=${right_type}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* createAReceivers(receiverData: Object) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.receivers}`,
      JSON.stringify(receiverData),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* integrateMicrofsoftDynamics(receiverData: any) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.microsoftIntegration}`,
      JSON.stringify(receiverData),
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getTimeLine(accountId: String, sessionId: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.sessions}/${sessionId}/${endpoints.timeline}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getTimeLineComments(accountId: String, sessionId: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.sessions}/${sessionId}/${endpoints.timeline}/${endpoints.comments}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* deleteTimeLineComment(
  commentId: String,
  resolve: any,
  reject: any
) {
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}${endpoints.timeline}/${endpoints.comments}/${commentId}`,
      commonheaders
    )
    .then((response) => {
      resolve("Success");
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        reject(error.response.data);
        return error.response.data;
      }
      reject(error);
      return error;
    });
  return response;
}

export function* deleteSession(
  accountId: String,
  sessionId: String,
  resolve: any,
  reject: any
) {
  const response: ResponseGenerator = yield axios
    .delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.stats}/${endpoints.meetings}/${sessionId}`,
      commonheaders
    )
    .then((response) => {
      resolve(response.data);
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        reject(error.response.data);
        return error.response.data;
      }
      reject(error);
      return error;
    });
  return response;
}

export function* addTimeLineComment(
  accountId: String,
  sessionId: String,
  comment: Object,
  resolve: any,
  reject: any
) {
  const response: ResponseGenerator = yield axios
    .put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.sessions}/${sessionId}/${endpoints.timeline}/${endpoints.comments}`,
      JSON.stringify(comment),
      commonheaders
    )
    .then((response) => {
      resolve(response.data);
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        reject(error.response.data);
        return error.response.data;
      }
      reject(error);
      return error;
    });
  return response;
}

export function* getSnippetTimeLine(accountId: string, snippetUuid: string) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.snippets}/${snippetUuid}/timeline`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* getMeetingAIAnalytics(accountId: String, sessionId: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.sessions}/${sessionId}/${endpoints.meetingAIAnalytics}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getReceiver(accountId: String, receiverId: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.receivers}/${receiverId}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* deleteMultipleDocs(
  presentationIds: string[],
  filesIds: string[]
) {
  let data = {
    presentation_ids: presentationIds,
    file_ids: filesIds,
  };
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const response: ResponseGenerator = yield axios
    .delete(`${urls.baseApiURL}${endpoints.folders}/${endpoints.documents}`, {
      data: JSON.stringify(data),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* loginPipedrive() {
  const response: ResponseGenerator = yield axios
    .get(`${urls.baseApiURL}${endpoints.pipedrive}/login`, commonheaders)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* removeIntegration(integrationType: IntegrationType) {
  const response: ResponseGenerator = yield axios
    .post(
      `${urls.baseApiURL}${endpoints.getUsers}/${endpoints.integration}/remove`,
      JSON.stringify({ integration: integrationType }),
      commonheaders
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export async function removeIntegrationAsync(integrationType: IntegrationType) {
  const response: ResponseGenerator = await axios
    .post(
      `${urls.baseApiURL}${endpoints.getUsers}/${endpoints.integration}/remove`,
      JSON.stringify({ integration: integrationType }),
      commonheaders
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export async function updateIntegration(
  integrationId: string,
  importCalls: boolean
) {
  const response: ResponseGenerator = await axios
    .put(
      `${urls.baseApiURL}${endpoints.getUsers}/${endpoints.integration}/${integrationId}`,
      JSON.stringify({ import_calls: importCalls }),
      commonheaders
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export function* createWidgetLayout(data: Object, accountId: String) {
  const response: ResponseGenerator = yield axios.post(
    `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.widgetLayouts}`,
    JSON.stringify(data),
    commonheaders
  );

  return response;
}

export async function createLayout(data: IWidgetLayout, accountId: String) {
  const response: ResponseGenerator = await axios.post(
    `${urls.baseApiURL}${endpoints.accounts}/${accountId}/dashboard`,
    JSON.stringify(data),
    commonheaders
  );

  return response.data;
}

export function* getLogs(data: {
  user_id?: string;
  actions?: string[];
  action_types?: string[];
  target_ids?: string[];
  after_date?: string;
  before_date?: string;
}) {
  const response: ResponseGenerator = yield axios.get(
    `${urls.baseApiURL}${endpoints.logs}`,
    {
      ...commonheaders,
      params: {
        user_id: data.user_id,
        actions: data.actions,
        action_types: data.action_types,
        target_ids: data.target_ids,
        after_date: data.after_date,
        before_date: data.before_date,
      },
    }
  );

  return response;
}

export function* getUserCompareViewData(accountId: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.stats}/${endpoints.user}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.stats}/${endpoints.user}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* getTeamCompareViewData(id: String, filter: String) {
  const response: ResponseGenerator = yield axios
    .get(
      filter
        ? `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.team}?${filter}`
        : `${urls.baseApiURL}${endpoints.accounts}/${id}/${endpoints.stats}/${endpoints.team}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  const finalResponse = {
    data: response,
  };
  return finalResponse;
}

export function* createLog(data: Object) {
  const response: ResponseGenerator = yield axios.post(
    `${urls.baseApiURL}${endpoints.logs}`,
    JSON.stringify(data),
    commonheaders
  );

  return response;
}

export function* getRecordingTranscript(
  sessionId: String,
  filters?: IRecordingTranscriptFilters
) {
  const params = new URLSearchParams();

  if (filters) {
    const filterMap = {
      use_translation: filters.useTranslation ? 1 : 0,
    };

    for (const [key, value] of Object.entries(filterMap)) {
      if (value) {
        params.append(key, value.toString());
      }
    }
  }

  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.recordings}/${sessionId}/${endpoints.transcript}`,
      {
        params,
        // @ts-ignore
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export function* getSnippetRecordingTranscript(snippetUuid: String) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}${endpoints.snippets}/${snippetUuid}/${endpoints.transcript}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export async function updateSessionLanguage(
  sessionId: string,
  language: string
) {
  return axios
    .put(
      `${urls.baseApiURL}${endpoints.recordings}/${sessionId}/language`,
      JSON.stringify({ language: language }),
      commonheaders
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }

      return error;
    });
}

export async function requestRecordingTranscription(sessionId: String) {
  return axios
    .post(
      `${urls.baseApiURL}${endpoints.recordings}/${sessionId}/${endpoints.transcriptRequest}`,
      null,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }

      return error;
    });
}

export async function requestRecordingTranscriptParseNaming(sessionId: String) {
  return axios
    .post(
      `${urls.baseApiURL}${endpoints.recordings}/${sessionId}/${endpoints.transcriptSpeakersRequest}`,
      null,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }

      return error;
    });
}

export function* searchRecordingTranscripts(
  accountId: String,
  search: String,
  filter: String
) {
  const response: ResponseGenerator = yield axios
    .get(
      `${urls.baseApiURL}account/${accountId}/recordings/transcripts/search?search=${search}${filter}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
  return response;
}

export async function getSentenceStatistic(
  accountId: String,
  type: string,
  filter: Filters,
  globalFilters: IGlobalFilters
) {
  const filters = {
    group_by: filter.chartType === TOTAL ? "total" : filter.groupBy,
    speaker_type: filter.speakerType,
    period: filter.period,
    chart_type: filter.chartType,
    denominator: filter.denominator,
  };

  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(filters)) {
    if (value !== undefined && value !== "" && value !== null) {
      params.append(key, value.toString());
    }
  }

  if (filter.specificStats && filter.specificStats.length > 0) {
    filter.specificStats.forEach((item) => {
      if (item !== "") {
        params.append("specific_stats[]", item);
      }
    });
  }

  if (filter.keywordGroupIds && filter.keywordGroupIds.length > 0) {
    filter.keywordGroupIds.forEach((item) => {
      if (item !== "") {
        params.append("keyword_group_ids[]", item);
      }
    });
  }

  if (type === STATISTIC_WIDGET_TYPES.KEYWORD && filter.keywordMentioned !== undefined) {
    params.append('keyword_mentioned', filter.keywordMentioned.toString());
  }

  for (const [key, value] of Object.entries(globalFilters)) {
    if (key === "global_period" || key === "queryBuilderObject") {
      continue;
    }

    if (!isEmpty(value)) {
      if (key === "global_start" || key === "global_end") {
        params.append(key, value.toString());
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item);
        });
      } else {
        params.append(key, value);
      }
    }
  }

  if (globalFilters.queryBuilderObject) {
      params.append('queryBuilderObject', JSON.stringify(globalFilters.queryBuilderObject));
  }

  const response = await axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.stats}/${endpoints.sentence}/${type}`,
      {
        params,
        // @ts-ignore
        headers: commonheaders,
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });

  return response;
}

export async function getAiCategories() {
  try {
    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.aiCategories}`,
      {
        // @ts-ignore
        headers: commonheaders,
      }
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getRecordingsByCompareGroup(
  accountId: String,
  type: string,
  filter: Filters,
  compareBy: { key: any; value: any },
  globalFilters: IGlobalFilters,
  interval: IGlobalInterval | null,
  useTranslation?: boolean,
  limit?: number
) {
  const { denominator, groupBy, keywordMentioned, speakerType, period, chartType } = filter;

  const filters = {
    group_by: groupBy,
    speaker_type: speakerType,
    period: period,
    chart_type: chartType,
    denominator,
    [compareBy.key]: compareBy.value,
    use_translation: useTranslation ? useTranslation : null,
    keyword_mentioned: keywordMentioned,
  };

  const params = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (value !== null && value !== "" && value !== undefined) {
      params.append(key, value);
    }
  });

  if (interval) {
    params.append("global_start", interval.globalStart);
    params.append("global_end", interval.globalEnd);
  }

  if (limit) {
    params.append("limit", limit.toString());
  }

  if (
    filter.groupBy !== GROUP_VALUE &&
    filter.specificStats &&
    filter.specificStats.length > 0
  ) {
    filter.specificStats.forEach((item) => {
      if (item !== "") {
        params.append("specific_stats[]", item);
      }
    });
  }

  if (
    filter.groupBy !== GROUP_VALUE &&
    filter.keywordGroupIds &&
    filter.keywordGroupIds.length > 0
  ) {
    filter.keywordGroupIds.forEach((item) => {
      if (item !== "") {
        params.append("keyword_group_ids[]", item);
      }
    });
  }

  for (const [key, value] of Object.entries(globalFilters)) {
    if (key === 'queryBuilderObject') {
      continue;
    }

    if (!isEmpty(value)) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item);
        });
      } else {
        if (!(interval && (key === 'global_start' || key === 'global_end'))) {
          params.append(key, value);
        }
      }
    }
  }

  if (type === VARIABLE_WIDGET && filter.variableIds && filter.variableIds.length) {
    filter.variableIds.forEach((value) => {
      params.append('variable_ids[]', String(value));
    })
  }

  if (globalFilters.queryBuilderObject) {
    params.append('queryBuilderObject', JSON.stringify(globalFilters.queryBuilderObject));
  }

  const useOldCache = localStorage.getItem('use_old_cache');

  let route = useOldCache
    ? 'transcripts'
    : 'stats'
  ;

  try {
    const response = await axios.get(
      `${urls.baseApiURL}account/${accountId}/recordings/${route}/${type}`,
      {
        params,
        // @ts-ignore
        headers: commonheaders,
      }
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getPlaybookRecordings(
  accountId: String,
  uuid: string,
  globalFilters: IGlobalFilters,
  includeNotMentioned?: boolean,
  limit?: number
) {
  const params = new URLSearchParams();

  params.append('uuid', uuid);

  if (includeNotMentioned) {
    params.append("include_not_mentioned", String(includeNotMentioned).toString());
  }

  if (limit) {
    params.append("limit", limit.toString());
  }

  for (const [key, value] of Object.entries(globalFilters)) {
    if (!isEmpty(value)) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item);
        });
      } else {
        if (['global_start', 'global_end', 'session_id'].includes(key)) {
          params.append(key, value);
        }
      }
    }
  }

  if (globalFilters.queryBuilderObject) {
    params.append('queryBuilderObject', JSON.stringify(globalFilters.queryBuilderObject));
  }

  try {
    const response = await axios.get(
      `${urls.baseApiURL}accounts/${accountId}/stats/playbook`,
      {
        params,
        // @ts-ignore
        headers: commonheaders,
      }
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function downloadTranscriptCsv(sessionId: string) {
  try {
    const response = await axios.get(
      `${urls.baseApiURL}recordings/sessions/${sessionId}/transcript/export`,
      { responseType: "blob" }
    );
    const blob = response.data;

    const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = `transcription_${sessionId.slice(0, 5)}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Error downloading transcript CSV:", error);
  }
}

export async function getDashboards(accountId: String) {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}accounts/${accountId}/${endpoints.dashboard}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getCalendars() {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}user/${endpoints.calendars}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export const fetchCalendars = async (
  token: string,
  fetchEvents: (calendarId: string) => Promise<Event[]>,
): Promise<CalendarData[]> => {
  if (!token) {
    console.error('No token available');
    throw new Error('Authentication token is missing');
  }

  try {
    const response = await fetch(`${urls.baseApiURL}calendars`, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        console.error("Unauthorized! Token may have expired.");
        throw new Error('Unauthorized');
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json().catch(() => {
      throw new Error("Failed to parse JSON response");
    });

    const calendars = await Promise.all(
      (responseData.data || []).map(async (calendar: CalendarData) => {
        const events = await fetchEvents(calendar.id);
        return { ...calendar, events };
      })
    );

    return calendars;
  } catch (error) {
    console.error('Error fetching calendars:', error);
    throw error;
  }
};

export const fetchEvents = async (
  token: string,
  calendarId: string,
  startDateFilter?: string,
  endDateFilter?: string,
  filters?: ICalendarFilters,
): Promise<Event[]> => {
  if (!token) {
    console.error('No token available');
    throw new Error('Authentication token is missing');
  }

  // Skip fetching if calendarId is present in filters.calendar_ids
  if (filters?.calendar_ids && filters?.calendar_ids.length && !filters?.calendar_ids?.includes(calendarId)) {
    console.log(`Skipping fetch for calendarId: ${calendarId}`);

    return [];
  }

  try {
    const queryParams = new URLSearchParams();
    if (startDateFilter) queryParams.append('start_time', startDateFilter);
    if (endDateFilter) queryParams.append('end_time', endDateFilter);

    if (filters?.global_meeting_types?.length) {
      filters.global_meeting_types.forEach(value => {
        queryParams.append('global_meeting_types[]', String(value));
      });
    }

    const url = `${urls.baseApiURL}calendar/${calendarId}/events?${queryParams.toString()}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        console.error("Unauthorized! Token may have expired.");
        throw new Error('Unauthorized');
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData: { data: Event[] } = await response.json();
    const formattedEvents = responseData.data.map(event => ({
      ...event,
      start: formatDate(event.start_time),
      end: formatDate(event.end_time),
      // Defensive programming against malformed data
      participants: Array.isArray(event.participants) ? event.participants : Object.values(event.participants),
    }));

    return formattedEvents;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export async function refetchCalendar() {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.checkFutureEvents}/user`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getMeetingBots(calendarId: string) {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.meetingBotsEvent}/${endpoints.calendars}/${calendarId}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getProcessing(calendarId: string) {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.transcriptEvent}/${endpoints.calendars}/${calendarId}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getAllTags(accountId: String) {
  return axios
    .get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/tags`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export async function addRecordingTag(
  name: String,
  sessionId: String,
  accountId: String
) {
  return axios
    .post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.recordingTags}/${sessionId}/tag`,
      {
        name,
      },
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export async function deleteRecordingTag(
  id: String,
  sessionId: String,
  accountId: String
) {
  return axios
    .delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.recordingTags}/${sessionId}/tag/${id}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export async function createSnippetTag(
  name: String,
  snippetUuid: String,
  accountId: String
) {
  try {
    const response = await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.snippets}/${snippetUuid}/tag`,
      {
        name,
      },
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function removeSnippetTag(
  id: string,
  sessionId: string,
  accountId: String
) {
  try {
    const response = await axios.delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.snippets}/${sessionId}/tags/${id}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function createTagByAccount(accountId: String, name: string) {
  try {
    const response = await axios.post(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/tags`,
      { name },
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteTagByAccount(id: string, accountId: String) {
  try {
    const response = await axios.delete(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/tags/${id}`,
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function editTagByAccount(
  id: string,
  name: string,
  accountId: String
) {
  try {
    const response = await axios.put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/tags/${id}`,
      { name },
      commonheaders
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function fetchTagAnalytics(accountId: string, filters?: string) {
  try {
    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.taggedRecordings}?${filters}`,
      commonheaders
    );
    return response;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function changeUserPassword(
  accountId: String,
  userId: string,
  newPassword: string,
  confirmedPassword: string
) {
  try {
    const response = await axios.put(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.editUserProfile}/${userId}/${endpoints.changeUserProfilePassword}`,
      {
        new_password: newPassword,
        confirm_password: confirmedPassword,
      },
      commonheaders
    );
    return response;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getGlobalFilters(accountId: String) {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}accounts/${accountId}/${endpoints.globalFilters}`,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function disableCallIntegration(accountId: String, token: string) {
  try {
    const response: ResponseGenerator = await axios.post(
      `${urls.baseApiURL}accounts/${accountId}/${endpoints.callImports}/disable`,
      {
        token: token
      },
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

export async function enableCallIntegration(accountId: String, token: string) {
  try {
    const response: ResponseGenerator = await axios.post(
      `${urls.baseApiURL}accounts/${accountId}/${endpoints.callImports}/enable`,
      {
        token: token
      },
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

export async function getInfracomToken() {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}tokens/${endpoints.infracomToken}`,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function createInfracomToken(token: InfracomToken) {
  try {
    const response: ResponseGenerator = await axios.post(
      `${urls.baseApiURL}tokens/${endpoints.infracomToken}`,
      JSON.stringify(token),
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function updateInfracomToken(token: InfracomToken) {
  try {
    const response: ResponseGenerator = await axios.put(
      `${urls.baseApiURL}tokens/${endpoints.infracomToken}/${token.id}`,
      JSON.stringify(token),
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteInfracomToken(token: InfracomToken) {
  try {
    const response: ResponseGenerator = await axios.delete(
      `${urls.baseApiURL}tokens/${endpoints.infracomToken}/${token.id}`,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getTotalNumberOfMeetings(userId: string, meetingTime: string, meetingFilters: FilterObject, query: string) {
  const globalFilterString = createMeetingQueryStringFromParams(meetingFilters);
  const url = `${urls.baseApiURL}${endpoints.getUsers}/${userId}/${endpoints.meetings}-total?time=${meetingTime}&query=${query}&${globalFilterString}`;

  try {
    const response: ResponseGenerator = await axios.get(url,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

export async function getRecordingsWithTranscript(
  accountId: String,
  globalFilters?: IGlobalFilters,
  sessionId?: string,
  search?: string,
  agentType?: string,
): Promise<RecordingBasicData[]> {
  const params: ParamsType = {};

  if (globalFilters) {
    for (const [key, value] of Object.entries(globalFilters)) {
      if (!isEmpty(value)) {
        params[key] = value;
      }
    }
  }

  if (sessionId) {
    params["session_id"] = sessionId;
  }

  if (search) {
    params["search"] = search;
  }

  if (agentType) {
    params["agent_type"] = agentType;
  }

  try {
    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.accounts}/${accountId}/${endpoints.analyticStats}/${endpoints.recordingsWithTranscription}`,
      {
        // @ts-ignore
        headers: commonheaders,
        params,
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getBotLogsForMeetingBot(meetingId: string) {
  const url = `${urls.baseApiURL}${endpoints.meetingBotsEvent}/meeting/${meetingId}`;

  try {
    const response: ResponseGenerator = await axios.get(url,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
};

export async function getMeetingCalendarSyncInfo(meetingId: string): Promise<IMeetingCalendarSyncInfoData> {
  try {
    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.meetings}/${meetingId}/${endpoints.meetingCalendarSyncInfo}`
    );
    return mapKeysToCamelCase<IMeetingCalendarSyncInfoData>(response.data);
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function startLiveRecordingUpload({
  meetingId, title, participants
}: {
  meetingId: string|null,
  title: string,
  participants: any[]
}) {
  try {
    const response = await axios.post(
      `${urls.baseApiURL}${endpoints.recordingsUploadLive}`,
      { meetingId, title, participants }
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getLiveRecordingUploadUrls(
  recordingId: string,
  uploadId: string,
  offset: number = 1,
  page: number = 50,
) {
  try {
    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.recordingGeneral}/${recordingId}/upload-live-urls` +
        `?uploadId=${uploadId}&offset=${offset}&page=${page}`,
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export function failLiveRecordingUpload(recordingId: string, uploadId: string) {
  return finishOrFailLiveRecordingUpload({
    recordingId,
    uploadId,
    success: false,
  });
}

export function endLiveRecordingUpload({
  recordingId,
  uploadId,
  partETags,
  recordingDuration
}: {
  recordingId: string,
  uploadId: string,
  partETags: string[],
  recordingDuration: number,
}) {
  return finishOrFailLiveRecordingUpload({
    recordingId,
    uploadId,
    partETags,
    recordingDuration,
    success: true,
  });
}

async function finishOrFailLiveRecordingUpload({
  recordingId,
  uploadId,
  partETags,
  recordingDuration,
  success,
}: {
  recordingId: string,
  uploadId: string,
  success: boolean,
  partETags?: string[],
  recordingDuration?: number,
}) {
  try {
    const response = await axios.put(
      `${urls.baseApiURL}${endpoints.recordingsUploadLive}/${recordingId}`,
      { success, uploadId, partETags, recordingDuration }
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getLiveRecordingViewUrl(recordingId: string) {
  try {
    const response = await axios.get(
      `${urls.baseApiURL}${endpoints.recordingsViewLive}/${recordingId}`
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function saveMeetingSpeakerRemapping(sessionId: String, speakerMap: any) {
  try {
    const response = await axios.post(
      `${urls.baseApiURL}${endpoints.recordingsAnalytics}/${sessionId}/map-participants`,
      { participants: speakerMap },
      commonheaders,
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function logoutFromAPI() {
  try {
    return await axios.post(`${urls.baseApiURL}logout`);
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function resetMeetingSpeakerMapping(sessionId: String) {
  try {
    const response = await axios.post(
      `${urls.baseApiURL}${endpoints.recordingsAnalytics}/${sessionId}/reset-participants`,
      commonheaders,
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function updateMeetingOnCalendar(meetingData: Partial<Event>) {
  const response = await axios.post(
    `${urls.baseApiURL}${endpoints.calendars}/${endpoints.selectOwner}`,
    {
      meetingUrl: meetingData.video_url,
      meetingHash: meetingData.meeting_hash,
      startTime: meetingData.start_time,
      ownerEmail: meetingData.selected_owner,
      meetingType: meetingData.selected_meeting_type_id,
    },
    commonheaders,
  );
  return response.data;
}

export function getPrivatePhoneNumbers() {
  return axios
    .get(
      `${urls.baseApiURL}${endpoints.privatePhoneNumbers}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function addPrivatePhoneNumber(phoneNumber: string) {
  return axios
    .post(
      `${urls.baseApiURL}${endpoints.privatePhoneNumbers}`,
      JSON.stringify({
        force_delete_phone_number_sessions: false,
        phone_number: phoneNumber,
      }),
      commonheaders,
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}

export function removePrivatePhoneNumber(numberId: string) {
  return axios
    .delete(
      `${urls.baseApiURL}${endpoints.privatePhoneNumbers}/${numberId}`,
      commonheaders
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
      return error;
    });
}
