import React from "react";
import moment from 'moment';
import _ from "lodash";
import Cookies from "../../utilities/universal-cookies";
import {extractDomainFromHostname} from "../partner/partnerService";

const cookies = new Cookies();

const setLocalStorage = (id, data) => {
  localStorage.setItem(id, JSON.stringify(data));
  return;
}

const getLocalStorage = (id) => {
  return JSON.parse(localStorage.getItem(id));
}

const deleteLocalStorage = (id, data) => {
  localStorage.removeItem(id);
  return;
}

const toKebabCase = (str) => str.replace(
  /([a-z])([A-Z])/g,
  (m) => `${m[0]}-${m[1].toLowerCase()}`
);

const resizeImage = (url, size = 'full', CDN = 'cloudflare') => {
  // temporary solution
  return url;
  if (size === 'full' || url.slice(0, 10) === 'data:image') {
    return url;
  }

  const urlObj = new URL(url);

  if (CDN === 'cloudflare') {
    if (size === 'large') {
      return urlObj.origin + '/cdn-cgi/image/fit=scale-down,width=1200,height=1200,quality=95' + urlObj.pathname;
    } else if (size === 'thumbnail') {
      return urlObj.origin + '/cdn-cgi/image/fit=scale-down,width=260,height=260,quality=80' + urlObj.pathname;
    }
  }

  return url;
};

const getHighlightedText = (text, highlight) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? (<span style={{ color: '#0045CA' }}>{part}</span>) : part)}</span>;
};

export const DEFAULT_DATE_STATE = "0";
export const THIS_MONTH = "1";
export const LAST_MONTH = "2";
export const LAST_30_DAYS = "3";
export const LAST_90_DAYS = "4";
export const LAST_120_DAYS = "5";
export const CURRENT_WEEK = "6";
export const LAST_WEEK = "7";

const getEndDatesForAPI = (value) => {
  let response = {};
  const currentDate = moment().format("YYYY-MM-DD");
  response.enddate = currentDate;
  switch (value.toString()) {
    case DEFAULT_DATE_STATE:
      response.startdate = null;
      response.enddate = null;
      break;
    case THIS_MONTH:
      response.startdate = moment().startOf("month").format("YYYY-MM-DD");
      break;
    case LAST_MONTH:
      response.startdate = moment()
        .subtract("1", "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      response.enddate = moment()
        .subtract("1", "month")
        .endOf("month")
        .format("YYYY-MM-DD");
      break;
    case LAST_30_DAYS:
      response.startdate = moment()
        .subtract("30", "days")
        .format("YYYY-MM-DD");
      break;
    case LAST_90_DAYS:
      response.startdate = moment()
        .subtract("90", "days")
        .format("YYYY-MM-DD");
      break;
    case LAST_120_DAYS:
      response.startdate = moment()
        .subtract("120", "days")
        .format("YYYY-MM-DD");
      break;
    case CURRENT_WEEK:
      response.startdate = moment()
        .startOf('week')
        .format('YYYY-MM-DD');
      break;
    case LAST_WEEK:
      response.startdate = moment()
        .subtract(1, 'week')
        .startOf('week')
        .format('YYYY-MM-DD');
      response.enddate = moment()
        .subtract(1, 'week')
        .endOf('week')
        .format('YYYY-MM-DD');
      break;
    default:
      response.startdate = moment()
        .subtract("120", "days")
        .format("YYYY-MM-DD");
      break;
  }
  return response;
}

const convertSeconds = (value) => {
  var hour = Math.floor(
    moment.duration(value, "seconds").asHours()
  );
  var format =
    moment.duration(value, "seconds").minutes() +
    ":" +
    moment.duration(value, "seconds").seconds();
  if (hour !== 0) {
    format =
      Math.floor(hour) +
      ":" +
      moment.duration(value, "seconds").minutes() +
      ":" +
      moment.duration(value, "seconds").seconds();
  }
  return format;
}

const formatDateTime = (date) => {
  return moment(date).format('YYYY-MM-DD HH:mm');
};

const formatDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
};

const formatIsoDate = (dateISO) => {
  const date = new Date(dateISO);

  return moment(date).format('YYYY-MM-DD');
}

const getAllWeekDatesByDate = (date) => {
  const baseDate = new Date(date);
  baseDate.setDate(baseDate.getDate());

  return Array.from({ length: 7 }).map((_, idx) => {
    const d = new Date(baseDate);
    d.setDate(baseDate.getDate() - baseDate.getDay() + idx);
    return d;
  });
};

const formatTime = (seconds) => {
  let s = ~~seconds;

  if (s >= 86400) {
    return moment.utc(s * 1000).format('d:HH:mm:ss');
  } else if (s >= 3600) {
    return moment.utc(s * 1000).format('HH:mm:ss');
  } else {
    return moment.utc(s * 1000).format('mm:ss');
  }
};

const formatPercentage = (value) => {
  if (typeof value === 'number') {
    return ~~_.round(value) + '%';
  } else {
    return value;
  }
};

const formatDecimal = (value) => {
  if (typeof value === 'number') {
    return value === 0 ? 0 : value.toFixed(2);
  } else {
    return value;
  }
};

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const getDateOfWeek = (w, y) => {
  const d = 1 + (w - 1) * 7;
  const startDate = new Date(y, 0, d);

  const dayOfWeek = startDate.getDay();
  if (dayOfWeek !== 1) { // Force monday as start of the week
    startDate.setDate(startDate.getDate() + (1 - dayOfWeek + 7) % 7);
  }

  return startDate;
}

const firstLetterUppercase = (word) => {
  const string = String(word);
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const isFloat = (value) => {
  if (
      typeof value === 'number' &&
      !Number.isNaN(value) &&
      !Number.isInteger(value)
  ) {
    return true;
  }

  return false;
}

const escapeHtmlSpecialChars = (text) => {
  var map = {
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;',
  };

  return text.replace(/[<>"']/g, (m) => map[m]);
};

function cleanObject(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

function isUserRightLevel(account) {
  const right_level = [
    "user_manager",
    "manage_users",
    "admin",
    "team_manager",
  ];
  if (right_level.includes(account.right_level)) {
    return true;
  }
  return false;
}

function setTokenCookies(token, setAuthTokenCookie = true) {
  if (token?.includes("JWT")) {
    token = token.replace("JWT ", "");
  }

  cookies.set("is_demo", false, { path:'/'});
  if (!_.isEmpty(token)) {
    const rememberMe = getLocalStorage('remember-me');

    // Default options.
    let cookieOptions = {
      sameSite: "None",
      secure: true,
      path: '/',
    };

    if (rememberMe === true) {
      let date = new Date(Date.now());
      // Auth cookie expires in 90 days.
      date.setDate(date.getDate() + 90);

      cookieOptions.expires = date;
    }

    let domain = "";

    switch (window.location.hostname) {
      case "app.pitchflow.com":
        domain = ".pitchflow.com";
        break;
      case "app.meetric.com":
        domain = ".meetric.com";
        break;
      case "dashboard.staging.pitchflow.io":
        domain = ".pitchflow.io";
        break;
      case "app.acceptflow.com":
        domain = ".acceptflow.com";
        break;
      case "app.acceptstack.com":
        domain = ".acceptstack.com";
        break;
      case "localhost":
        domain = "localhost";
        break;
      default:
        domain = extractDomainFromHostname(window.location.hostname);
    }

    cookies.set("JWT_Token", `JWT ${token}`, {
      domain,
      ...cookieOptions,
    });

    if (setAuthTokenCookie) {
      cookies.set("auth-token", `JWT ${token}`, {
        domain,
        ...cookieOptions,
      });
    }
  }
}

export function removeTokenCookies() {
  cookies.remove('JWT_Token');
}

//Function to convert hex format to a rgb color
function rgb2hex(rgb) {
  rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  return (rgb && rgb.length === 4) ? "#" +
    ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
    ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
    ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
}

const isoLangs = [
  "Abkhaz ( аҧсуа )",
  "Afar ( Afaraf )",
  "Afrikaans ( Afrikaans )",
  "Akan ( Akan )",
  "Albanian ( Shqip )",
  "Amharic ( አማርኛ )",
  "Arabic ( العربية )",
  "Aragonese ( Aragonés )",
  "Armenian ( Հայերեն )",
  "Assamese ( অসমীয়া )",
  "Avaric ( авар мацӀ, магӀарул мацӀ )",
  "Avestan ( avesta )",
  "Aymara ( aymar aru )",
  "Azerbaijani ( azərbaycan dili )",
  "Bambara ( bamanankan )",
  "Bashkir ( башҡорт теле )",
  "Basque ( euskara, euskera )",
  "Belarusian ( Беларуская )",
  "Bengali ( বাংলা )",
  "Bihari ( भोजपुरी )",
  "Bislama ( Bislama )",
  "Bosnian ( bosanski jezik )",
  "Breton ( brezhoneg )",
  "Bulgarian ( български език )",
  "Burmese ( ဗမာစာ )",
  "Catalan; Valencian ( Català )",
  "Chamorro ( Chamoru )",
  "Chechen ( нохчийн мотт )",
  "Chichewa; Chewa; Nyanja ( chiCheŵa, chinyanja )",
  "Chinese ( 中文 (Zhōngwén), 汉语, 漢語 )",
  "Chuvash ( чӑваш чӗлхи )",
  "Cornish ( Kernewek )",
  "Corsican ( corsu, lingua corsa )",
  "Cree ( ᓀᐦᐃᔭᐍᐏᐣ )",
  "Croatian ( hrvatski )",
  "Czech ( česky, čeština )",
  "Danish ( dansk )",
  "Divehi; Dhivehi; Maldivian; ( ދިވެހި )",
  "Dutch ( Nederlands, Vlaams )",
  "English ( English )",
  "Esperanto ( Esperanto )",
  "Estonian ( eesti, eesti keel )",
  "Ewe ( Eʋegbe )",
  "Faroese ( føroyskt )",
  "Fijian ( vosa Vakaviti )",
  "Finnish ( suomi, suomen kieli )",
  "French ( français, langue française )",
  "Fula; Fulah; Pulaar; Pular ( Fulfulde, Pulaar, Pular )",
  "Galician ( Galego )",
  "Georgian ( ქართული )",
  "German ( Deutsch )",
  "Greek, Modern ( Ελληνικά )",
  "Guaraní ( Avañeẽ )",
  "Gujarati ( ગુજરાતી )",
  "Haitian; Haitian Creole ( Kreyòl ayisyen )",
  "Hausa ( Hausa, هَوُسَ )",
  "Hebrew (modern) ( עברית )",
  "Herero ( Otjiherero )",
  "Hindi ( हिन्दी, हिंदी )",
  "Hiri Motu ( Hiri Motu )",
  "Hungarian ( Magyar )",
  "Interlingua ( Interlingua )",
  "Indonesian ( Bahasa Indonesia )",
  "Interlingue ( Originally called Occidental; then Interlingue after WWII )",
  "Irish ( Gaeilge )",
  "Igbo ( Asụsụ Igbo )",
  "Inupiaq ( Iñupiaq, Iñupiatun )",
  "Ido ( Ido )",
  "Icelandic ( Íslenska )",
  "Italian ( Italiano )",
  "Inuktitut ( ᐃᓄᒃᑎᑐᑦ )",
  "Japanese ( 日本語 (にほんご／にっぽんご) )",
  "Javanese ( basa Jawa )",
  "Kalaallisut, Greenlandic ( kalaallisut, kalaallit oqaasii )",
  "Kannada ( ಕನ್ನಡ )",
  "Kanuri ( Kanuri )",
  "Kashmiri ( कश्मीरी, كشميري‎ )",
  "Kazakh ( Қазақ тілі )",
  "Khmer ( ភាសាខ្មែរ )",
  "Kikuyu, Gikuyu ( Gĩkũyũ )",
  "Kinyarwanda ( Ikinyarwanda )",
  "Kirghiz, Kyrgyz ( кыргыз тили )",
  "Komi ( коми кыв )",
  "Kongo ( KiKongo )",
  "Korean ( 한국어 (韓國語), 조선말 (朝鮮語) )",
  "Kurdish ( Kurdî, كوردی‎ )",
  "Kwanyama, Kuanyama ( Kuanyama )",
  "Latin ( latine, lingua latina )",
  "Luxembourgish, Letzeburgesch ( Lëtzebuergesch )",
  "Luganda ( Luganda )",
  "Limburgish, Limburgan, Limburger ( Limburgs )",
  "Lingala ( Lingála )",
  "Lao ( ພາສາລາວ )",
  "Lithuanian ( lietuvių kalba )",
  "Luba-Katanga (  )",
  "Latvian ( latviešu valoda )",
  "Manx ( Gaelg, Gailck )",
  "Macedonian ( македонски јазик )",
  "Malagasy ( Malagasy fiteny )",
  "Malay ( bahasa Melayu, بهاس ملايو‎ )",
  "Malayalam ( മലയാളം )",
  "Maltese ( Malti )",
  "Māori ( te reo Māori )",
  "Marathi (Marāṭhī) ( मराठी )",
  "Marshallese ( Kajin M̧ajeļ )",
  "Mongolian ( монгол )",
  "Nauru ( Ekakairũ Naoero )",
  "Navajo, Navaho ( Diné bizaad, Dinékʼehǰí )",
  "Norwegian Bokmål ( Norsk bokmål )",
  "North Ndebele ( isiNdebele )",
  "Nepali ( नेपाली )",
  "Ndonga ( Owambo )",
  "Norwegian Nynorsk ( Norsk nynorsk )",
  "Norwegian ( Norsk )",
  "Nuosu ( ꆈꌠ꒿ Nuosuhxop )",
  "South Ndebele ( isiNdebele )",
  "Occitan ( Occitan )",
  "Ojibwe, Ojibwa ( ᐊᓂᔑᓈᐯᒧᐎᓐ )",
  "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic ( ѩзыкъ словѣньскъ )",
  "Oromo ( Afaan Oromoo )",
  "Oriya ( ଓଡ଼ିଆ )",
  "Ossetian, Ossetic ( ирон æвзаг )",
  "Panjabi, Punjabi ( ਪੰਜਾਬੀ, پنجابی‎ )",
  "Pāli ( पाऴि )",
  "Persian ( فارسی )",
  "Polish ( polski )",
  "Pashto, Pushto ( پښتو )",
  "Portuguese ( Português )",
  "Quechua ( Runa Simi, Kichwa )",
  "Romansh ( rumantsch grischun )",
  "Kirundi ( kiRundi )",
  "Romanian, Moldavian, Moldovan ( română )",
  "Russian ( русский язык )",
  "Sanskrit (Saṁskṛta) ( संस्कृतम् )",
  "Sardinian ( sardu )",
  "Sindhi ( सिन्धी, سنڌي، سندھی‎ )",
  "Northern Sami ( Davvisámegiella )",
  "Samoan ( gagana faa Samoa )",
  "Sango ( yângâ tî sängö )",
  "Serbian ( српски језик )",
  "Scottish Gaelic; Gaelic ( Gàidhlig )",
  "Shona ( chiShona )",
  "Sinhala, Sinhalese ( සිංහල )",
  "Slovak ( slovenčina )",
  "Slovene ( slovenščina )",
  "Somali ( Soomaaliga, af Soomaali )",
  "Southern Sotho ( Sesotho )",
  "Spanish; Castilian ( español, castellano )",
  "Sundanese ( Basa Sunda )",
  "Swahili ( Kiswahili )",
  "Swati ( SiSwati )",
  "Swedish ( svenska )",
  "Tamil ( தமிழ் )",
  "Telugu ( తెలుగు )",
  "Tajik ( тоҷикӣ, toğikī, تاجیکی‎ )",
  "Thai ( ไทย )",
  "Tigrinya ( ትግርኛ )",
  "Tibetan Standard, Tibetan, Central ( བོད་ཡིག )",
  "Turkmen ( Türkmen, Түркмен )",
  "Tagalog ( Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔ )",
  "Tswana ( Setswana )",
  "Tonga (Tonga Islands) ( faka Tonga )",
  "Turkish ( Türkçe )",
  "Tsonga ( Xitsonga )",
  "Tatar ( татарча, tatarça, تاتارچا‎ )",
  "Twi ( Twi )",
  "Tahitian ( Reo Tahiti )",
  "Uighur, Uyghur ( Uyƣurqə, ئۇيغۇرچە‎ )",
  "Ukrainian ( українська )",
  "Urdu ( اردو )",
  "Uzbek ( zbek, Ўзбек, أۇزبېك‎ )",
  "Venda ( Tshivenḓa )",
  "Vietnamese ( Tiếng Việt )",
  "Volapük ( Volapük )",
  "Walloon ( Walon )",
  "Welsh ( Cymraeg )",
  "Wolof ( Wollof )",
  "Western Frisian ( Frysk )",
  "Xhosa ( isiXhosa )",
  "Yiddish ( ייִדיש )",
  "Yoruba ( Yorùbá )",
  "Zhuang, Chuang ( Saɯ cueŋƅ, Saw cuengh )"
];

const currency = [
  "United Arab Emirates Dirham ( AED )",
  "Afghan Afghani ( AFN )",
  "Albanian Lek ( ALL )",
  "Armenian Dram ( AMD )",
  "Netherlands Antillean Guilder ( ANG )",
  "Angolan Kwanza ( AOA )",
  "Argentine Peso ( ARS )",
  "Australian Dollar ( AUD )",
  "Aruban Florin ( AWG )",
  "Azerbaijani Manat ( AZN )",
  "Bosnia-Herzegovina Convertible Mark ( BAM )",
  "Barbadian Dollar ( BBD )",
  "Bangladeshi Taka ( BDT )",
  "Bulgarian Lev ( BGN )",
  "Bahraini Dinar ( BHD )",
  "Burundian Franc ( BIF )",
  "Bermudan Dollar ( BMD )",
  "Brunei Dollar ( BND )",
  "Bolivian Boliviano ( BOB )",
  "Brazilian Real ( BRL )",
  "Bahamian Dollar ( BSD )",
  "Bitcoin ( BTC )",
  "Bhutanese Ngultrum ( BTN )",
  "Botswanan Pula ( BWP )",
  "Belarusian Ruble ( BYN )",
  "Belize Dollar ( BZD )",
  "Canadian Dollar ( CAD )",
  "Congolese Franc ( CDF )",
  "Swiss Franc ( CHF )",
  "Chilean Unit of Account (UF) ( CLF )",
  "Chilean Peso ( CLP )",
  "Chinese Yuan (Offshore) ( CNH )",
  "Chinese Yuan ( CNY )",
  "Colombian Peso ( COP )",
  "Costa Rican Colón ( CRC )",
  "Cuban Convertible Peso ( CUC )",
  "Cuban Peso ( CUP )",
  "Cape Verdean Escudo ( CVE )",
  "Czech Republic Koruna ( CZK )",
  "Djiboutian Franc ( DJF )",
  "Danish Krone ( DKK )",
  "Dominican Peso ( DOP )",
  "Algerian Dinar ( DZD )",
  "Egyptian Pound ( EGP )",
  "Eritrean Nakfa ( ERN )",
  "Ethiopian Birr ( ETB )",
  "Euro ( EUR )",
  "Fijian Dollar ( FJD )",
  "Falkland Islands Pound ( FKP )",
  "British Pound Sterling ( GBP )",
  "Georgian Lari ( GEL )",
  "Guernsey Pound ( GGP )",
  "Ghanaian Cedi ( GHS )",
  "Gibraltar Pound ( GIP )",
  "Gambian Dalasi ( GMD )",
  "Guinean Franc ( GNF )",
  "Guatemalan Quetzal ( GTQ )",
  "Guyanaese Dollar ( GYD )",
  "Hong Kong Dollar ( HKD )",
  "Honduran Lempira ( HNL )",
  "Croatian Kuna ( HRK )",
  "Haitian Gourde ( HTG )",
  "Hungarian Forint ( HUF )",
  "Indonesian Rupiah ( IDR )",
  "Israeli New Sheqel ( ILS )",
  "Manx pound ( IMP )",
  "Indian Rupee ( INR )",
  "Iraqi Dinar ( IQD )",
  "Iranian Rial ( IRR )",
  "Icelandic Króna ( ISK )",
  "Jersey Pound ( JEP )",
  "Jamaican Dollar ( JMD )",
  "Jordanian Dinar ( JOD )",
  "Japanese Yen ( JPY )",
  "Kenyan Shilling ( KES )",
  "Kyrgystani Som ( KGS )",
  "Cambodian Riel ( KHR )",
  "Comorian Franc ( KMF )",
  "North Korean Won ( KPW )",
  "South Korean Won ( KRW )",
  "Kuwaiti Dinar ( KWD )",
  "Cayman Islands Dollar ( KYD )",
  "Kazakhstani Tenge ( KZT )",
  "Laotian Kip ( LAK )",
  "Lebanese Pound ( LBP )",
  "Sri Lankan Rupee ( LKR )",
  "Liberian Dollar ( LRD )",
  "Lesotho Loti ( LSL )",
  "Libyan Dinar ( LYD )",
  "Moroccan Dirham ( MAD )",
  "Moldovan Leu ( MDL )",
  "Malagasy Ariary ( MGA )",
  "Macedonian Denar ( MKD )",
  "Myanma Kyat ( MMK )",
  "Mongolian Tugrik ( MNT )",
  "Macanese Pataca ( MOP )",
  "Mauritanian Ouguiya (pre-2018) ( MRO )",
  "Mauritanian Ouguiya ( MRU )",
  "Mauritian Rupee ( MUR )",
  "Maldivian Rufiyaa ( MVR )",
  "Malawian Kwacha ( MWK )",
  "Mexican Peso ( MXN )",
  "Malaysian Ringgit ( MYR )",
  "Mozambican Metical ( MZN )",
  "Namibian Dollar ( NAD )",
  "Nigerian Naira ( NGN )",
  "Nicaraguan Córdoba ( NIO )",
  "Norwegian Krone ( NOK )",
  "Nepalese Rupee ( NPR )",
  "New Zealand Dollar ( NZD )",
  "Omani Rial ( OMR )",
  "Panamanian Balboa ( PAB )",
  "Peruvian Nuevo Sol ( PEN )",
  "Papua New Guinean Kina ( PGK )",
  "Philippine Peso ( PHP )",
  "Pakistani Rupee ( PKR )",
  "Polish Zloty ( PLN )",
  "Paraguayan Guarani ( PYG )",
  "Qatari Rial ( QAR )",
  "Romanian Leu ( RON )",
  "Serbian Dinar ( RSD )",
  "Russian Ruble ( RUB )",
  "Rwandan Franc ( RWF )",
  "Saudi Riyal ( SAR )",
  "Solomon Islands Dollar ( SBD )",
  "Seychellois Rupee ( SCR )",
  "Sudanese Pound ( SDG )",
  "Swedish Krona ( SEK )",
  "Singapore Dollar ( SGD )",
  "Saint Helena Pound ( SHP )",
  "Sierra Leonean Leone ( SLL )",
  "Somali Shilling ( SOS )",
  "Surinamese Dollar ( SRD )",
  "South Sudanese Pound ( SSP )",
  "São Tomé and Príncipe Dobra (pre-2018) ( STD )",
  "São Tomé and Príncipe Dobra ( STN )",
  "Salvadoran Colón ( SVC )",
  "Syrian Pound ( SYP )",
  "Swazi Lilangeni ( SZL )",
  "Thai Baht ( THB )",
  "Tajikistani Somoni ( TJS )",
  "Turkmenistani Manat ( TMT )",
  "Tunisian Dinar ( TND )",
  "Tongan Pa'anga ( TOP )",
  "Turkish Lira ( TRY )",
  "Trinidad and Tobago Dollar ( TTD )",
  "New Taiwan Dollar ( TWD )",
  "Tanzanian Shilling ( TZS )",
  "Ukrainian Hryvnia ( UAH )",
  "Ugandan Shilling ( UGX )",
  "United States Dollar ( USD )",
  "Uruguayan Peso ( UYU )",
  "Uzbekistan Som ( UZS )",
  "Venezuelan Bolívar Fuerte ( VEF )",
  "Vietnamese Dong ( VND )",
  "Vanuatu Vatu ( VUV )",
  "Samoan Tala ( WST )",
  "CFA Franc BEAC ( XAF )",
  "Silver Ounce ( XAG )",
  "Gold Ounce ( XAU )",
  "East Caribbean Dollar ( XCD )",
  "Special Drawing Rights ( XDR )",
  "CFA Franc BCEAO ( XOF )",
  "Palladium Ounce ( XPD )",
  "CFP Franc ( XPF )",
  "Platinum Ounce ( XPT )",
  "Yemeni Rial ( YER )",
  "South African Rand ( ZAR )",
  "Zambian Kwacha ( ZMW )",
  "Zimbabwean Dollar ( ZWL )"
]

const timeZones =
  [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville',
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay',
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
  ];

const countryCodes = [
  {"name": "Afghanistan", "code": "AF", "timezone": "Asia/Kabul"},
  {"name": "Åland Islands", "code": "AX", "timezone": "Europe/Mariehamn"},
  {"name": "Albania", "code": "AL", "timezone": "Europe/Tirane"},
  {"name": "Algeria", "code": "DZ", "timezone": "Africa/Algiers"},
  {"name": "American Samoa", "code": "AS", "timezone": "Pacific/Pago_Pago"},
  {"name": "Andorra", "code": "AD", "timezone": "Europe/Andorra"},
  {"name": "Angola", "code": "AO", "timezone": "Africa/Luanda"},
  {"name": "Anguilla", "code": "AI", "timezone": "America/Anguilla"},
  {"name": "Antarctica", "code": "AQ", "timezone": "Antarctica/Palmer"},
  {"name": "Antigua and Barbuda", "code": "AG", "timezone": "America/Antigua"},
  {"name": "Argentina", "code": "AR", "timezone": "America/Argentina/Buenos_Aires"},
  {"name": "Armenia", "code": "AM", "timezone": "Asia/Yerevan"},
  {"name": "Aruba", "code": "AW", "timezone": "America/Aruba"},
  {"name": "Australia", "code": "AU", "timezone": "Australia/Sydney"},
  {"name": "Austria", "code": "AT", "timezone": "Europe/Vienna"},
  {"name": "Azerbaijan", "code": "AZ", "timezone": "Asia/Baku"},
  {"name": "Bahamas", "code": "BS", "timezone": "America/Nassau"},
  {"name": "Bahrain", "code": "BH", "timezone": "Asia/Bahrain"},
  {"name": "Bangladesh", "code": "BD", "timezone": "Asia/Dhaka"},
  {"name": "Barbados", "code": "BB", "timezone": "America/Barbados"},
  {"name": "Belarus", "code": "BY", "timezone": "Europe/Minsk"},
  {"name": "Belgium", "code": "BE", "timezone": "Europe/Brussels"},
  {"name": "Belize", "code": "BZ", "timezone": "America/Belize"},
  {"name": "Benin", "code": "BJ", "timezone": "Africa/Porto-Novo"},
  {"name": "Bermuda", "code": "BM", "timezone": "Atlantic/Bermuda"},
  {"name": "Bhutan", "code": "BT", "timezone": "Asia/Thimphu"},
  {"name": "Bolivia", "code": "BO", "timezone": "America/La_Paz"},
  {"name": "Bosnia and Herzegovina", "code": "BA", "timezone": "Europe/Sarajevo"},
  {"name": "Botswana", "code": "BW", "timezone": "Africa/Gaborone"},
  {"name": "Bouvet Island", "code": "BV", "timezone": "Africa/Monrovia"},
  {"name": "Brazil", "code": "BR", "timezone": "America/Sao_Paulo"},
  {"name": "British Indian Ocean Territory", "code": "IO", "timezone": "Indian/Chagos"},
  {"name": "Brunei Darussalam", "code": "BN", "timezone": "Asia/Brunei"},
  {"name": "Bulgaria", "code": "BG", "timezone": "Europe/Sofia"},
  {"name": "Burkina Faso", "code": "BF", "timezone": "Africa/Ouagadougou"},
  {"name": "Burundi", "code": "BI", "timezone": "Africa/Bujumbura"},
  {"name": "Cambodia", "code": "KH", "timezone": "Asia/Phnom_Penh"},
  {"name": "Cameroon", "code": "CM", "timezone": "Africa/Douala"},
  {"name": "Canada", "code": "CA", "timezone": "America/Toronto"},
  {"name": "Cape Verde", "code": "CV", "timezone": "Atlantic/Cape_Verde"},
  {"name": "Cayman Islands", "code": "KY", "timezone": "America/Cayman"},
  {"name": "Central African Republic", "code": "CF", "timezone": "Africa/Bangui"},
  {"name": "Chad", "code": "TD", "timezone": "Africa/Ndjamena"},
  {"name": "Chile", "code": "CL", "timezone": "America/Santiago"},
  {"name": "China", "code": "CN", "timezone": "Asia/Shanghai"},
  {"name": "Christmas Island", "code": "CX", "timezone": "Indian/Christmas"},
  {"name": "Cocos (Keeling) Islands", "code": "CC", "timezone": "Indian/Cocos"},
  {"name": "Colombia", "code": "CO", "timezone": "America/Bogota"},
  {"name": "Comoros", "code": "KM", "timezone": "Indian/Comoro"},
  {"name": "Congo", "code": "CG", "timezone": "Africa/Brazzaville"},
  {"name": "Congo, The Democratic Republic of the", "code": "CD", "timezone": "Africa/Kinshasa"},
  {"name": "Cook Islands", "code": "CK", "timezone": "Pacific/Rarotonga"},
  {"name": "Costa Rica", "code": "CR", "timezone": "America/Costa_Rica"},
  {"name": "Cote D'Ivoire", "code": "CI", "timezone": "Africa/Abidjan"},
  {"name": "Croatia", "code": "HR", "timezone": "Europe/Zagreb"},
  {"name": "Cuba", "code": "CU", "timezone": "America/Havana"},
  {"name": "Cyprus", "code": "CY", "timezone": "Asia/Nicosia"},
  {"name": "Czech Republic", "code": "CZ", "timezone": "Europe/Prague"},
  {"name": "Denmark", "code": "DK", "timezone": "Europe/Copenhagen"},
  {"name": "Djibouti", "code": "DJ", "timezone": "Africa/Djibouti"},
  {"name": "Dominica", "code": "DM", "timezone": "America/Dominica"},
  {"name": "Dominican Republic", "code": "DO", "timezone": "America/Santo_Domingo"},
  {"name": "Ecuador", "code": "EC", "timezone": "America/Guayaquil"},
  {"name": "Egypt", "code": "EG", "timezone": "Africa/Cairo"},
  {"name": "El Salvador", "code": "SV", "timezone": "America/El_Salvador"},
  {"name": "Equatorial Guinea", "code": "GQ", "timezone": "Africa/Malabo"},
  {"name": "Eritrea", "code": "ER", "timezone": "Africa/Asmara"},
  {"name": "Estonia", "code": "EE", "timezone": "Europe/Tallinn"},
  {"name": "Ethiopia", "code": "ET", "timezone": "Africa/Addis_Ababa"},
  {"name": "Falkland Islands (Malvinas)", "code": "FK", "timezone": "Atlantic/Stanley"},
  {"name": "Faroe Islands", "code": "FO", "timezone": "Atlantic/Faroe"},
  {"name": "Fiji", "code": "FJ", "timezone": "Pacific/Fiji"},
  {"name": "Finland", "code": "FI", "timezone": "Europe/Helsinki"},
  {"name": "France", "code": "FR", "timezone": "Europe/Paris"},
  {"name": "French Guiana", "code": "GF", "timezone": "America/Cayenne"},
  {"name": "French Polynesia", "code": "PF", "timezone": "Pacific/Tahiti"},
  {"name": "French Southern Territories", "code": "TF", "timezone": "Indian/Kerguelen"},
  {"name": "Gabon", "code": "GA", "timezone": "Africa/Libreville"},
  {"name": "Gambia", "code": "GM", "timezone": "Africa/Banjul"},
  {"name": "Georgia", "code": "GE", "timezone": "Asia/Tbilisi"},
  {"name": "Germany", "code": "DE", "timezone": "Europe/Berlin"},
  {"name": "Ghana", "code": "GH", "timezone": "Africa/Accra"},
  {"name": "Gibraltar", "code": "GI", "timezone": "Europe/Gibraltar"},
  {"name": "Greece", "code": "GR", "timezone": "Europe/Athens"},
  {"name": "Greenland", "code": "GL", "timezone": "America/Godthab"},
  {"name": "Grenada", "code": "GD", "timezone": "America/Grenada"},
  {"name": "Guadeloupe", "code": "GP", "timezone": "America/Guadeloupe"},
  {"name": "Guam", "code": "GU", "timezone": "Pacific/Guam"},
  {"name": "Guatemala", "code": "GT", "timezone": "America/Guatemala"},
  {"name": "Guernsey", "code": "GG", "timezone": "Europe/Guernsey"},
  {"name": "Guinea", "code": "GN", "timezone": "Africa/Conakry"},
  {"name": "Guinea-Bissau", "code": "GW", "timezone": "Africa/Bissau"},
  {"name": "Guyana", "code": "GY", "timezone": "America/Guyana"},
  {"name": "Haiti", "code": "HT", "timezone": "America/Port-au-Prince"},
  {"name": "Heard Island and Mcdonald Islands", "code": "HM", "timezone": "Indian/Kerguelen"},
  {"name": "Holy See (Vatican City State)", "code": "VA", "timezone": "Europe/Vatican"},
  {"name": "Honduras", "code": "HN", "timezone": "America/Tegucigalpa"},
  {"name": "Hong Kong", "code": "HK", "timezone": "Asia/Hong_Kong"},
  {"name": "Hungary", "code": "HU", "timezone": "Europe/Budapest"},
  {"name": "Iceland", "code": "IS", "timezone": "Atlantic/Reykjavik"},
  {"name": "India", "code": "IN", "timezone": "Asia/Kolkata"},
  {"name": "Indonesia", "code": "ID", "timezone": "Asia/Jakarta"},
  {"name": "Iran, Islamic Republic Of", "code": "IR", "timezone": "Asia/Tehran"},
  {"name": "Iraq", "code": "IQ", "timezone": "Asia/Baghdad"},
  {"name": "Ireland", "code": "IE", "timezone": "Europe/Dublin"},
  {"name": "Isle of Man", "code": "IM", "timezone": "Europe/Isle_of_Man"},
  {"name": "Israel", "code": "IL", "timezone": "Asia/Jerusalem"},
  {"name": "Italy", "code": "IT", "timezone": "Europe/Rome"},
  {"name": "Jamaica", "code": "JM", "timezone": "America/Jamaica"},
  {"name": "Japan", "code": "JP", "timezone": "Asia/Tokyo"},
  {"name": "Jersey", "code": "JE", "timezone": "Europe/Jersey"},
  {"name": "Jordan", "code": "JO", "timezone": "Asia/Amman"},
  {"name": "Kazakhstan", "code": "KZ", "timezone": "Asia/Almaty"},
  {"name": "Kenya", "code": "KE", "timezone": "Africa/Nairobi"},
  {"name": "Kiribati", "code": "KI", "timezone": "Pacific/Tarawa"},
  {"name": "Korea, Democratic People'S Republic of", "code": "KP", "timezone": "Asia/Pyongyang"},
  {"name": "Korea, Republic of", "code": "KR", "timezone": "Asia/Seoul"},
  {"name": "Kuwait", "code": "KW", "timezone": "Asia/Kuwait"},
  {"name": "Kyrgyzstan", "code": "KG", "timezone": "Asia/Bishkek"},
  {"name": "Lao People'S Democratic Republic", "code": "LA", "timezone": "Asia/Vientiane"},
  {"name": "Latvia", "code": "LV", "timezone": "Europe/Riga"},
  {"name": "Lebanon", "code": "LB", "timezone": "Asia/Beirut"},
  {"name": "Lesotho", "code": "LS", "timezone": "Africa/Maseru"},
  {"name": "Liberia", "code": "LR", "timezone": "Africa/Monrovia"},
  {"name": "Libyan Arab Jamahiriya", "code": "LY", "timezone": "Africa/Tripoli"},
  {"name": "Liechtenstein", "code": "LI", "timezone": "Europe/Vaduz"},
  {"name": "Lithuania", "code": "LT", "timezone": "Europe/Vilnius"},
  {"name": "Luxembourg", "code": "LU", "timezone": "Europe/Luxembourg"},
  {"name": "Macao", "code": "MO", "timezone": "Asia/Macau"},
  {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK", "timezone": "Europe/Skopje"},
  {"name": "Madagascar", "code": "MG", "timezone": "Indian/Antananarivo"},
  {"name": "Malawi", "code": "MW", "timezone": "Africa/Blantyre"},
  {"name": "Malaysia", "code": "MY", "timezone": "Asia/Kuala_Lumpur"},
  {"name": "Maldives", "code": "MV", "timezone": "Indian/Maldives"},
  {"name": "Mali", "code": "ML", "timezone": "Africa/Bamako"},
  {"name": "Malta", "code": "MT", "timezone": "Europe/Malta"},
  {"name": "Marshall Islands", "code": "MH", "timezone": "Pacific/Majuro"},
  {"name": "Martinique", "code": "MQ", "timezone": "America/Martinique"},
  {"name": "Mauritania", "code": "MR", "timezone": "Africa/Nouakchott"},
  {"name": "Mauritius", "code": "MU", "timezone": "Indian/Mauritius"},
  {"name": "Mayotte", "code": "YT", "timezone": "Indian/Mayotte"},
  {"name": "Mexico", "code": "MX", "timezone": "America/Mexico_City"},
  {"name": "Micronesia, Federated States of", "code": "FM", "timezone": "Pacific/Chuuk"},
  {"name": "Moldova, Republic of", "code": "MD", "timezone": "Europe/Chisinau"},
  {"name": "Monaco", "code": "MC", "timezone": "Europe/Monaco"},
  {"name": "Mongolia", "code": "MN", "timezone": "Asia/Ulaanbaatar"},
  {"name": "Montenegro", "code": "ME", "timezone": "Europe/Podgorica"},
  {"name": "Montserrat", "code": "MS", "timezone": "America/Montserrat"},
  {"name": "Morocco", "code": "MA", "timezone": "Africa/Casablanca"},
  {"name": "Mozambique", "code": "MZ", "timezone": "Africa/Maputo"},
  {"name": "Myanmar", "code": "MM", "timezone": "Asia/Yangon"},
  {"name": "Namibia", "code": "NA", "timezone": "Africa/Windhoek"},
  {"name": "Nauru", "code": "NR", "timezone": "Pacific/Nauru"},
  {"name": "Nepal", "code": "NP", "timezone": "Asia/Kathmandu"},
  {"name": "Netherlands", "code": "NL", "timezone": "Europe/Amsterdam"},
  {"name": "Netherlands Antilles", "code": "AN", "timezone": "America/Curacao"},
  {"name": "New Caledonia", "code": "NC", "timezone": "Pacific/Noumea"},
  {"name": "New Zealand", "code": "NZ", "timezone": "Pacific/Auckland"},
  {"name": "Nicaragua", "code": "NI", "timezone": "America/Managua"},
  {"name": "Niger", "code": "NE", "timezone": "Africa/Niamey"},
  {"name": "Nigeria", "code": "NG", "timezone": "Africa/Lagos"},
  {"name": "Niue", "code": "NU", "timezone": "Pacific/Niue"},
  {"name": "Norfolk Island", "code": "NF", "timezone": "Pacific/Norfolk"},
  {"name": "Northern Mariana Islands", "code": "MP", "timezone": "Pacific/Saipan"},
  {"name": "Norway", "code": "NO", "timezone": "Europe/Oslo"},
  {"name": "Oman", "code": "OM", "timezone": "Asia/Muscat"},
  {"name": "Pakistan", "code": "PK", "timezone": "Asia/Karachi"},
  {"name": "Palau", "code": "PW", "timezone": "Pacific/Palau"},
  {"name": "Palestinian Territory, Occupied", "code": "PS", "timezone": "Asia/Gaza"},
  {"name": "Panama", "code": "PA", "timezone": "America/Panama"},
  {"name": "Papua New Guinea", "code": "PG", "timezone": "Pacific/Port_Moresby"},
  {"name": "Paraguay", "code": "PY", "timezone": "America/Asuncion"},
  {"name": "Peru", "code": "PE", "timezone": "America/Lima"},
  {"name": "Philippines", "code": "PH", "timezone": "Asia/Manila"},
  {"name": "Pitcairn", "code": "PN", "timezone": "Pacific/Pitcairn"},
  {"name": "Poland", "code": "PL", "timezone": "Europe/Warsaw"},
  {"name": "Portugal", "code": "PT", "timezone": "Europe/Lisbon"},
  {"name": "Puerto Rico", "code": "PR", "timezone": "America/Puerto_Rico"},
  {"name": "Qatar", "code": "QA", "timezone": "Asia/Qatar"},
  {"name": "Reunion", "code": "RE", "timezone": "Indian/Reunion"},
  {"name": "Romania", "code": "RO", "timezone": "Europe/Bucharest"},
  {"name": "Russian Federation", "code": "RU", "timezone": "Europe/Moscow"},
  {"name": "RWANDA", "code": "RW", "timezone": "Africa/Kigali"},
  {"name": "Saint Helena", "code": "SH", "timezone": "Atlantic/St_Helena"},
  {"name": "Saint Kitts and Nevis", "code": "KN", "timezone": "America/St_Kitts"},
  {"name": "Saint Lucia", "code": "LC", "timezone": "America/St_Lucia"},
  {"name": "Saint Pierre and Miquelon", "code": "PM", "timezone": "America/Miquelon"},
  {"name": "Saint Vincent and the Grenadines", "code": "VC", "timezone": "America/St_Vincent"},
  {"name": "Samoa", "code": "WS", "timezone": "Pacific/Apia"},
  {"name": "San Marino", "code": "SM", "timezone": "Europe/San_Marino"},
  {"name": "Sao Tome and Principe", "code": "ST", "timezone": "Africa/Sao_Tome"},
  {"name": "Saudi Arabia", "code": "SA", "timezone": "Asia/Riyadh"},
  {"name": "Senegal", "code": "SN", "timezone": "Africa/Dakar"},
  {"name": "Serbia", "code": "RS", "timezone": "Europe/Belgrade"},
  {"name": "Seychelles", "code": "SC", "timezone": "Indian/Mahe"},
  {"name": "Sierra Leone", "code": "SL", "timezone": "Africa/Freetown"},
  {"name": "Singapore", "code": "SG", "timezone": "Asia/Singapore"},
  {"name": "Slovakia", "code": "SK", "timezone": "Europe/Bratislava"},
  {"name": "Slovenia", "code": "SI", "timezone": "Europe/Ljubljana"},
  {"name": "Solomon Islands", "code": "SB", "timezone": "Pacific/Guadalcanal"},
  {"name": "Somalia", "code": "SO", "timezone": "Africa/Mogadishu"},
  {"name": "South Africa", "code": "ZA", "timezone": "Africa/Johannesburg"},
  {"name": "South Georgia and the South Sandwich Islands", "code": "GS", "timezone": "Atlantic/South_Georgia"},
  {"name": "Spain", "code": "ES", "timezone": "Europe/Madrid"},
  {"name": "Sri Lanka", "code": "LK", "timezone": "Asia/Colombo"},
  {"name": "Sudan", "code": "SD", "timezone": "Africa/Khartoum"},
  {"name": "Suriname", "code": "SR", "timezone": "America/Paramaribo"},
  {"name": "Svalbard and Jan Mayen", "code": "SJ", "timezone": "Arctic/Longyearbyen"},
  {"name": "Swaziland", "code": "SZ", "timezone": "Africa/Mbabane"},
  {"name": "Sweden", "code": "SE", "timezone": "Europe/Stockholm"},
  {"name": "Switzerland", "code": "CH", "timezone": "Europe/Zurich"},
  {"name": "Syrian Arab Republic", "code": "SY", "timezone": "Asia/Damascus"},
  {"name": "Taiwan, Province of China", "code": "TW", "timezone": "Asia/Taipei"},
  {"name": "Tajikistan", "code": "TJ", "timezone": "Asia/Dushanbe"},
  {"name": "Tanzania, United Republic of", "code": "TZ", "timezone": "Africa/Dar_es_Salaam"},
  {"name": "Thailand", "code": "TH", "timezone": "Asia/Bangkok"},
  {"name": "Timor-Leste", "code": "TL", "timezone": "Asia/Dili"},
  {"name": "Togo", "code": "TG", "timezone": "Africa/Lome"},
  {"name": "Tokelau", "code": "TK", "timezone": "Pacific/Fakaofo"},
  {"name": "Tonga", "code": "TO", "timezone": "Pacific/Tongatapu"},
  {"name": "Trinidad and Tobago", "code": "TT", "timezone": "America/Port_of_Spain"},
  {"name": "Tunisia", "code": "TN", "timezone": "Africa/Tunis"},
  {"name": "Turkey", "code": "TR", "timezone": "Europe/Istanbul"},
  {"name": "Turkmenistan", "code": "TM", "timezone": "Asia/Ashgabat"},
  {"name": "Turks and Caicos Islands", "code": "TC", "timezone": "America/Grand_Turk"},
  {"name": "Tuvalu", "code": "TV", "timezone": "Pacific/Funafuti"},
  {"name": "Uganda", "code": "UG", "timezone": "Africa/Kampala"},
  {"name": "Ukraine", "code": "UA", "timezone": "Europe/Kiev"},
  {"name": "United Arab Emirates", "code": "AE", "timezone": "Asia/Dubai"},
  {"name": "United Kingdom", "code": "GB", "timezone": "Europe/London"},
  {"name": "United States", "code": "US", "timezone": "America/New_York"},
  {"name": "United States Minor Outlying Islands", "code": "UM", "timezone": "Pacific/Midway"},
  {"name": "Uruguay", "code": "UY", "timezone": "America/Montevideo"},
  {"name": "Uzbekistan", "code": "UZ", "timezone": "Asia/Tashkent"},
  {"name": "Vanuatu", "code": "VU", "timezone": "Pacific/Efate"},
  {"name": "Venezuela", "code": "VE", "timezone": "America/Caracas"},
  {"name": "Viet Nam", "code": "VN", "timezone": "Asia/Ho_Chi_Minh"},
  {"name": "Virgin Islands, British", "code": "VG", "timezone": "America/Tortola"},
  {"name": "Virgin Islands, U.S.", "code": "VI", "timezone": "America/St_Thomas"},
  {"name": "Wallis and Futuna", "code": "WF", "timezone": "Pacific/Wallis"},
  {"name": "Western Sahara", "code": "EH", "timezone": "Africa/El_Aaiun"},
  {"name": "Yemen", "code": "YE", "timezone": "Asia/Aden"},
  {"name": "Zambia", "code": "ZM", "timezone": "Africa/Lusaka"},
  {"name": "Zimbabwe", "code": "ZW", "timezone": "Africa/Harare"},
]

export {
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
  cookies,
  resizeImage,
  getEndDatesForAPI,
  convertSeconds,
  formatDateTime,
  formatDate,
  formatIsoDate,
  getAllWeekDatesByDate,
  formatTime,
  formatPercentage,
  formatDecimal,
  validateEmail,
  setTokenCookies,
  isUserRightLevel,
  isFloat,
  cleanObject,
  rgb2hex,
  getHighlightedText,
  isoLangs,
  currency,
  timeZones,
  countryCodes,
  escapeHtmlSpecialChars,
  getDateOfWeek,
  firstLetterUppercase,
};
