import React, { Component } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import DatePicker from "react-datepicker";

import { time_filter_options } from "../../pages/Analytics/Common/analyticsIntialData";
import { isEmpty } from 'lodash';
import {
  DEFAULT_DATE_STATE,
  formatDate,
  getEndDatesForAPI,
} from "../../services/utilities/utilservice";

interface DateFilterProps {
  startDate: string | null;
  endDate: string | null;
  periodValue: string | null;
  isDisabled?: boolean;
  onDateChange: (
    startDate: null | string,
    endDate: null | string,
    globalPeriod: null | string
  ) => void;
}

interface DateFilterState {
  mainFilterOpen: boolean;
  datePickerOpen: boolean;
  startDateData: string | null;
  endDateData: string | null;
  tempStartDate: string | null;
}

class DateFilterControlled extends Component<DateFilterProps, DateFilterState> {
  dropdownRef: React.RefObject<HTMLDivElement>;

  constructor(props: DateFilterProps) {
    super(props);

    this.state = {
      mainFilterOpen: false,
      datePickerOpen: false,
      startDateData: null,
      endDateData: null,
      tempStartDate: null,
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.handleRenderDates();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Readonly<DateFilterProps>, prevState: Readonly<DateFilterState>) {
    const { startDate, endDate, periodValue } = this.props;
    if (
      startDate !== prevProps.startDate
      || endDate !== prevProps.endDate
      || periodValue !== prevProps.periodValue
      || this.state.tempStartDate !== prevState.tempStartDate
    ) {
      this.handleRenderDates();
      this.closeDatePicker();
    }
  }

  handleRenderDates = () => {
    const { startDate, endDate, periodValue } = this.props;
    const periodValueToString = !isEmpty(periodValue) ? periodValue : null;
    let startDateData = null, endDateData = null;

    if (!isEmpty(periodValueToString) && periodValueToString !== DEFAULT_DATE_STATE) {
      const { startdate, enddate } = getEndDatesForAPI(periodValueToString) || {};
      startDateData = startdate;
      endDateData = enddate;
    } else if (periodValueToString !== DEFAULT_DATE_STATE) {
      startDateData = startDate;
      endDateData = endDate;
    }
    this.setState({ startDateData, endDateData });
  };

  handleClickOutside = (event: MouseEvent) => {
    if (event.target instanceof Node) {
      if (
        this.dropdownRef.current &&
        !this.dropdownRef.current.contains(event.target)
      ) {
        if (this.state.tempStartDate) {
          this.props.onDateChange(this.state.tempStartDate, this.props.endDate, null);
        }
        this.closeMainFilter();
      }
    }
  };

  getDisplayText = (
    startDate: string | null,
    endDate: string | null,
    periodValue: string | null
  ): string => {
    if (Number(periodValue)) {
      return String(time_filter_options[Number(periodValue)].label);
    } else if (startDate && endDate) {
      return "Date Range";
    } else {
      return "Date";
    }
  };

  handleTimeChange = (value: any) => {
    const { onDateChange } = this.props;
    const currentTimeDates = getEndDatesForAPI(value);
    onDateChange(currentTimeDates.startdate, currentTimeDates.enddate, value);
    this.closeMainFilter();
  };

  handleRangeDateStart = (date: Date) => {
    this.setState({ tempStartDate: formatDate(date) });
  };

  handleRangeDateEnd = (date: Date) => {
    const { startDate, onDateChange } = this.props;
    onDateChange(this.state.tempStartDate || startDate, formatDate(date), null);
    this.closeMainFilter();
  };

  closeDatePicker = () => {
    this.setState({ datePickerOpen: false });
  };

  closeMainFilter = () => {
    this.closeDatePicker();
    this.setState({ mainFilterOpen: false, tempStartDate: null });
  }

  render() {
    const { startDate, endDate, periodValue, isDisabled } = this.props;
    const { mainFilterOpen, datePickerOpen, startDateData, endDateData, tempStartDate } = this.state;

    // Purpose of tempStartDate: when start date selected, don't trigger update until either end date selected, or main filter closed
    const startDateToShow = tempStartDate || startDateData;
    const startDateToShowAsDate = startDateToShow ? new Date(startDateToShow) : null;
    const endDateAsDate = endDateData ? new Date(endDateData) : null;

    const datePickerHeight = datePickerOpen ? "360px" : "auto";
    const datePickerWidth = datePickerOpen ? "300px" : "auto";

    return (
      <div ref={this.dropdownRef}>
        <UncontrolledDropdown isOpen={mainFilterOpen} direction="down" disabled={isDisabled} className="global-date-filter">
          <DropdownToggle
            tag="button"
            className={`btn btn-primary ${isDisabled ? 'disabled' : ''}`}
            style={{ backgroundColor: isDisabled ? 'hsl(0,0%,95%)' : '' }}
            onClick={() =>
              this.setState({ mainFilterOpen: !mainFilterOpen })
            }
          >
            {this.getDisplayText(startDate, endDate, periodValue)}
            <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu style={{ width: "300px" }}>
            {time_filter_options.map((data, key) => {
              return (
                <DropdownItem
                  key={key}
                  onClick={(event: any) => {
                    this.handleTimeChange(event.target.value);
                  }}
                  value={key}
                >
                  {data.label}
                </DropdownItem>
              );
            })}
            <div
              style={{
                height: datePickerHeight,
                width: datePickerWidth,
              }}
            >
              <DropdownItem>
                <div style={{ display: "grid" }}>
                  <div className="my-2">Date Range</div>
                  <div
                    style={{ width: 'fit-content' }}
                    onClick={() =>
                      this.setState({ datePickerOpen: true })
                    }
                  >
                    <DatePicker
                      selectsStart
                      selected={startDateToShowAsDate}
                      endDate={endDateAsDate}
                      maxDate={endDateAsDate}
                      onChange={this.handleRangeDateStart}
                      onCalendarClose={this.closeDatePicker}
                      onBlur={this.closeDatePicker}
                    />
                  </div>
                  <div
                  style={{ width: 'fit-content' }}
                  onClick={() =>
                    this.setState({ datePickerOpen: true })
                  }>
                    <DatePicker
                      className="mb-2"
                      selectsEnd
                      selected={endDateAsDate}
                      startDate={startDateToShowAsDate}
                      minDate={startDateToShowAsDate}
                      onChange={this.handleRangeDateEnd}
                      onCalendarClose={this.closeDatePicker}
                      onBlur={this.closeDatePicker}
                    />
                  </div>
                </div>
              </DropdownItem>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }
}

export default DateFilterControlled;
