import React, { Component } from 'react';

import MultipleSelect from '../../MultipleSelect';
import { DropdownOption } from '../../../pages/Home/utils/constants';
import {
  convertToDropdownOptions,
  filterOutUsersByTeam,
} from '../utils/helperfunctions';
import {
  FilterObject,
  ICallOutcome,
  ICampaign,
  IDealStatus,
  IFilterTeam,
  IFilterUser,
  IMeetingTypes,
  IRecordingType,
} from '../../../store/interface';
import DateFilterControlled from '../../Filters/DateFilterControlled';
import FilterQueryBuilderModal from '../../FilterQueryBuilder/Modal';
import { RuleGroupType } from '@react-querybuilder/ts';
import MagicFilterButton from '../../FilterQueryBuilder/MagicFilterButton';

interface GlobalFilterComponentProps {
  filters: FilterObject;
  isMeetingOrRecording: boolean;
  isUsedForEditDashboardModal?: boolean;
  isUsedForAgentEditPage?: boolean;
  isUsedForRecording?: boolean;
  recordingTypes: IRecordingType[];
  campaigns: ICampaign[];
  meetingTypes: IMeetingTypes[];
  filterUsers: IFilterUser[];
  filterTeams: IFilterTeam[];
  callsOutcome: ICallOutcome[];
  dealsStatus: IDealStatus[];
  isForcedCampaigns: boolean;
  handleDateChange: (
    startDate: string | null,
    endDate: string | null,
    globalPeriod: null | string
  ) => void;
  updateFilter: (
    filterType: string,
    options: DropdownOption[] | DropdownOption
  ) => void;
  onUpdateQueryBuilderObject?: (queryBuilderObject: RuleGroupType | undefined) => void;
}

interface GlobalFilterComponentState {
  magicFiltersOpen: boolean;
}

class GlobalFilterComponent extends Component<
  GlobalFilterComponentProps,
  GlobalFilterComponentState
> {
  constructor(props: GlobalFilterComponentProps) {
    super(props);
    this.state = {
      magicFiltersOpen: false,
    }
  }
  toggleMagicFilters = () => {
    this.setState((prevState) => ({
      magicFiltersOpen: !prevState.magicFiltersOpen,
    }));
  };

  handleSaveMagicFilters = (query: RuleGroupType | undefined) => {
    const { onUpdateQueryBuilderObject } = this.props;

    this.toggleMagicFilters();

    if (onUpdateQueryBuilderObject) {
      onUpdateQueryBuilderObject(query)
    }
  }

  getQueryFilterRulesCount = (): number => {
    const { filters } = this.props;

    if (!filters.queryBuilderObject) {
      return 0;
    }

    return filters.queryBuilderObject?.rules.length ?? 0;
  }

  render() {
    const {
      isMeetingOrRecording,
      recordingTypes,
      campaigns,
      meetingTypes,
      filterTeams,
      filterUsers,
      callsOutcome,
      dealsStatus,
      filters,
      isUsedForEditDashboardModal,
      isForcedCampaigns,
      isUsedForAgentEditPage,
      isUsedForRecording,
      updateFilter,
      handleDateChange,
      onUpdateQueryBuilderObject,
    } = this.props;

    const { magicFiltersOpen } = this.state;

    const recordingTypeOptions = convertToDropdownOptions(
      recordingTypes,
      'recordingTypes'
    );
    const recordingTypeDefaultValue = convertToDropdownOptions(
      filters.recordingTypes,
      'recordingTypes'
    );

    const campaignOptions = convertToDropdownOptions(campaigns, 'campaigns');
    const campaignDefaultValue = convertToDropdownOptions(
      filters.campaigns,
      'campaigns'
    );

    const meetingTypeOptions = convertToDropdownOptions(meetingTypes);
    const meetingTypeDefaultValue = convertToDropdownOptions(
      filters.meetingTypes
    );

    const filterTeamOptions = convertToDropdownOptions(filterTeams);
    const teamDefaultValue = convertToDropdownOptions(filters.filterTeams);

    const filterUserOptions = filterOutUsersByTeam(
      teamDefaultValue,
      filterUsers
    );
    const userDefaultValue = filterOutUsersByTeam(
      teamDefaultValue,
      filters.filterUsers
    );

    const dealsStatusOptions = convertToDropdownOptions(
      dealsStatus,
      'dealsStatus'
    );
    const dealsStatusDefaultValue = convertToDropdownOptions(
      filters.dealsStatus,
      'dealsStatus'
    );

    const callsOutcomeOptions = convertToDropdownOptions(
      callsOutcome,
      'callsOutcome'
    );
    const callsOutcomeDefaultValue = convertToDropdownOptions(
      filters.callsOutcome,
      'callsOutcome'
    );

    const queryFilterRulesCount = this.getQueryFilterRulesCount();

    return (
      <>
        <FilterQueryBuilderModal
          isOpen={magicFiltersOpen}
          queryBuilderObject={filters.queryBuilderObject}
          onClose={this.toggleMagicFilters}
          onSaveFilters={(q: RuleGroupType | undefined) => this.handleSaveMagicFilters(q)}
        />
        <div
          className={
            isUsedForEditDashboardModal
              ? 'default-filter'
              : isMeetingOrRecording
                ? 'meeting-filters'
                : 'filters'
          }
          style={{
            display: isMeetingOrRecording ? 'flex' : '',
            alignItems: 'center',
          }}
        >
          {!isMeetingOrRecording && !isUsedForEditDashboardModal && (
            <div className="filters__title">Filter by</div>
          )}
          <div
            className={
              isUsedForEditDashboardModal
                ? 'default-filter'
                : isMeetingOrRecording
                  ? 'meeting-header__filter long'
                  : 'filters__filter long'
            }
          >
            <MultipleSelect
              defaultValue={recordingTypeDefaultValue}
              onChange={(options: DropdownOption[]) => {
                updateFilter('recordingTypes', options);
              }}
              options={recordingTypeOptions}
              placeholder="Recording Type"
            />
          </div>
          {!isForcedCampaigns && (
            <div
              className={
                isUsedForEditDashboardModal
                  ? 'default-filter'
                  : isMeetingOrRecording
                    ? 'meeting-header__filter medium'
                    : 'filters__filter medium'
              }
            >
              <MultipleSelect
                defaultValue={campaignDefaultValue}
                onChange={(options: DropdownOption[]) => {
                  updateFilter('campaigns', options);
                }}
                options={campaignOptions}
                placeholder="Campaign"
              />
            </div>
          )}
          <>
            {
              isUsedForAgentEditPage
                ? (<></>)
                : (
                  <div
                    className={
                      isUsedForEditDashboardModal
                        ? 'default-filter'
                        : isMeetingOrRecording
                          ? 'meeting-header__filter long'
                          : 'filters__filter long'
                    }
                  >
                    <MultipleSelect
                      defaultValue={dealsStatusDefaultValue}
                      onChange={(options: DropdownOption[]) => {
                        updateFilter('dealsStatus', options);
                      }}
                      options={dealsStatusOptions}
                      placeholder="Deal Status"
                    />
                  </div>
                )
            }
            <div
              className={
                isUsedForEditDashboardModal
                  ? 'default-filter'
                  : isMeetingOrRecording
                    ? 'meeting-header__filter long'
                    : 'filters__filter long'
              }
            >
              <MultipleSelect
                defaultValue={callsOutcomeDefaultValue}
                onChange={(options: DropdownOption[]) => {
                  updateFilter('callsOutcome', options);
                }}
                options={callsOutcomeOptions}
                placeholder="Call Outcome"
              />
            </div>
          </>
          <div
            className={
              isUsedForEditDashboardModal
                ? 'default-filter'
                : isMeetingOrRecording
                  ? 'meeting-header__filter short'
                  : 'filters__filter short'
            }
          >
            <MultipleSelect
              defaultValue={teamDefaultValue}
              onChange={(options: DropdownOption[]) => {
                updateFilter('filterTeams', options);
              }}
              options={filterTeamOptions}
              placeholder="Teams"
            />
          </div>
          <div
            className={
              isUsedForEditDashboardModal
                ? 'default-filter'
                : isMeetingOrRecording
                  ? 'meeting-header__filter short'
                  : 'filters__filter short'
            }
          >
            <MultipleSelect
              defaultValue={userDefaultValue}
              onChange={(options: DropdownOption[]) => {
                updateFilter('filterUsers', options);
              }}
              options={filterUserOptions}
              placeholder="Users"
            />
          </div>
          <div
            className={
              isUsedForEditDashboardModal
                ? 'default-filter'
                : isMeetingOrRecording
                  ? 'meeting-header__filter long'
                  : 'filters__filter long'
            }
          >
            <MultipleSelect
              defaultValue={meetingTypeDefaultValue}
              onChange={(options: DropdownOption[]) => {
                updateFilter('meetingTypes', options);
              }}
              options={meetingTypeOptions}
              placeholder="Meeting Types"
            />
          </div>
          {!isUsedForAgentEditPage && (isUsedForRecording || isUsedForEditDashboardModal) && (
            <MagicFilterButton
              className={
                isUsedForEditDashboardModal
                  ? 'magic-filter-modal'
                  : isMeetingOrRecording
                    ? 'meeting-header__filter short'
                    : 'filters__filter filters__filter--date-time'
              }
              styleDiv={!isUsedForEditDashboardModal ? undefined : {
                backgroundColor: '#FFFFFF',
                color: '#000000',
                cursor: 'pointer',
                padding: '4px 0 0 0',
              }}
              styleParagraph={!isUsedForEditDashboardModal ? undefined : {
                margin: '4px 0 0 1px',
                padding: '2px 0 0 2px',
                color: queryFilterRulesCount > 0 ? '#000000' : '#74788d',
                fontWeight: queryFilterRulesCount > 0 ? 'bold' : 'normal',
              }}
              styleArrow={!isUsedForEditDashboardModal ? undefined : {
                margin: '4px 8px 0 0',
              }}
              filtersCount={queryFilterRulesCount}
              onClick={this.toggleMagicFilters}
            />
          )}
          {isUsedForAgentEditPage ? (
            <></>
          ) : (
            <div
              className={
                isUsedForEditDashboardModal
                  ? 'default-filter'
                  : isMeetingOrRecording
                    ? 'meeting-header__filter short'
                    : 'filters__filter filters__filter--date-time'
              }
              style={{
                backgroundColor: 'white',
                color: '#ced4da',
                borderRadius: '4px',
              }}
            >
              <DateFilterControlled
                startDate={filters.startDate}
                endDate={filters.endDate}
                periodValue={filters.globalPeriod}
                onDateChange={handleDateChange}
              />
            </div>
          )}
        </div>
        {isUsedForAgentEditPage && <div style={{ marginBottom: '10px' }}/>}
      </>
    );
  }
}

export default GlobalFilterComponent;
