import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

// @ts-ignore
import QueryBuilder from 'react-querybuilder';
import { Controls, Field, Option, RuleGroupType } from '@react-querybuilder/ts';

import './filter-query-builder.css';

import { IVariable } from '../../../store/Variable/interface';
import { EmptyRules } from './constants';
import Select, { MenuPlacement } from 'react-select';
import { isEqual } from 'lodash';

interface FilterQueryBuilderProps {
  variables: IVariable[];
  defaultQuery?: RuleGroupType;
  onQueryChange?: (query: RuleGroupType, fields: Field[]) => void;
}

interface FilterQueryBuilderState {
  fields: Field[];
  query?: RuleGroupType;
  customControlElements: Partial<Controls>;
}

class FilterQueryBuilder extends Component<FilterQueryBuilderProps, FilterQueryBuilderState> {
  constructor(props: FilterQueryBuilderProps) {
    super(props);
    this.state = {
      fields: [],
      query: props.defaultQuery ?? EmptyRules,
      customControlElements: this.getControlElements(),
    };
  }

  componentDidMount() {
    this.initializeFields();
  }

  componentDidUpdate(prevProps: FilterQueryBuilderProps) {
    if (!isEqual(prevProps.variables, this.props.variables)) {
      this.initializeFields();
    }
  }

  getControlElements = () => ({
    addRuleAction: () => null,
    addGroupAction: (props: any) => this.renderAddGroupAction(props, this.isTopLevel),
    combinatorSelector: (props: any) => this.renderCombinatorSelector(props, this.isTopLevel, this.getMenuPlacement(props.path)),
    fieldSelector: (props: any) => this.renderFieldSelector(props, this.getMenuPlacement(props.path)),
    valueEditor: (props: any) => this.renderValueEditor(props, this.getMenuPlacement(props.path)),
    operatorSelector: (props: any) => this.renderOperatorSelector(props, this.getMenuPlacement(props.path)),
    removeRuleAction: this.renderRemoveGroupAction,
    removeGroupAction: () => null,
  });

  initializeFields = () => {
    const { variables, defaultQuery } = this.props;

    const fields: Field[] = variables.filter(v => v.type === 'boolean').map(variable => {
      const { name, type, extra } = variable;

      const operatorOptions = this.getOperatorOptions(type)

      if (type === 'number') {
        return {
          uuid: variable.uuid,
          name,
          label: name,
          operators: operatorOptions,
          inputType: 'number',
        };
      }

      if (type === 'list') {
        return {
          uuid: variable.uuid,
          name,
          label: name,
          operators: operatorOptions,
          valueEditorType: 'select',
          values: (extra.list || []).map((value: string) => ({ name: value, label: value })),
        };
      }

      if (type === 'text') {
        return {
          uuid: variable.uuid,
          name,
          label: name,
          operators: operatorOptions,
          placeholder: `Enter ${name}`,
        };
      }

      if (type === 'boolean') {
        return {
          uuid: variable.uuid,
          name,
          label: name,
          operators: operatorOptions,
          valueEditorType: 'select',
          values: [
            { name: 'true', label: 'True' },
            { name: 'false', label: 'False' },
          ],
        };
      }

      return {
        uuid: variable.uuid,
        name,
        label: name,
        operators: operatorOptions,
      };
    });

    this.setState({ fields }, () => {
      if (defaultQuery) {
        this.setState({ query: defaultQuery });
      }
    })
  };

  getOperatorOptions = (type: string): Option<string>[] => {
    const operatorMap: Record<string, string[]> = {
      number: ['Equals', 'Equals not', 'Less than', 'Greater than'],
      text: ['Equals', 'Equals not', 'Contains'],
      boolean: ['Equals'],
      list: ['Equals', 'Equals not'],
    };

    const operators = operatorMap[type] || ['Is', 'Is not'];

    return operators.map(op => ({ name: op, label: op }));
  };

  handleQueryChange = (updatedQuery: RuleGroupType) => {
    if (isEqual(this.state.query, updatedQuery)) return;
    const cleanedQuery = this.cleanEmptyGroups(updatedQuery);
    this.setState({ query: cleanedQuery }, () => {
      if (this.props.onQueryChange) {
        this.props.onQueryChange(cleanedQuery, this.state.fields);
      }
    });
  };

  cleanEmptyGroups = (query: RuleGroupType): RuleGroupType => {
    return {
      ...query,
      rules: query.rules
        .filter((rule) => {
          // Remove empty rule groups
          if ('rules' in rule) {
            return rule.rules.length > 0;
          }
          return true;
        })
        .map((rule) => {
          if ('rules' in rule) {
            return this.cleanEmptyGroups(rule);
          }
          return rule;
        }),
    };
  };

  isTopLevel = (path: number[]) => path.length === 0;

  isLastRule = (path: number[]): boolean => {
    const { query } = this.state;

    if (!query || !query.rules || query.rules.length === 0) {
      return false;
    }

    const rulesLength = query.rules.length;

    // Case 1: Exactly three rules -> Only the last rule
    if (rulesLength === 2 || rulesLength === 3) {
      return path[0] === rulesLength - 1;
    }

    // Case 2: More than three rules -> Last rule and second-to-last rule
    if (rulesLength > 3) {
      return path[0] === rulesLength - 1 || path[0] === rulesLength - 2;
    }

    return false;
  };

  getMenuPlacement = (path: number[]): MenuPlacement => {
    return this.isLastRule(path) ? 'top' : 'bottom';
  }

  renderAddGroupAction = ({ path, handleOnClick }: any, isTopLevel: (path: number[]) => boolean) => {
    if (isTopLevel(path)) {
      return (
        <Button
          color="primary"
          onClick={handleOnClick}
          style={{ padding: "5px 10px" }}
        >
          + Add Filter
        </Button>
      );
    }
    return null;
  };

  renderCombinatorSelector = (
    { options, path, value, handleOnChange }: any,
    isTopLevel: (path: number[]) => boolean,
    menuPlacement: MenuPlacement = 'bottom',
  ) => {
    if (!isTopLevel(path)) return null;

    const isDisabled = path[0] === 0;

    const dropdownOptions = options.map((option: any) => {
      if (option.label.toLowerCase() === 'or') {
        return {
          id: 'any',
          value: 'any',
          label: 'Any of the following',
        };
      }
      if (option.label.toLowerCase() === 'and') {
        return {
          id: 'all',
          value: 'all',
          label: 'All of the following',
        };
      }
      return option;
    });

    return (
      <div className="combinator-select">
        <Select
          className="combinator-select-row"
          classNamePrefix="custom-react-querybuilder"
          isDisabled={isDisabled}
          options={dropdownOptions}
          value={dropdownOptions.find((opt: any) => opt.value === value) ?? dropdownOptions[0]}
          onChange={(selectedOption) =>
            handleOnChange(selectedOption ? selectedOption.value : null)
          }
          placeholder="Select Condition"
          isSearchable={false}
          menuPlacement={menuPlacement}
        />
      </div>
    );
  };

  renderFieldSelector = (
    { options, value, handleOnChange }: any,
    menuPlacement: MenuPlacement = 'bottom',
  ) => {
    const dropdownOptions = options.map((option: any) => ({
      value: option.label,
      label: option.label,
    }));

    return (
      <div className="field-select">
        <Select
          className="field-select-row"
          classNamePrefix="custom-react-querybuilder"
          options={dropdownOptions}
          value={dropdownOptions.find((opt: any) => opt.value === value)}
          onChange={(selectedOption) =>
            handleOnChange(selectedOption ? selectedOption.value : null)
          }
          placeholder="Select Field"
          isSearchable
          menuPlacement={menuPlacement}
        />
      </div>
    );
  };

  renderValueEditor = (
    { fieldData, value, handleOnChange }: any,
    menuPlacement: MenuPlacement = 'bottom',
  ) => {
    if (fieldData?.valueEditorType === "select" && fieldData?.values) {
      const trueFalseOptions = [
        { value: "true", label: "True" },
        { value: "false", label: "False" },
      ];

      return (
        <div className="value-select">
          <Select
            className="value-select-row"
            classNamePrefix="custom-react-querybuilder"
            options={trueFalseOptions}
            value={trueFalseOptions.find((opt) => opt.value === value)}
            onChange={(selectedOption) =>
              handleOnChange(selectedOption ? selectedOption.value : null)
            }
            placeholder="Select Value"
            isSearchable={false}
            menuPlacement={menuPlacement}
          />
        </div>
      );
    }
    return null;
  };

  renderOperatorSelector = (
    { options, value, handleOnChange }: any,
    menuPlacement: MenuPlacement = 'bottom',
  ) => {
    const dropdownOptions = options.map((option: any) => ({
      value: option.label,
      label: option.label,
    }));

    return (
      <div className="operator-select">
        <Select
          className="operator-select-row"
          classNamePrefix="custom-react-querybuilder"
          options={dropdownOptions}
          value={dropdownOptions.find((opt: any) => opt.value === value)}
          onChange={(selectedOption) =>
            handleOnChange(selectedOption ? selectedOption.value : null)
          }
          placeholder="Select Operator"
          isSearchable
          menuPlacement={menuPlacement}
        />
      </div>
    );
  };

  renderRemoveGroupAction = ({ handleOnClick }: any) => {
    return (
      <Button
        color="danger"
        onClick={handleOnClick}
        style={{
          padding: "5px 10px",
          margin: "5px",
          fontSize: "12px",
          borderRadius: "6px",
        }}
      >
        <i className="fa fa-trash"></i>
      </Button>
    );
  };

  render() {
    const { customControlElements, fields, query } = this.state;

    return (
      <div className="filter-query-builder">
        <QueryBuilder
          addRuleToNewGroups
          fields={fields}
          query={query}
          onQueryChange={(updatedQuery: RuleGroupType) => this.handleQueryChange(updatedQuery)}
          controlElements={customControlElements}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { variables } = state.Variable;

  return {
    variables,
  };
};

export default connect(mapStateToProps, {})(FilterQueryBuilder);
