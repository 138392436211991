import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions";

// Other Layout related Component
import Navbar from "./Navbar";
import Header from "./Header";
import Footer from "./Footer";
// import Rightbar from "../CommonForBoth/Rightbar";
import AsyncLoader from "../Common/AsyncLoader";
import loaderImg from "../../assets/images/loader.gif";
import { Icurrentaccountselected } from "../../store/interface";
import { getDocumentTitleForPartner } from "../../utilities/helperFunctions";


interface LayoutProps {
  leftSideBarTheme: String;
  toggleRightSidebar: () => void;
  changeSidebarTheme: (type: String) => void;
  changeLayoutWidth: (type: String) => void;
  changeTopbarTheme: (type: String) => void;
  changeSidebarType: (type: String, isMobile: String) => void;
  changeLayout: (type: String) => void;
  layoutWidth: String;
  leftSideBarType: String;
  topbarTheme: String;
  showRightSidebar: String;
  isPreloader: Boolean;
  history: {
    push: any;
    goBack: () => void;
    location: any;
  };
  location: Location;
  fromIframe: boolean;
  currentaccountselected: Icurrentaccountselected;
}

interface LayoutState {
  isMobile: any;
  isMenuOpened: boolean;
}

class Layout extends Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      isMenuOpened: true
    };
    // this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
  }

  /**
  * Open/close right sidebar
  */
  toggleRightSidebar() {
    this.props.toggleRightSidebar();
  }

  capitalizeFirstLetter = (string: string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {

    const preLoader = document.getElementById("preloader");
    const status = document.getElementById("status");
    if (this.props.isPreloader === true) {
      if (preLoader) {
        preLoader.style.display = "block";
      }
      if (status) {
        status.style.display = "block";
      }

      setTimeout(function () {
        if (preLoader) {
          preLoader.style.display = "none";
        }
        if (status) {
          status.style.display = "none";
        }
      }, 2500);
    } else {
      if (preLoader) {
        preLoader.style.display = "none";
      }
      if (status) {
        status.style.display = "none";
      }
    }

    // Scroll Top to 0
    window.scrollTo(0, 0);
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

    const account = this.props.currentaccountselected;
    document.title = getDocumentTitleForPartner(currentage, account);

    this.props.changeLayout('horizontal');
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }
    if (this.props.showRightSidebar) {
      this.toggleRightSidebar();
    }
  }

  /**
   * Opens the menu - mobile
   */
  openMenu = () => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened });
  };

  render() {
    return (
      <React.Fragment>

        {/* <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div> */}

        <div id="layout-wrapper d-flex flex-column">
           {/* <Header 
            // theme={this.props.topbarTheme}
            // isMenuOpened={this.state.isMenuOpened}
            // toggleRightSidebar={this.toggleRightSidebar}
            // openLeftMenuCallBack={this.openMenu}
            history={this.props.history} 
            location={this.props.location}
            fromIframe={this.props.fromIframe}
            />*/}
          <Navbar 
            menuOpen={this.state.isMenuOpened}
            showContent={true}
          />

          <div
            className="main-content"
            style={{ marginLeft: this.props.fromIframe ? "0px" : "" }}
          >
            <React.Suspense
              fallback={
                <div className="lazyloader">
                  <div className="lazyloader-wraaper">
                    <img src={loaderImg} alt="loader" />
                  </div>
                </div>
              }
            >
              {this.props.children}
            </React.Suspense>
          </div>
          <Footer />
        </div>

        {/* <Rightbar /> */}
        <AsyncLoader />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state: any) => {
  return {
    ...state.Layout,
    currentaccountselected: state.Profile.currentaccountselected,
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter<any, any>(Layout));
