import {
  SnippetTag,
  Tag,
} from "../../pages/InspirationLibrary/utils/interface";
import {
  IAIAgentRequest,
  IAnalyticsMeetingAIAnalytics,
  IAnalyticsMeetingTimeline,
  IAnalyticsMeetings,
  IAnalyticsRecordings,
  IAssociatedTeam,
  IAssociatedUser,
  IRecordingTranscriptFilters,
  ISnippet,
  ITranscript,
  Icurrentaccountselected,
  TagType,
} from "../../store/interface";
import { DropdownOption } from '../../pages/Meetings/interface';

export interface IPermission {
  value: string;
  label: string;
  email: string;
  type: number;
  team_id: string;
  user_id: string;
  can_view: string;
  can_comment: boolean;
}

export interface VideoPlayerProps {
  sessionId: string;
  recordingSessionId: string;
  accountId: String;
  currentuser: any;
  currentaccountselected: Icurrentaccountselected;
  recording: IAnalyticsRecordings;
  recordingLoader: boolean;
  showComments: boolean;
  canComment?: boolean;
  showSnippets: boolean;
  showAddSnippet: boolean;
  isSuperAdmin: boolean;
  timeLineData: IAnalyticsMeetingTimeline[];
  snippets: ISnippet[];
  snippetPostSuccess: Object | string;
  meetingAIAnalytics?: IAnalyticsMeetingAIAnalytics[];
  meeting?: IAnalyticsMeetings;
  recordingTranscript?: ITranscript | null;
  allPlaybooksForSession?: IAIAgentRequest[] | null;
  allMagicVariableForSession?: IAIAgentRequest[] | null;
  showTranscription: boolean;
  permissions?: any[];
  snippetShareLinkId?: number | null;
  forceShowSnippetDiv?: boolean | null;
    history?: {
    push: any;
  };
  start?: string | null;
  showTabs?: string[];
  users?: IAssociatedUser[];
  teams?: IAssociatedTeam[];
  canExportCSV?: boolean;
  //all tags created by account
  accountTags?: TagType[];
  //tags from recording or snippet
  entityTags?: Tag[] | SnippetTag[];
  getTimeLine?: (accountId: String, sessionId: String) => void;
  getMeetingAIAnalytics?: (accountId: String, sessionId: String) => void;
  getRecordingSnippets?: (recordingSessionId: string) => void;
  postRecordingSnippet?: (
    sessionId: string,
    data: Object,
    onSuccess: any
  ) => void;
  handleRedirect?: () => void;
  handleSnippetShare?: (id: string) => void;
  getRecordingTranscript?: (
    sessionId: string,
    filters?: IRecordingTranscriptFilters
    ) => void;
  getPlaybookForSession?: (
    accountId: String,
    sessionId: string,
  ) => void;
  getAllPlaybooksForSession?: (
    accountId: String,
    sessionId: string,
  ) => void;
  getAllMagicVariableForSession?: (
    accountId: String,
    sessionId: string,
  ) => void;
  updateSessionLanguage?: (sessionId: string, language: string) => void;
  createShareLink?: (data: Object, onSuccess: any) => void;
  updateShareLink?: (id: number, data: Object, onSuccess: any) => void;
  updateSnippetAction?: (
    snippetUuid: string,
    data: Object,
    onSuccess: any
  ) => void;
  onAddTag?: (name: string, accountId: String) => void;
  deleteTag?: (id: string) => void;
  getAllTagsByAccount: (accountId: String) => void;
}

export interface VideoPlayerState {
  activeTab: string;
  activeMeetingTab: string;
  progress: number;
  playing: boolean;
  isVideoReady: boolean;
  isVideoAvailable: boolean;
  currentTimeSeconds: number;
  currentTime: string;
  duration: string;
  durationSeconds: number;
  axisX: number;
  axisY: number;
  snippetDivWidth: number;
  snippetStartSeconds: number;
  snippetEndSeconds: number | null;
  snippetStop: boolean;
  showSnippetDiv: boolean;
  snippetUuid: string | null;
  snippetName: string;
  snippetDescription: string;
  showSnippetForm: boolean;
  activeTranscriptItem: number | null;
  searchQuery: string;
  searchItemIds: number[];
  searchItem: number;
  transcriptAutoScroll: boolean;
  language: string;
  minimized: boolean;
  showShareModal: boolean;
  shareRecording: boolean;
  shareToUser: IPermission[];
  generalAccess: string;
  showShareLinkUrl?: boolean;
  showClipoardMessage: boolean;
  showAddCommentModal: boolean;
  useTranslation: boolean;
  selectedQuestionObjection: string;
  showFixSpeakers: boolean;
  speakerInfoByParticipantId: any;
  lastSpeakerIndexInTranscript: number;
  speakerMap: any;
  speakerMapBeforeEdit: any;
  speakerDisplayNames: any;
  showSpeakerId: boolean;
  showResetSpeakersModal: boolean;
  swapDropdownParticipantId: any;
  showNoSentencesModal: boolean;
  timelineDeleted: boolean;
  initialSpeakerMapLoaded: boolean;
  selectedPlaybook: IAIAgentRequest | null;
  selectedPlaybookDropdown: DropdownOption | null;
  selectedMagicVariables: IAIAgentRequest | null;
  selectedMagicVariablesDropdown: DropdownOption | null;
  isEditingParticipants: boolean;
  participantsDraft: any[];
}

export const navArray: {
  name: String;
  activeTab: string;
}[] = [
  {
    name: "Comments",
    activeTab: "1",
  },
  {
    name: "Snippets",
    activeTab: "2",
  },
];

export const meetingNavArray: {
  name: string;
  activeTab: string;
}[] = [
  {
    name: "Full Transcript",
    activeTab: "tab-transcript",
  },
  {
    name: "Questions",
    activeTab: "tab-questions",
  },
  {
    name: "AI Meeting Summary",
    activeTab: "tab-summary",
  },
  {
    name: "Playbook Performance",
    activeTab: "tab-playbook",
  },
  {
    name: "Magic Variables",
    activeTab: "tab-data_insights",
  },
  {
    name: "AI Chat",
    activeTab: "tab-chat",
  },
];
