import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardGroup,
  CardHeader,
  CardSubtitle,
  Button,
} from "reactstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import axios from "axios";

import ConfirmationModal from "./Modal/ConfirmationModal";
import googleCalendarImg from "../../assets/images/googleCalendarImg.png";
import googleCalendar from "../../assets/images/googleCalendar.png";
import chrome from "../../assets/images/chrome.png";
import s2 from "../../assets/images/s2.png";
import adversus from "../../assets/images/adversus.png";
import upSalesImg from "../../assets/images/upSalesImg.png";
import pipedriveImg from "../../assets/images/pipedriveImg.png";
import hubspotImg from "../../assets/images/hubspotImg.png";
import zohoImg from "../../assets/images/zohoImg.png";
import outlookImg from "../../assets/images/outlookImg.png";
import outlook from "../../assets/images/outlook.png";
import p from "../../assets/images/p.png";
import salesforce from "../../assets/images/salesforce.png";
import aircall from "../../assets/images/aircall.png";
import aircallLogo from "../../assets/images/aircall-small.png";
import telenor from "../../assets/images/telenor.png";
import telenorLogo from "../../assets/images/telenor-small.png";
import infracom from "../../assets/images/infracom.png";
import infracomLogo from "../../assets/images/infracom-small.png";
import telavox from "../../assets/images/telavox.png";
import telavoxLogo from "../../assets/images/telavox-small.png";
import dynamics365 from "../../assets/images/dynamics365.png";
import dynamics365Logo from "../../assets/images/dynamics365-small.png";
import hitrateLogo from "../../assets/images/hitrateLogo.png";
import crmServiceLogo from "../../assets/images/crmServiceLogo.png";
import crmService from "../../assets/images/crmService.png";
import { urls } from "../../utilities/urls";
import {
  FinishedIntegration,
  IntegrationType,
} from "../../store/settings/Integration/interface";
import SwitchComponent from "../../components/SwitchButton/SwitchComponent";
import { CallTokenType, Icurrentaccountselected } from "../../store/interface";
import { CALL_TOKENS } from "./CallImport/utils/constants";
import { CardTitle } from "reactstrap";
import { Row } from "reactstrap";
import { Col } from "reactstrap";
import { IntegrationModalType } from "./Modal/IntegrationsMenuModal";
import { InfracomToken } from "./Integrations";
import {
  areAllCalendarAndCRMBlacklisted,
  areAllDialersBlacklisted,
  isIntegrationBlacklisted,
  getBlacklistId,
  LYNES_INTEGRATIONS,
  TOLLRING_INTEGRATIONS, TELAVOX_INTEGRATIONS
} from '../../services/settings/integrations/constants';
import { updateDynamicsTokenFlag, updateSalesforceTokenFlag } from '../../services/settings/DynamicsIntegration';

type IntegrationsProfileProps = RouteComponentProps & {
  finishedIntegrations: FinishedIntegration[];
  isUserRightLevel: boolean;
  currentaccountselected: Icurrentaccountselected;
  isLynesEnabled: boolean;
  isTelenorEnabled: boolean;
  isTelavoxEnabled: boolean;
  isDestinyEnabled?: boolean;
  isHitrateEnabled?: boolean;
  isAircallEnabled?: boolean;
  isCRMServiceEnabled: boolean;
  areAllTokensDisabled: boolean;
  disableLynes: (accountId: String) => void
  enableLynes: (accountId: String) => void;
  disableTelenor: (accountId: String) => void
  enableTelenor: (accountId: String) => void;
  disableTelavox: () => void;
  disableDestiny?: () => void;
  disableHitrate?: () => void;
  disableAircall?: () => void;
  infracomToken?: InfracomToken|null;
  disableInfracom: () => void;
  handleOpenIntegrationModal: (
    modalType: IntegrationModalType
  ) => void;
  onRemoveIntegration: (integrationType: IntegrationType) => void;
  onUpdateIntegration: (
    integration: FinishedIntegration,
    importCalls: boolean
  ) => void;
  disableCallIntegration?: (token: string) => void;
  enableCallIntegration?: (token: CallTokenType) => void;
  disableCRMService: () => void;
};

interface IntegrationsProfileState {
  title: string;
  isModalOpen: boolean;
}

export const INTEGRATION = {
  GOOGLE_MEET: "googleMeet",
  MICROSOFT_TEAMS: "msTeams",
  HUBSPOT: "hubspot",
  PIPEDRIVE: "pipedrive",
  SALESFORCE: "salesforce",
  GOOGLE_CHROME_EXTENSION: "googleChromeExtension",
  ADVERSUS: "adversus",
  S2: "s2",
  ZOHO: "zoho",
  CRM_SERVICE: "crm-service",
  DYNAMICS_365: 'dynamics365',
};

class IntegrationsProfile extends Component<
  IntegrationsProfileProps,
  IntegrationsProfileState
> {
  constructor(props: IntegrationsProfileProps) {
    super(props);
    this.state = {
      title: "",
      isModalOpen: false,
    };
  }

  handleHubspotClick(event: any) {
    event.preventDefault();
    const redirectUrl = `${urls.baseApiURL}hubspot/login`;

    axios.get(redirectUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, "_blank");
      }
    });
  }

  handlePipedriveClick(event: any) {
    event.preventDefault();
    const redirectUrl = `${urls.baseApiURL}pipedrive/login`;

    axios.get(redirectUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, "_blank");
      }
    });
  }

  handleZohoClick(event: any) {
    event.preventDefault();
    const redirectUrl = `${urls.baseApiURL}zoho/login`;

    axios.get(redirectUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, "_blank");
      }
    });
  }

  handleDynamicsClick = (event: any) => {
    const { handleOpenIntegrationModal } = this.props;

    handleOpenIntegrationModal('dynamics365');
  }

  handleGoogleMeetClick(event: any) {
    event.stopPropagation();
    event.preventDefault();
    const redirectUrl = `${urls.baseApiURL}google-meet/login`;

    axios.get(redirectUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, "_blank");
      }
    });
  }

  handleMicrosoftTeamsClick(event: any) {
    event.preventDefault();
    const redirectUrl = `${urls.baseApiURL}ms-teams/login`;

    axios.get(redirectUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, "_blank");
      }
    });
  }

  handleAdversusAndS2Click = () => {
    const { history } = this.props;

    history.push("/settings/call-import");
  };

  handleSalesforceClick = () => {
    const authUrl = `${urls.baseApiURL}salesforce/auth-url`;

    axios.get(authUrl).then((response) => {
      const responseUrl = response?.data?.url;
      if (responseUrl) {
        window.open(responseUrl, "_blank");
      }
    });
  }

  findIntegrationByType = (
    finishedIntegrations: FinishedIntegration[],
    integrationType: string
  ): FinishedIntegration | undefined => {
    return finishedIntegrations.find(
      (integration) => integration.type === integrationType
    );
  };

  canImportCalls(integration: string) {
    return (
      integration === INTEGRATION.PIPEDRIVE ||
      integration === INTEGRATION.HUBSPOT ||
      integration === INTEGRATION.SALESFORCE
    );
  }

  disableAdversusOrS2 = () => {
    const { disableCallIntegration } = this.props;
    const { title } = this.state;

    disableCallIntegration && disableCallIntegration(title);
    this.closeModal();
  };

  openModal = (title: string) => {
    this.setState({ isModalOpen: true, title: title });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, title: "" });
  };

  enable = async (token: CallTokenType) => {
    const { enableCallIntegration } = this.props;

    enableCallIntegration && enableCallIntegration(token);
    this.closeModal();
  };

  enableLynes = async (integration: 'telink' | 'lynes') => {
    const { currentaccountselected, enableLynes, handleOpenIntegrationModal } = this.props;

    enableLynes(currentaccountselected.id);
    handleOpenIntegrationModal(integration);
  }

  disableLynes = async () => {
    const { currentaccountselected, disableLynes } = this.props;

    disableLynes(currentaccountselected.id);
    this.closeModal();
  }

  enableTelenor = async () => {
    const { currentaccountselected, enableTelenor, handleOpenIntegrationModal } = this.props;

    enableTelenor(currentaccountselected.id);
    handleOpenIntegrationModal('telenor');
  }

  disableTelenor = async () => {
    const { currentaccountselected, disableTelenor } = this.props;

    disableTelenor(currentaccountselected.id);
    this.closeModal();
  }

  enableHitrate = async () => {
    const { handleOpenIntegrationModal } = this.props;

    handleOpenIntegrationModal('hitrate');
  }

  disableHitrate = async () => {
    const { disableHitrate } = this.props;

    disableHitrate && disableHitrate();
    this.closeModal();
  }

  enableAircall = async () => {
    const { handleOpenIntegrationModal } = this.props;

    handleOpenIntegrationModal('aircall');
  }

  disableAircall = async () => {
    const { disableAircall } = this.props;

    disableAircall && disableAircall();
    this.closeModal();
  }

  enableTelavox = async (integration: 'telavox' | 'pingaway_no' | 'pingaway_se') => {
    const { handleOpenIntegrationModal } = this.props;

    handleOpenIntegrationModal(integration);
  }

  disableTelavox = async () => {
    const { disableTelavox } = this.props;

    disableTelavox();
    this.closeModal();
  }

  enableDestiny = async (integration: 'destiny' | 'tele2' | 'telia' | 'teleproffs') => {
    const { handleOpenIntegrationModal } = this.props;

    handleOpenIntegrationModal(integration);
  }

  disableDestiny = async () => {
    const { disableDestiny } = this.props;

    disableDestiny && disableDestiny();
    this.closeModal();
  }

  enableInfracom = async () => {
    const { handleOpenIntegrationModal } = this.props;
    handleOpenIntegrationModal('infracom');
  }

  disableInfracom = async () => {
    this.props.disableInfracom();
    this.closeModal();
  }

  enableCRMService = async () => {
    const { handleOpenIntegrationModal } = this.props;

    handleOpenIntegrationModal('crm-service');
  }

  disableCRMService = async () => {
    const { disableCRMService } = this.props;

    disableCRMService();
    this.closeModal();
  }

  updateDynamicsFlag = async (
    dynamics365: FinishedIntegration,
    isUpdateMeetingDescription: boolean,
  ) => {
    const { onUpdateIntegration } = this.props;

    try {
      await updateDynamicsTokenFlag(dynamics365.id, isUpdateMeetingDescription);
    } catch (e: any) {
      console.error(`Dynamics 365 Flag | Failed updating flag for integration: Error: `, e);
    }

    onUpdateIntegration(
      dynamics365,
      isUpdateMeetingDescription,
    )
  }

  updateSalesforceFlag = async (
    salesforce: FinishedIntegration,
    isUpdateMeetingDescription: boolean,
  ) => {
    const { onUpdateIntegration } = this.props;

    try {
      await updateSalesforceTokenFlag(salesforce.id, isUpdateMeetingDescription);
    } catch (e: any) {
      console.error(`Salesforce Flag | Failed updating flag for integration: Error: `, e);
    }

    onUpdateIntegration(
      salesforce,
      isUpdateMeetingDescription,
    )
  }

  render() {
    const {
      finishedIntegrations,
      isUserRightLevel,
      currentaccountselected,
      isLynesEnabled,
      isTelenorEnabled,
      isTelavoxEnabled,
      isDestinyEnabled,
      isHitrateEnabled,
      isAircallEnabled,
      infracomToken,
      handleOpenIntegrationModal,
      onRemoveIntegration,
      onUpdateIntegration,
    } = this.props;
    const { isModalOpen, title } = this.state;

    // If allowed but not set, it will be null instead of undefined
    const infracomDisabled = infracomToken === undefined;

    const googleMeetIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.GOOGLE_MEET
    );

    const msTeamsIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.MICROSOFT_TEAMS
    );

    const hubspotIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.HUBSPOT
    );

    const pipedriveIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.PIPEDRIVE
    );

    const googleChromeExtensionIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.GOOGLE_CHROME_EXTENSION
    );

    const salesforceIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.SALESFORCE
    );

    const adversusIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.ADVERSUS
    );

    const s2Integration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.S2
    );

    const zohoIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.ZOHO
    );

    const dynamicsIntegration = this.findIntegrationByType(
      finishedIntegrations,
      INTEGRATION.DYNAMICS_365
    );

    const importCallsLabel = "Import calls from integration";

    const importCallsPlaceholderComponent = (
      <div
        style={{
          paddingTop: "35px",
          width: "100%",
        }}
      />
    );

    const isMeetricEngageEnabled = currentaccountselected.is_meetric_engage_enabled === true;

    const visibleStyle = {};
    const hiddenStyle = { display: 'none' };

    return (
      <>
        <Row className="table-userlist light-fontweight-table">
          <Col lg="12">
            {!areAllCalendarAndCRMBlacklisted() && (
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Calendar and CRM</CardTitle>
                  <Row className="integrations-profile">
                    <Col>
                      <CardGroup className="row integration-cards">
                        <div
                          id={getBlacklistId('google_calendar')}
                          style={isIntegrationBlacklisted('google_calendar') ? hiddenStyle : visibleStyle}
                        >
                          <Card className="card_integration">
                            <CardHeader>
                              <img src={googleCalendarImg} alt="loader" height="20" />
                              <span>Google Calendar Notetaker</span>
                              <hr />
                              <CardSubtitle>
                                Allow our Meeting Notetaker AI bot to join meetings created in
                                Google Calendar
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={this.handleGoogleMeetClick}
                              style={{ cursor: "pointer" }}
                              className='d-flex justify-content-center flex-column items-aling-center'
                            >
                              <div>
                                <img
                                  src={googleCalendar}
                                  alt="loader"
                                  width="180"
                                  height="130"
                                />
                              </div>
                              {googleMeetIntegration && (
                                <div className="finished-integration">
                                  <div className="finished-integration-icon">
                                    <i className="fa fa-check-circle" />
                                  </div>
                                  <div
                                    className="finished-integration-disconnect"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onRemoveIntegration("googleMeet");
                                    }}
                                  >
                                    <span>Disconnect</span>
                                  </div>
                                </div>
                              )}
                            </CardBody>
                            <CardFooter>
                              <span className="integration-type-badge">User</span>
                              {importCallsPlaceholderComponent}
                            </CardFooter>
                          </Card>
                        </div>
                        <div
                          id={getBlacklistId('outlook')}
                          style={isIntegrationBlacklisted('outlook') ? hiddenStyle : visibleStyle}
                        >
                          <Card className="card_integration">
                            <CardHeader>
                              <img src={outlookImg} alt="loader" height="20"/>
                              <span>Outlook Notetaker</span>
                              <hr/>
                              <CardSubtitle>
                                Allow our Meeting Notetaker AI bot to join meetings created in
                                Outlook Calendar
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={this.handleMicrosoftTeamsClick}
                              style={{ cursor: "pointer" }}
                              className='d-flex justify-content-center flex-column items-aling-center'
                            >
                              <div>
                                <img src={outlook} alt="loader" width="180"/>
                              </div>
                              {msTeamsIntegration && (
                                <div className="finished-integration">
                                  <div className="finished-integration-icon">
                                    <i className="fa fa-check-circle"/>
                                  </div>
                                  <div
                                    className="finished-integration-disconnect"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onRemoveIntegration("msTeams");
                                    }}
                                  >
                                    <span>Disconnect</span>
                                  </div>
                                </div>
                              )}
                            </CardBody>
                            <CardFooter>
                              <span className="integration-type-badge">User</span>
                              {importCallsPlaceholderComponent}
                            </CardFooter>
                          </Card>
                        </div>
                        <div
                          id={getBlacklistId('hubspot')}
                          style={isIntegrationBlacklisted('hubspot') ? hiddenStyle : visibleStyle}
                        >
                          <Card className="card_integration">
                            <CardHeader>
                              <img src={hubspotImg} alt="loader" height="20"/>
                              <span>Native Hubspot Integration</span>
                              <hr/>
                              <CardSubtitle>
                                When meeting is done, push meeting data to Hubspot
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={this.handleHubspotClick}
                              style={{ cursor: "pointer" }}
                              className='d-flex justify-content-center flex-column items-aling-center'
                            >
                              <div>
                                <img
                                  src={hubspotImg}
                                  alt="loader"
                                  height="40"
                                />
                              </div>
                              {hubspotIntegration && (
                                <div className="finished-integration">
                                  <div className="finished-integration-icon">
                                    <i className="fa fa-check-circle"/>
                                  </div>
                                  <div
                                    className="finished-integration-disconnect"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onRemoveIntegration("hubspot");
                                    }}
                                  >
                                    <span>Disconnect</span>
                                  </div>
                                </div>
                              )}
                            </CardBody>
                            <CardFooter>
                              <span className="integration-type-badge">User</span>
                              {hubspotIntegration &&
                              this.canImportCalls(INTEGRATION.HUBSPOT) ? (
                                <div style={{ margin: '6px 0' }}>
                                  <div className="card_integration-switch">
                                    <SwitchComponent
                                      label={importCallsLabel}
                                      checked={hubspotIntegration.importCalls}
                                      onChange={() =>
                                        onUpdateIntegration(
                                          hubspotIntegration,
                                          !hubspotIntegration?.importCalls
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                importCallsPlaceholderComponent
                              )}
                            </CardFooter>
                          </Card>
                        </div>
                        <div
                          id={getBlacklistId('pipedrive')}
                          style={isIntegrationBlacklisted('pipedrive') ? hiddenStyle : visibleStyle}
                        >
                          <Card className="card_integration">
                            <CardHeader>
                              <img src={p} alt="loader" height="20"/>
                              <span>Native Pipedrive Integration</span>
                              <hr/>
                              <CardSubtitle>
                                When meeting is done, push meeting data to Pipedrive
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={this.handlePipedriveClick}
                              style={{ cursor: "pointer" }}
                              className='d-flex justify-content-center flex-column items-aling-center'
                            >
                              <div>
                                <div>
                                  <img
                                    src={pipedriveImg}
                                    alt="loader"
                                    height="40"
                                  />
                                </div>
                                {pipedriveIntegration && (
                                  <div className="finished-integration">
                                    <div className="finished-integration-icon">
                                      <i className="fa fa-check-circle"/>
                                    </div>
                                    <div
                                      className="finished-integration-disconnect"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onRemoveIntegration("pipedrive");
                                      }}
                                    >
                                      <span>Disconnect</span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </CardBody>
                            <CardFooter>
                              <span className="integration-type-badge">User</span>
                              {pipedriveIntegration &&
                              this.canImportCalls(INTEGRATION.PIPEDRIVE) ? (
                                <div style={{ margin: '6px 0' }}>
                                  <div className="card_integration-switch">
                                    <SwitchComponent
                                      label={importCallsLabel}
                                      checked={pipedriveIntegration.importCalls}
                                      onChange={() =>
                                        onUpdateIntegration(
                                          pipedriveIntegration,
                                          !pipedriveIntegration?.importCalls
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                importCallsPlaceholderComponent
                              )}
                            </CardFooter>
                          </Card>
                        </div>
                        <div
                          id={getBlacklistId('salesforce')}
                          style={isIntegrationBlacklisted('salesforce') ? hiddenStyle : visibleStyle}
                        >
                          <Card className="card_integration">
                            <CardHeader>
                              <span>Native Salesforce Integration</span>
                              <hr/>
                              <CardSubtitle>
                                When meeting is done, push meeting data to Salesforce. This integration is done by our
                                support team.
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              className="d-flex justify-content-center flex-column items-aling-center"
                              style={{ cursor: "pointer" }}
                              onClick={this.handleSalesforceClick}
                            >
                              <div>
                                <img
                                  src={salesforce}
                                  alt="loader"
                                  height="80"
                                />
                              </div>
                              {salesforceIntegration && (
                                <div className="finished-integration">
                                  <div className="finished-integration-icon">
                                    <i className="fa fa-check-circle"/>
                                  </div>
                                  <div
                                    className="finished-integration-disconnect"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onRemoveIntegration("salesforce");
                                    }}
                                  >
                                    <span>Disconnect</span>
                                  </div>
                                </div>
                              )}
                            </CardBody>
                            <CardFooter>
                              <span className="integration-type-badge">User</span>
                              {salesforceIntegration ? (
                                <div style={{ margin: '6px 0' }}>
                                  <div className="card_integration-switch">
                                    <SwitchComponent
                                      label="Match AI summary to the meeting if possible"
                                      checked={salesforceIntegration.isUpdateMeetingDescription ?? false}
                                      extraLabelClass="dynamics365-switch-label"
                                      onChange={
                                        () => this.updateSalesforceFlag(
                                          salesforceIntegration,
                                          !salesforceIntegration?.isUpdateMeetingDescription,
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                importCallsPlaceholderComponent
                              )}
                            </CardFooter>
                          </Card>
                        </div>
                        <div
                          id={getBlacklistId('zoho')}
                          style={isIntegrationBlacklisted('zoho') ? hiddenStyle : visibleStyle}
                        >
                          <Card className="card_integration">
                            <CardHeader>
                              <img src={zohoImg} alt="loader" height="20"/>
                              <span>Zoho Integration</span>
                              <hr/>
                              <CardSubtitle>
                                Enable/Disable Zoho CRM Integration.
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={zohoIntegration ? () => {
                              } : this.handleZohoClick}
                              style={{ cursor: "pointer" }}
                              className='d-flex justify-content-center flex-column items-aling-center'
                            >
                              <div>
                                <img
                                  src={zohoImg}
                                  alt="loader"
                                  height={100}
                                  width={200}
                                />
                              </div>
                              {zohoIntegration && (
                                <div className="finished-integration">
                                  <div className="finished-integration-icon">
                                    <i className="fa fa-check-circle"/>
                                  </div>
                                  <div
                                    className="finished-integration-disconnect"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onRemoveIntegration("zoho");
                                    }}
                                  >
                                    <span>Disconnect</span>
                                  </div>
                                </div>
                              )}
                            </CardBody>
                            <CardFooter>
                              <span className="integration-type-badge">User</span>
                              {importCallsPlaceholderComponent}
                            </CardFooter>
                          </Card>
                        </div>
                        <div
                          id={getBlacklistId('dynamics365')}
                          style={isIntegrationBlacklisted('dynamics365') ? hiddenStyle : visibleStyle}
                        >
                          <Card className="card_integration">
                            <CardHeader>
                              <img src={dynamics365Logo} alt="loader" height="20"/>
                              <span>Microsoft Dynamics CRM Integration</span>
                              <hr/>
                              <CardSubtitle>
                                Enable/Disable Dynamics CRM Integration.
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={dynamicsIntegration ? () => {
                              } : this.handleDynamicsClick}
                              style={{ cursor: "pointer" }}
                              className='d-flex justify-content-center flex-column items-aling-center'
                            >
                              <div>
                                <img
                                  src={dynamics365}
                                  alt="loader"
                                  height={150}
                                  width={250}
                                />
                              </div>
                              {dynamicsIntegration && (
                                <div className="finished-integration">
                                  <div className="finished-integration-icon">
                                    <i className="fa fa-check-circle"/>
                                  </div>
                                  <div
                                    className="finished-integration-disconnect"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onRemoveIntegration("dynamics365");
                                    }}
                                  >
                                    <span>Disconnect</span>
                                  </div>
                                </div>
                              )}
                            </CardBody>
                            <CardFooter>
                              <div style={{ margin: '0' }}>
                                <span className="integration-type-badge">User</span>
                                {dynamicsIntegration ? (
                                  <div style={{ margin: '6px 0' }}>
                                    <div className="card_integration-switch">
                                      <SwitchComponent
                                        label="Match AI summary to the meeting if possible"
                                        checked={dynamicsIntegration.isUpdateMeetingDescription ?? false}
                                        extraLabelClass="dynamics365-switch-label"
                                        onChange={
                                          () => this.updateDynamicsFlag(
                                            dynamicsIntegration,
                                            !dynamicsIntegration?.isUpdateMeetingDescription,
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  importCallsPlaceholderComponent
                                )}
                              </div>
                            </CardFooter>
                          </Card>
                        </div>
                        {isMeetricEngageEnabled && (
                          <>
                            <div
                              style={isIntegrationBlacklisted('google_calendar') ? hiddenStyle : visibleStyle}
                            >
                              <Card className="card_integration">
                                <CardHeader>
                                  <img src={googleCalendarImg} alt="loader" height="20"/>
                                  <span>Google Calendar Conference Integration</span>
                                  <hr/>
                                  <CardSubtitle>
                                    Add professional Meetric links to Google Calendar
                                  </CardSubtitle>
                                </CardHeader>
                                <CardBody
                                  onClick={() => {
                                    handleOpenIntegrationModal("google_calendar");
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className='d-flex justify-content-center flex-column items-aling-center'
                                >
                                  <div>
                                    <img
                                      src={googleCalendar}
                                      alt="loader"
                                      width="180"
                                      height="130"
                                    />
                                  </div>
                                  {googleChromeExtensionIntegration && (
                                    <div className="finished-integration">
                                      <div className="finished-integration-icon">
                                        <i className="fa fa-check-circle"/>
                                      </div>
                                    </div>
                                  )}
                                </CardBody>
                                <CardFooter>
                                  <span className="integration-type-badge">User</span>
                                  {importCallsPlaceholderComponent}
                                </CardFooter>
                              </Card>
                            </div>
                            <div style={isIntegrationBlacklisted('outlook') ? hiddenStyle : visibleStyle}>
                              <Card className="card_integration">
                                <CardHeader>
                                  <img src={outlookImg} alt="loader" height="20"/>
                                  <span>Outlook Conference Integration</span>
                                  <hr/>
                                  <CardSubtitle>
                                    Add professional Meetric links to Outlook
                                  </CardSubtitle>
                                </CardHeader>
                                <CardBody
                                  onClick={() => {
                                    handleOpenIntegrationModal('outlook');
                                  }}
                                  style={{ cursor: 'pointer' }}
                                  className="d-flex justify-content-center flex-column items-aling-center"
                                >
                                  <div>
                                    <img src={outlook} alt="loader" height="120"/>
                                  </div>
                                </CardBody>
                                <CardFooter>
                                  <span className="integration-type-badge">User</span>
                                  {importCallsPlaceholderComponent}
                                </CardFooter>
                              </Card>
                            </div>
                            <div
                              id={getBlacklistId('chrome_extension')}
                              style={isIntegrationBlacklisted('chrome_extension') ? hiddenStyle : visibleStyle}
                            >
                              <Card className="card_integration">
                                <CardHeader>
                                  <img src={chrome} alt="loader" height="20"/>
                                  <span>Chrome Integration</span>
                                  <hr/>
                                  <CardSubtitle>
                                    Easily add Meetric meetings to your CRM or calendar
                                  </CardSubtitle>
                                </CardHeader>
                                <CardBody
                                  onClick={() => {
                                    handleOpenIntegrationModal('chrome');
                                  }}
                                  style={{ cursor: 'pointer' }}
                                  className="d-flex justify-content-center flex-column items-aling-center"
                                >
                                  <div className="images chrome-extension">
                                    <img src={googleCalendar} alt="chrome" height="75"/>
                                    <img
                                      src={upSalesImg}
                                      alt="chrome"
                                      height="24"
                                      className="upsales-img"
                                    />
                                    <img src={pipedriveImg} alt="chrome" height="32"/>
                                    <img src={hubspotImg} alt="chrome" height="32"/>
                                  </div>
                                </CardBody>
                                <CardFooter>
                                  <span className="integration-type-badge">User</span>
                                  {importCallsPlaceholderComponent}
                                </CardFooter>
                              </Card>
                            </div>
                          </>
                        )}
                      </CardGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
            {!areAllDialersBlacklisted() && (
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Calling</CardTitle>
                  <Row className="integrations-profile">
                    <Col>
                      <CardGroup className="row integration-cards">
                        <div
                          id={getBlacklistId('adversus')}
                          style={isIntegrationBlacklisted('adversus') ? hiddenStyle : visibleStyle}
                        >
                          <Card className={`card_integration ${isUserRightLevel ? '' : 'grayed-out'}`}>
                            <CardHeader>
                              <img src={adversus} alt="loader" height="20"/>
                              <span>Adversus</span>
                              <hr/>
                              <CardSubtitle>
                                Enable/Disable Adversus integration on this account. If
                                enabled you can define settings for importing calls from
                                Adversus integration.
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={this.handleAdversusAndS2Click}
                              style={{ cursor: "pointer" }}
                              className='d-flex justify-content-center flex-column items-aling-center'
                            >
                              <div title="Go to Adversus settings">
                                <img src={adversus} alt="loader" width="100"/>
                              </div>
                              {adversusIntegration && (
                                <div className="finished-integration">
                                  <div className="finished-integration-icon">
                                    <i className="fa fa-check-circle"/>
                                  </div>
                                </div>
                              )}
                              <div className={`additional-notice ${adversusIntegration ? 'with-checkmark' : ''}`}>
                                To ensure proper functionality of this integration, stereo mode must be enabled for call
                                recording.
                                <br/>
                                If it's not enabled or you're unsure, please contact Adversus to request activation.
                              </div>
                            </CardBody>
                            <CardFooter className="integration-buttons">
                              <span className="integration-type-badge">
                                {isUserRightLevel ? 'Account' : 'Account - Admins only'}
                              </span>
                              {isUserRightLevel ? (
                                <>
                                  {currentaccountselected.call_integrations?.includes(
                                    CALL_TOKENS.adverus
                                  ) ? (
                                    <Button onClick={() => this.openModal(CALL_TOKENS.adverus)}>
                                      Disable
                                    </Button>
                                  ) : (
                                    <Button onClick={() => this.enable(CALL_TOKENS.adverus)}>
                                      Enable
                                    </Button>
                                  )}
                                </>
                              ) : importCallsPlaceholderComponent}
                            </CardFooter>
                          </Card>
                        </div>
                        <div
                          id={getBlacklistId('s2')}
                          style={isIntegrationBlacklisted('s2') ? hiddenStyle : visibleStyle}
                        >
                          <Card className={`card_integration ${isUserRightLevel ? '' : 'grayed-out'}`}>
                            <CardHeader>
                              <img src={s2} alt="loader" height="20"/>
                              <span>S2</span>
                              <hr/>
                              <CardSubtitle>
                                Enable/Disable S2 integration on this account. If enabled
                                you can define settings for importing calls from S2
                                integration.
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={this.handleAdversusAndS2Click}
                              style={{ cursor: "pointer" }}
                              className='d-flex justify-content-center flex-column items-aling-center'
                            >
                              <div title="Go to S2 settings">
                                <img src={s2} alt="loader" width="100"/>
                              </div>
                              {s2Integration && (
                                <div className="finished-integration">
                                  <div className="finished-integration-icon">
                                    <i className="fa fa-check-circle"/>
                                  </div>
                                </div>
                              )}
                            </CardBody>
                            <CardFooter className="integration-buttons">
                              <span className="integration-type-badge">
                                {isUserRightLevel ? 'Account' : 'Account - Admins only'}
                              </span>
                              {isUserRightLevel ? (
                                <>
                                  {currentaccountselected.call_integrations?.includes(
                                    CALL_TOKENS.s2
                                  ) ? (
                                    <Button onClick={() => this.openModal(CALL_TOKENS.s2)}>
                                      Disable
                                    </Button>
                                  ) : (
                                    <Button onClick={() => this.enable(CALL_TOKENS.s2)}>
                                      Enable
                                    </Button>
                                  )}
                                </>
                              ) : importCallsPlaceholderComponent}
                            </CardFooter>
                          </Card>
                        </div>
                        {LYNES_INTEGRATIONS.map((integration) => {
                          if (isIntegrationBlacklisted(integration.type)) {
                            return null; // Skip rendering if blacklisted
                          }

                          return (
                            <div
                              id={getBlacklistId(integration.type)}
                              key={integration.name}
                            >
                              <Card className={`card_integration ${isUserRightLevel ? '' : 'grayed-out'}`}>
                                <CardHeader>
                                  <img
                                    src={integration.smallLogo}
                                    alt={`${integration.name.toLowerCase()}-logo`}
                                    height="20"
                                    style={{ marginBottom: '7px' }}
                                  />
                                  <span>{integration.name}</span>
                                  <hr/>
                                  <CardSubtitle>
                                    Enable/Disable {integration.name} Integration on this account.
                                  </CardSubtitle>
                                </CardHeader>
                                <CardBody
                                  onClick={() => this.enableLynes(integration.type)}
                                  style={{ cursor: 'pointer' }}
                                  className="d-flex justify-content-center flex-column items-align-center"
                                >
                                  <div>
                                    <img src={integration.mainLogo} alt={integration.imageAlt}
                                         height={integration.imageHeight} width={integration.imageWidth}/>
                                  </div>
                                </CardBody>
                                <CardFooter className="integration-buttons">
                                  <span className="integration-type-badge">
                                    {isUserRightLevel ? 'Account' : 'Account - Admins only'}
                                  </span>
                                  {isUserRightLevel ? (
                                    <>
                                      {!isLynesEnabled ? (
                                        <Button onClick={() => this.enableLynes(integration.type)}>
                                          Enable
                                        </Button>
                                      ) : (
                                        <>
                                          <Button
                                            onClick={() => this.enableLynes(integration.type)}
                                            style={{ marginRight: '5px' }}
                                          >
                                            Info
                                          </Button>
                                          <Button onClick={() => this.openModal(integration.name)}>
                                            Disable
                                          </Button>
                                        </>
                                      )}
                                    </>
                                  ) : importCallsPlaceholderComponent}
                                </CardFooter>
                              </Card>
                            </div>
                          );
                        })}
                        <div
                          id={getBlacklistId('telenor')}
                          style={isIntegrationBlacklisted('telenor') ? hiddenStyle : visibleStyle}
                        >
                          <Card className={`card_integration ${isUserRightLevel ? '' : 'grayed-out'}`}>
                            <CardHeader>
                              <img
                                src={telenorLogo}
                                alt='telenor'
                                height='20'
                                style={{ marginBottom: '7px' }}
                              />
                              <span>Telenor</span>
                              <hr/>
                              <CardSubtitle>
                                Enable/Disable Telenor Integration on this account.
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={() => {
                                this.enableTelenor();
                              }}
                              style={{ cursor: 'pointer' }}
                              className='d-flex justify-content-center flex-column items-aling-center'
                            >
                              <div>
                                <img src={telenor} alt='telenor' height="100%" width="100%"/>
                              </div>
                            </CardBody>
                            <CardFooter className='integration-buttons'>
                              <span className="integration-type-badge">
                                {isUserRightLevel ? 'Account' : 'Account - Admins only'}
                              </span>
                              {isUserRightLevel ? (
                                <>
                                  {isTelenorEnabled ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          this.enableTelenor();
                                        }}
                                        style={{ marginRight: '5px' }}
                                      >
                                        Info
                                      </Button>
                                      <Button onClick={() => this.openModal('Telenor')}>
                                        Disable
                                      </Button>
                                    </>
                                  ) : (
                                    <Button onClick={() => this.enableTelenor()}>Enable</Button>
                                  )}
                                </>
                              ) : importCallsPlaceholderComponent}
                            </CardFooter>
                          </Card>
                        </div>
                        <div
                          id={getBlacklistId('hitrate')}
                          style={isIntegrationBlacklisted('hitrate') ? hiddenStyle : visibleStyle}
                        >
                          <Card className={`card_integration ${isUserRightLevel ? '' : 'grayed-out'}`}>
                            <CardHeader>
                              <img
                                src={hitrateLogo}
                                alt='hitrate'
                                height='20'
                                style={{ marginBottom: '7px' }}
                              />
                              <span>Hitrate</span>
                              <hr/>
                              <CardSubtitle>
                                Enable/Disable Hitrate Integration on this account.
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={() => {
                                this.enableHitrate();
                              }}
                              style={{ cursor: 'pointer' }}
                              className='d-flex justify-content-center flex-column items-aling-center'
                            >
                              <div>
                                <img src={hitrateLogo} alt='hitrate' width={150}/>
                              </div>
                            </CardBody>
                            <CardFooter className='integration-buttons'>
                              <span className="integration-type-badge">
                                {isUserRightLevel ? 'Account' : 'Account - Admins only'}
                              </span>
                              {isUserRightLevel ? (
                                <>
                                  {isHitrateEnabled ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          this.enableHitrate();
                                        }}
                                        style={{ marginRight: '5px' }}
                                      >
                                        Info
                                      </Button>
                                      <Button onClick={() => this.openModal('Hitrate')}>
                                        Disable
                                      </Button>
                                    </>
                                  ) : (
                                    <Button onClick={() => this.enableHitrate()}>Enable</Button>
                                  )}
                                </>
                              ) : importCallsPlaceholderComponent}
                            </CardFooter>
                          </Card>
                        </div>
                        <div
                          id={getBlacklistId('aircall')}
                          style={isIntegrationBlacklisted('aircall') ? hiddenStyle : visibleStyle}
                        >
                          <Card className={`card_integration ${isUserRightLevel ? '' : 'grayed-out'}`}>
                            <CardHeader>
                              <img
                                src={aircallLogo}
                                alt='aircall'
                                height='20'
                                style={{ marginBottom: '7px' }}
                              />
                              <span>Aircall</span>
                              <hr/>
                              <CardSubtitle>
                                Enable/Disable Aircall Integration on this account.
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={() => {
                                this.enableAircall();
                              }}
                              style={{ cursor: 'pointer' }}
                              className='d-flex justify-content-center flex-column items-aling-center'
                            >
                              <div>
                                <img src={aircall} alt='hitrate' width={200}/>
                              </div>
                            </CardBody>
                            <CardFooter className='integration-buttons'>
                              <span className="integration-type-badge">
                                {isUserRightLevel ? 'Account' : 'Account - Admins only'}
                              </span>
                              {isUserRightLevel ? (
                                <>
                                  {isAircallEnabled ? (
                                    <>
                                      <Button
                                        onClick={() => {
                                          this.enableAircall();
                                        }}
                                        style={{ marginRight: '5px' }}
                                      >
                                        Info
                                      </Button>
                                      <Button onClick={() => this.openModal('Aircall')}>
                                        Disable
                                      </Button>
                                    </>
                                  ) : (
                                    <Button onClick={() => this.enableAircall()}>Enable</Button>
                                  )}
                                </>
                              ) : importCallsPlaceholderComponent}
                            </CardFooter>
                          </Card>
                        </div>
                        {TOLLRING_INTEGRATIONS.map((integration) => {
                          if (isIntegrationBlacklisted(integration.type)) {
                            return null; // Skip rendering if blacklisted
                          }

                          return (
                            <div
                              id={getBlacklistId(integration.type)}
                              key={integration.name}
                            >
                              <Card className={`card_integration ${isUserRightLevel ? '' : 'grayed-out'}`}>
                                <CardHeader>
                                  <img
                                    src={integration.smallLogo}
                                    alt={`${integration.name.toLowerCase()}-logo`}
                                    height="20"
                                    style={{ marginBottom: '7px' }}
                                  />
                                  <span>{integration.name}</span>
                                  <hr/>
                                  <CardSubtitle>
                                    Enable/Disable {integration.name} Integration on this account.
                                  </CardSubtitle>
                                </CardHeader>
                                <CardBody
                                  onClick={() => this.enableDestiny(integration.type)}
                                  style={{ cursor: 'pointer' }}
                                  className="d-flex justify-content-center flex-column items-align-center"
                                >
                                  <div>
                                    <img src={integration.mainLogo} alt={integration.imageAlt}
                                         height={integration.imageHeight} width={integration.imageWidth}/>
                                  </div>
                                </CardBody>
                                <CardFooter className="integration-buttons">
                                  <span className="integration-type-badge">
                                    {isUserRightLevel ? 'Account' : 'Account - Admins only'}
                                  </span>
                                  {isUserRightLevel ? (
                                    <>
                                      {!isDestinyEnabled ? (
                                        <Button onClick={() => this.enableDestiny(integration.type)}>
                                          Enable
                                        </Button>
                                      ) : (
                                        <>
                                          <Button
                                            onClick={() => this.enableDestiny(integration.type)}
                                            style={{ marginRight: '5px' }}
                                          >
                                            Info
                                          </Button>
                                          <Button onClick={() => this.openModal(integration.name)}>
                                            Disable
                                          </Button>
                                        </>
                                      )}
                                    </>
                                  ) : importCallsPlaceholderComponent}
                                </CardFooter>
                              </Card>
                            </div>
                          );
                        })}
                        {TELAVOX_INTEGRATIONS.map((integration) => {
                          if (isIntegrationBlacklisted(integration.type)) {
                            return null; // Skip rendering if blacklisted
                          }

                          return (
                            <div
                              id={getBlacklistId(integration.type)}
                              key={integration.name}
                            >
                              <Card className={`card_integration ${isUserRightLevel ? '' : 'grayed-out'}`}>
                                <CardHeader>
                                  <img
                                    src={integration.smallLogo}
                                    alt={`${integration.name.toLowerCase()}-logo`}
                                    height="20"
                                    style={{ marginBottom: '7px' }}
                                  />
                                  <span>{integration.name}</span>
                                  <hr/>
                                  <CardSubtitle>
                                    Enable/Disable {integration.name} Integration on this account.
                                  </CardSubtitle>
                                </CardHeader>
                                <CardBody
                                  onClick={() => this.enableTelavox(integration.type)}
                                  style={{ cursor: 'pointer' }}
                                  className="d-flex justify-content-center flex-column items-align-center"
                                >
                                  <div>
                                    <img
                                      src={integration.mainLogo}
                                      alt={integration.imageAlt}
                                      height={integration.imageHeight}
                                      width={integration.imageWidth}
                                    />
                                  </div>
                                </CardBody>
                                <CardFooter className="integration-buttons">
                                  <span className="integration-type-badge">
                                    {isUserRightLevel ? 'Account' : 'Account - Admins only'}
                                  </span>
                                  {isUserRightLevel ? (
                                    <>
                                      {!isTelavoxEnabled ? (
                                        <Button onClick={() => this.enableTelavox(integration.type)}>
                                          Enable
                                        </Button>
                                      ) : (
                                        <>
                                          <Button
                                            onClick={() => this.enableTelavox(integration.type)}
                                            style={{ marginRight: '5px' }}
                                          >
                                            Info
                                          </Button>
                                          <Button onClick={() => this.openModal(integration.name)}>
                                            Disable
                                          </Button>
                                        </>
                                      )}
                                    </>
                                  ) : importCallsPlaceholderComponent}
                                </CardFooter>
                              </Card>
                            </div>
                          );
                        })}
                        <div
                          id={getBlacklistId('infracom')}
                          style={isIntegrationBlacklisted('infracom') ? hiddenStyle : visibleStyle}
                        >
                          <Card className={`card_integration ${infracomDisabled ? 'grayed-out' : ''}`}>
                            <CardHeader>
                              <img
                                src={infracomLogo}
                                alt='infracom-logo'
                                height='20'
                                style={{ marginBottom: '7px' }}
                              />
                              <span>Infracom</span>
                              <hr/>
                              <CardSubtitle>
                                Enable/Disable Infracom Integration on this account.
                              </CardSubtitle>
                            </CardHeader>
                            <CardBody
                              onClick={() => {
                                this.enableInfracom();
                              }}
                              style={{ cursor: infracomDisabled ? '' : 'pointer' }}
                              className="d-flex justify-content-center flex-column items-aling-center"
                            >
                              <div>
                                <img src={infracom} alt='infracom'/>
                              </div>
                            </CardBody>
                            <CardFooter className='integration-buttons'>
                              <span className="integration-type-badge">User</span>
                              {infracomDisabled ? (
                                importCallsPlaceholderComponent
                              ) : !!infracomToken ? (
                                <>
                                  <Button
                                    onClick={() => {
                                      this.enableInfracom();
                                    }}
                                    style={{ marginRight: '5px' }}
                                  >
                                    Info
                                  </Button>
                                  <Button onClick={() => this.openModal('Infracom')}>
                                    Disable
                                  </Button>
                                </>
                              ) : (
                                <Button onClick={() => this.enableInfracom()}>
                                  Enable
                                </Button>
                              )}
                            </CardFooter>
                          </Card>
                        </div>
                        {/* Hidden temporarily */}
                        {/*<div>*/}
                        {/*  <Card className="card_integration">*/}
                        {/*    <CardHeader>*/}
                        {/*      <img src={crmServiceLogo} alt="loader" height="20"/>*/}
                        {/*      <span>CRM Service Integration</span>*/}
                        {/*      <hr/>*/}
                        {/*      <CardSubtitle>*/}
                        {/*        Enable / Disable CRM Service Integration.*/}
                        {/*      </CardSubtitle>*/}
                        {/*    </CardHeader>*/}
                        {/*    <CardBody*/}
                        {/*      onClick={isCRMServiceEnabled ? () => {} : this.enableCRMService}*/}
                        {/*      className='d-flex justify-content-center flex-column items-aling-center'*/}
                        {/*    >*/}
                        {/*      <div>*/}
                        {/*        <img*/}
                        {/*          src={crmService}*/}
                        {/*          alt="loader"*/}
                        {/*          height={100}*/}
                        {/*          width={250}*/}
                        {/*        />*/}
                        {/*      </div>*/}
                        {/*    </CardBody>*/}
                        {/*    <CardFooter className='integration-buttons'>*/}
                        {/*      {!isCRMServiceEnabled ? (*/}
                        {/*        <Button onClick={() => this.enableCRMService()}>*/}
                        {/*          Enable*/}
                        {/*        </Button>*/}
                        {/*      ) : (*/}
                        {/*        <Button onClick={() => this.openModal('CRM Service')}>*/}
                        {/*          Disable*/}
                        {/*        </Button>*/}
                        {/*      )}*/}
                        {/*    </CardFooter>*/}
                        {/*  </Card>*/}
                        {/*</div>*/}
                      </CardGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>

        <ConfirmationModal
          isOpen={isModalOpen}
          title={title}
          handleClose={this.closeModal}
          handleDisable={this.disableAdversusOrS2}
          handleLynesDisable={this.disableLynes}
          handleTelenorDisable={this.disableTelenor}
          handleTelavoxDisable={this.disableTelavox}
          handleDestinyDisable={this.disableDestiny}
          handleHitrateDisable={this.disableHitrate}
          handleAircallDisable={this.disableAircall}
          handleInfracomDisable={this.disableInfracom}
          handleCRMServiceDisable={this.disableCRMService}
        />
      </>
    );
  }
}

export default withRouter(IntegrationsProfile);
