import React, { Component, createRef } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  List,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import classnames from "classnames";
import { VariableSizeList as VirtualizedList } from 'react-window';
import { Rnd } from 'react-rnd';
import {
  isEmpty,
  pickBy,
  pick,
  map,
  debounce,
  keys,
  findIndex,
  split,
  filter,
  some,
  difference,
} from "lodash";
import { AnyIfEmpty, connect } from "react-redux";

import Comments from '../Comments';
import CommentList from '../CommentList';
import { Snippets } from '../Snippets';
import Tags from '../Tags';
import loaderImg from '../../assets/images/loader.gif';
import questionImg from '../../assets/images/question.svg';
import objectionImg from '../../assets/images/exclamation-mark.svg';
import {
  IAnalyticsMeetingAIAnalytics,
  ITranscript,
  ITranscriptItem,
} from '../../store/interface';
import {
  formatDecimal,
  formatPercentage,
  formatTime,
  resizeImage
} from '../../services/utilities/utilservice';
import {
  requestRecordingTranscription,
  requestRecordingTranscriptParseNaming,
  updateSessionLanguage,
  downloadTranscriptCsv,
  saveMeetingSpeakerRemapping,
  resetMeetingSpeakerMapping,
  updateMeetingParticipants,
} from '../../services/userservice';
import './../../../node_modules/video-react/dist/video-react.css';
import { OptionTypeBase } from 'react-select';
import { SaveAndShareModal } from './SaveAndShareModal';
import ThumbnailPhases from './ThumbnailPhases';
import SwitchComponent from "../SwitchButton/SwitchComponent";
import { adaptDateToTimezone, formatSummary } from "../../services/utilities/newUtilservice";
import { getAllTagsByAccount } from "../../store/Analytics/actions";
import { VideoPlayerProps, VideoPlayerState, meetingNavArray, navArray } from './interface';
import AIAgentResponsePreview from '../../pages/AIAgent/AIAgentLayout/AIAgentResponsePreview';
import { findStartTime } from '../../pages/AIAgent/utils/constants';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import { sleep } from '../../utilities/helperFunctions';
import GptPrompt from '../../pages/GptPrompt';
import { DropdownOption } from '../../pages/Meetings/interface';
import { EditParticipantsList } from '../../pages/RecordLiveMeeting/EditParticipantsList';
import { mapReceiversToParticipants } from '../../pages/RecordLiveMeeting/helperFunctions';
import CloseButton from '../CloseButton';

const Player = require('video-react');
const THREE_HOURS_IN_MS = 3 * 60 * 60 * 1000;

const ignoredProps = [
  "sessionId", "recordingSessionId", "currentuser", "recordingLoader", "showComments", "showSnippets",
  "showAddSnippet", "getTimeLine", "getMeetingAIAnalytics", "postRecordingSnippet", "handleRedirect",
  "handleSnippetShare", "showTranscription", "getRecordingTranscript", "getPlaybookForSession",
  "isSuperAdmin", "start", "users", "teams", "createShareLink", "updateShareLink", "history",
  "canComment", "updateSnippetAction", "canExportCSV", "onAddTag", "deleteTag",
  "currentaccountselected", "getAllTagsByAccount",
];
const ignoredState = [
  "progress", "duration", "durationSeconds", "axisX", "axisY", "snippetStartSeconds",
  "snippetEndSeconds", "snippetStop", "snippetUuid", "showShareLinkUrl", "selectedQuestionObjection",
  "lastSpeakerIndexInTranscript", "speakerMapBeforeEdit", "showSpeakerId",
];

class VideoPlayer extends Component<
  VideoPlayerProps,
  VideoPlayerState
> {
  player: any;
  playerLoaded: Boolean = false;
  timelineDataLoaded: Boolean = false;
  aiAnalyticsLoaded: Boolean = false;
  snippetsLoaded: Boolean = false;
  transcriptLoaded: Boolean = false;
  virtualizedList: React.RefObject<VirtualizedList>;
  showPlayButton: Boolean = true;
  allPlaybooksForSessionLoaded: Boolean = false;
  allMagicVariableForSessionLoaded: Boolean = false;
  playbookForSessionLoaded: Boolean = false;
  matchSpeakersEl: any = createRef();

  constructor(props: VideoPlayerProps) {
    super(props);

    const {
      speakerInfoByParticipantId,
      speakerMap,
      speakerDisplayNames,
      lastSpeakerIndexInTranscript,
    } = this.calculateSpeakerMapData(props.recordingTranscript);

    this.state = {
      activeTab: "1",
      activeMeetingTab: "tab-transcript",
      progress: 0,
      playing: false,
      isVideoReady: false,
      isVideoAvailable: true,
      currentTimeSeconds: 0,
      currentTime: "00:00",
      duration: "00:00",
      durationSeconds: 0,
      axisX: 0,
      axisY: 14,
      snippetDivWidth: 250,
      snippetStartSeconds: 0,
      snippetEndSeconds: null,
      snippetStop: true,
      showSnippetDiv: false,
      snippetUuid: null,
      snippetName: '',
      snippetDescription: '',
      showSnippetForm: false,
      activeTranscriptItem: null,
      searchQuery: '',
      searchItemIds: [],
      searchItem: -1,
      transcriptAutoScroll: true,
      language: props.recording?.language || '',
      minimized: false,
      showShareModal: false,
      shareRecording: false,
      shareToUser: [],
      generalAccess: 'disabled',
      showShareLinkUrl: false,
      showClipoardMessage: false,
      showAddCommentModal: false,
      useTranslation: false,
      selectedQuestionObjection: '',
      showFixSpeakers: false,
      speakerInfoByParticipantId,
      lastSpeakerIndexInTranscript,
      speakerMap,
      speakerMapBeforeEdit: speakerMap,
      speakerDisplayNames: speakerDisplayNames,
      showSpeakerId: !!localStorage.getItem('show_speaker_id'),
      showResetSpeakersModal: false,
      swapDropdownParticipantId: null,
      showNoSentencesModal: false,
      timelineDeleted: false,
      initialSpeakerMapLoaded: false,
      selectedPlaybook: null,
      selectedPlaybookDropdown: null,
      selectedMagicVariables: null,
      selectedMagicVariablesDropdown: null,
      isEditingParticipants: false,
      participantsDraft: this.setParticipantsDraftFromMeeting(true),
    };

    this.virtualizedList = React.createRef();
    this.handleSnippetClick = this.handleSnippetClick.bind(this);
  }

  componentDidMount() {
    this.props.getRecordingTranscript?.(this.props.sessionId);

    if (this.props.currentaccountselected?.id) {
      this.props.getAllPlaybooksForSession?.(this.props.currentaccountselected?.id, this.props.sessionId)
      this.props.getAllMagicVariableForSession?.(this.props.currentaccountselected?.id, this.props.sessionId)
    }
  }

  shouldComponentUpdate(nextProps: VideoPlayerProps, nextState: VideoPlayerState) {
    return difference(Object.keys(nextProps), ignoredProps).some(
        (key) => this.props[key as keyof VideoPlayerProps] !== nextProps[key as keyof VideoPlayerProps]
      ) || difference(Object.keys(nextState), ignoredState).some(
        (key) => this.state[key as keyof VideoPlayerState] !== nextState[key as keyof VideoPlayerState]
      );
  }

  componentDidUpdate(prevProps: VideoPlayerProps, prevState: VideoPlayerState) {
    const {
      accountId,
      allPlaybooksForSession,
      allMagicVariableForSession,
      sessionId,
      recordingSessionId,
      recordingLoader,
      recording,
      start,
      permissions,
      forceShowSnippetDiv,
      recordingTranscript,
      meeting,
      getTimeLine: getTimeLineAction,
      getMeetingAIAnalytics: getMeetingAIAnalyticsAction,
      getRecordingSnippets: getRecordingSnippetsAction,
      getRecordingTranscript: getRecordingTranscriptAction,
      getAllPlaybooksForSession: getAllPlaybooksForSessionAction,
      getAllMagicVariableForSession: getAllMagicVariableForSessionAction,
    } = this.props;

    const {
      currentTimeSeconds,
      activeTranscriptItem,
      transcriptAutoScroll,
      showSnippetDiv,
      durationSeconds,
    } = this.state;

    if (!isEmpty(recording) && recording !== prevProps.recording) {
      this.setState({ language: recording?.language || 'en' });
    }

    if (!isEmpty(accountId) && !this.timelineDataLoaded && getTimeLineAction) {
      this.timelineDataLoaded = true;
      getTimeLineAction(accountId, sessionId);
    }

    if (!isEmpty(accountId) && !this.aiAnalyticsLoaded && getMeetingAIAnalyticsAction) {
      this.aiAnalyticsLoaded = true;
      getMeetingAIAnalyticsAction(accountId, recordingSessionId);
    }

    if (!isEmpty(recordingSessionId) && !this.snippetsLoaded && getRecordingSnippetsAction) {
      this.snippetsLoaded = true;
      getRecordingSnippetsAction(recordingSessionId);
    }

    if (!isEmpty(sessionId) && !this.transcriptLoaded && getRecordingTranscriptAction) {
      this.transcriptLoaded = true;
      getRecordingTranscriptAction(sessionId);
    }

    if (!isEmpty(accountId) && !isEmpty(sessionId) && !this.allPlaybooksForSessionLoaded && getAllPlaybooksForSessionAction) {
      this.allPlaybooksForSessionLoaded = true;
      getAllPlaybooksForSessionAction(accountId, sessionId);
    }

    if (!isEmpty(accountId) && !isEmpty(sessionId) && !this.allMagicVariableForSessionLoaded && getAllMagicVariableForSessionAction) {
      this.allMagicVariableForSessionLoaded = true;
      getAllMagicVariableForSessionAction(accountId, sessionId);
    }

    if (recordingLoader !== prevProps.recordingLoader && !recordingLoader) {
      this.setState({
        isVideoAvailable: this.props.recording?.url ? true : false,
      })
    }

    if (
      null !== allPlaybooksForSession
      && undefined !== allPlaybooksForSession
      && prevProps.allPlaybooksForSession !== allPlaybooksForSession
    ) {
      this.setState({
        selectedPlaybook: allPlaybooksForSession[0],
        selectedPlaybookDropdown: this.getUniquePlaybookAgentsDropdown()[0] ?? null,
      })
    }

    if (
      null !== allMagicVariableForSession
      && undefined !== allMagicVariableForSession
      && prevProps.allMagicVariableForSession !== allMagicVariableForSession
    ) {
      this.setState({
        selectedMagicVariables: allMagicVariableForSession[0],
        selectedMagicVariablesDropdown: this.getUniqueMagicVariableAgentsDropdown()[0] ?? null,
      })
    }

    if (this.player != null && this.playerLoaded === false) {
      this.playerLoaded = true;
      this.player.subscribeToStateChange(this.handlePlayerStateChange.bind(this));

      if (start) {
        this.player.seek(start);
        this.player.play();
        this.showPlayButton = false;
      }
    }

    if (this.state.snippetStop && Math.floor(currentTimeSeconds) === this.state.snippetEndSeconds) {
      this.player.pause();
      this.setState({ snippetStop: false })
    }

    if (currentTimeSeconds !== prevState.currentTimeSeconds) {
      this.handleActiveTranscriptItemChange();
    }

    if (
      transcriptAutoScroll
      && activeTranscriptItem
      && activeTranscriptItem !== prevState.activeTranscriptItem
    ) {
      this.scrollToVirtualizedListItem(activeTranscriptItem);
    }

    if (permissions && !isEmpty(permissions) && permissions != prevProps.permissions) {
      let snippetSharePermissions: any[] = []
      permissions.forEach((permission: any) => {
        let type = 0;
        if (permission.type === 'team') {
          type = 1;
        } else if (permission.type === 'user') {
          type = 2;
        }

        if (type !== 0) {
          snippetSharePermissions.push(
            {
              can_comment: permission.can_comment,
              can_view: permission.can_view,
              label: permission.name,
              email: permission.email,
              type,
              value: permission.user_id ? permission.user_id : (permission.team_id ? permission.team_id : null),
              team_id: permission.team_id,
              user_id: permission.user_id,
            }
          )
        } else {
          this.setState({ generalAccess: 'anyone' });
        }
      });

      this.setState({
        shareToUser: snippetSharePermissions
      });
    }

    if (showSnippetDiv && !forceShowSnippetDiv && prevProps.forceShowSnippetDiv !== forceShowSnippetDiv) {
      this.setState({
        showSnippetDiv: false
      });
    }

    if (recordingTranscript !== prevProps.recordingTranscript || meeting !== prevProps.meeting) {
      const {
        speakerInfoByParticipantId,
        speakerMap,
        speakerDisplayNames,
        lastSpeakerIndexInTranscript,
      } = this.calculateSpeakerMapData(recordingTranscript);

      this.setState({
        speakerInfoByParticipantId,
        speakerMap,
        speakerMapBeforeEdit: speakerMap,
        speakerDisplayNames,
        lastSpeakerIndexInTranscript,
      });
    }

    if (meeting && recordingTranscript && !this.state.initialSpeakerMapLoaded) {
      this.setState({ initialSpeakerMapLoaded: true });
      if (meeting?.live && !recordingTranscript?.areSpeakersRemapped) {
        this.setState({ showFixSpeakers: true });
      }
    }

    if (meeting?.participants && meeting?.participants !== prevProps.meeting?.participants) {
      this.setParticipantsDraftFromMeeting();
    }
  }

  setParticipantsDraftFromMeeting = (justReturn = false) => {
    const participantsDraft = mapReceiversToParticipants(this.props.meeting?.participants || []);
    if (!justReturn) {
      this.setState({ participantsDraft });
    }
    return participantsDraft;
  };

  calculateSpeakerMapData = (transcript: ITranscript | null | undefined) => {
    let lastSpeakerNum = 0, lastSpeakerIndex = 0;
    const speakerInfoByParticipantId: any = {};
    const speakerMap: any = {};

    transcript?.transcript?.forEach((transcriptItem) => {
      if (speakerInfoByParticipantId[transcriptItem.speakerId] && speakerMap[transcriptItem.originalSpeakerId]) {
        return;
      }

      lastSpeakerIndex = Math.max(lastSpeakerIndex, transcriptItem.speakerIndex);
      lastSpeakerNum = Math.max(
        lastSpeakerNum,
        parseInt(transcriptItem.originalSpeakerId?.replace('SPEAKER_', ''), 10) || 0
      );

      if (!speakerInfoByParticipantId[transcriptItem.speakerId]) {
        speakerInfoByParticipantId[transcriptItem.speakerId] = this.getSpeakerInfo(transcriptItem);
      }
      if (!speakerMap[transcriptItem.originalSpeakerId]) {
        speakerMap[transcriptItem.originalSpeakerId] = transcriptItem.speakerId;
      }
    });
    const lastSpeakerIndexInTranscript = lastSpeakerIndex;

    if (this.props.meeting) {
      [this.props.meeting.user, ...this.props.meeting.participants].forEach((participant: any) => {
        if (speakerInfoByParticipantId[participant.id]) {
          return;
        }

        lastSpeakerNum++;
        lastSpeakerIndex++;
        speakerInfoByParticipantId[participant.id] = {
          originalSpeakerId: 'N/A', // should never be actually visible in the UI, or used in any logic
          speakerFirstName: participant.first_name,
          speakerId: participant.id,
          speakerIndex: lastSpeakerIndex,
          speakerLastName: participant.last_name,
        };
      });
    }

    const speakerDisplayNames = Object.fromEntries(
      Object.keys(speakerMap).sort().map((originalSpeakerId, i) => [originalSpeakerId, i + 1])
    );

    return {
      speakerInfoByParticipantId,
      speakerMap,
      speakerDisplayNames,
      lastSpeakerIndexInTranscript
    };
  };

  remapTranscript = (recordingTranscript: ITranscript|null|undefined) => {
    if (!recordingTranscript) return recordingTranscript;
    return {
      ...recordingTranscript,
      transcript: recordingTranscript.transcript?.map(this.remapTranscriptItem),
      translatedTranscription: recordingTranscript.translatedTranscription?.map(this.remapTranscriptItem),
    };
  };

  remapTranscriptItem = (transcriptItem: ITranscriptItem) => {
    return {
      ...transcriptItem,
      ...this.state.speakerInfoByParticipantId?.[this.state.speakerMap?.[transcriptItem.originalSpeakerId]],
      originalSpeakerId: transcriptItem.originalSpeakerId,
    };
  };

  getSpeakerInfo = (transcriptItem: ITranscriptItem) => {
    return pick(
      transcriptItem,
      [
        'speaker', 'speakerId', 'speakerIndex',
        'speakerFirstName', 'speakerLastName',
        'originalSpeakerId'
      ]
    );
  };

  onSpeakerSelected = (originalSpeakerId: string, { value: speakerId }: any) => {
    this.setState({
      speakerMap: {
        ...this.state.speakerMap,
        [originalSpeakerId]: speakerId,
      },
    });
  };

  toggleTab = (tab: string) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  toggleMeetingTab = (tab: string) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeMeetingTab: tab,
      });
    }
  };

  handlePlayerStateChange = (state: any) => {
    this.setState({
      playing: !state.paused,
      progress: state.currentTime / state.duration * 100,
      isVideoReady: !!state.readyState,
      currentTimeSeconds: state.currentTime,
      currentTime: formatTime(state.currentTime),
      duration: formatTime(state.duration),
      durationSeconds: state.duration,
    });
  };

  updateSeek = (event: any, startTime?: number, key?: string) => {
    event.stopPropagation();

    this.setState({
      selectedQuestionObjection: key || ''
    })

    if (startTime !== undefined) {
      this.player.seek(startTime);
      return;
    }

    let clientLine = document.getElementById('video-line-main');
    let ignoreElement = document.getElementById('resizable-rnd-div');
    let scissors = document.getElementById('add-resizable');

    let isClicked = ignoreElement ? ignoreElement.contains(event.target) : false;
    isClicked = scissors && !isClicked ? scissors.contains(event.target) : isClicked;

    if (!isClicked && clientLine) {
      let rect = clientLine.getBoundingClientRect(),
        left = (event.clientX - rect.left) / clientLine.clientWidth;

      this.player.seek(Math.floor(left * this.state.durationSeconds));
    }
  };

  recenterSnippetDiv = (updateWidth = true) => {
    let timeLine = document.getElementById('video-line-main');
    let resizableDiv = document.getElementById('resizable-rnd-div');
    let divWidth = this.state.snippetDivWidth;

    if (resizableDiv) {
      divWidth = resizableDiv.clientWidth;
    } else {
      resizableDiv = document.getElementById('video-inner-line');
    }

    if (timeLine && resizableDiv) {
      let parentPos = timeLine.getBoundingClientRect(),
        childPos = resizableDiv.getBoundingClientRect(),
        left = (childPos.left - parentPos.left) / timeLine.clientWidth,
        right = ((childPos.left - parentPos.left) + divWidth) / timeLine.clientWidth,
        divAxisX = childPos.left - parentPos.left;

      if (updateWidth) {
        let width = (childPos.left - parentPos.left) + divWidth;
        this.setState({ snippetDivWidth: width });
      }

      this.setState({ axisX: divAxisX });

      let snippetStartSec = Math.floor(left * this.state.durationSeconds);
      let snippetEndSec = Math.floor(right * this.state.durationSeconds);

      this.setState({
        snippetStartSeconds: snippetStartSec,
        snippetEndSeconds: snippetEndSec,
        showSnippetDiv: true
      });

      this.player.seek(snippetStartSec);
    }
  };

  recenterSnippetDivBySeconds(startSec: number, endSec: number) {
    let timeLine = document.getElementById('video-line-main');

    if (timeLine) {
      let secWidth = timeLine.clientWidth / this.state.durationSeconds;
      let startPos = secWidth * startSec;
      let snippetWidth = (secWidth * endSec) - startPos;

      this.setState({
        axisX: startPos,
        snippetDivWidth: snippetWidth,
        showSnippetDiv: true,
        snippetStartSeconds: startSec,
        snippetEndSeconds: endSec,
      });

      this.player.seek(startSec);
    }
  }

  handleSnippetSubmit = () => {
    const {
      sessionId,
      postRecordingSnippet: postRecordingSnippetAction,
      getRecordingSnippets: getRecordingSnippetsAction,
    } = this.props;

    const {
      snippetName,
      snippetDescription,
      snippetStartSeconds,
      snippetEndSeconds
    } = this.state;

    let data = {
      name: snippetName,
      description: snippetDescription,
      start_time: snippetStartSeconds,
      end_time: snippetEndSeconds,
    }

    if (postRecordingSnippetAction) {
      this.toggleTab("2");
      postRecordingSnippetAction(
        sessionId,
        data,
        (snippet: any) => {
          if (getRecordingSnippetsAction) {
            getRecordingSnippetsAction(sessionId)
          }
        });
    }

    this.setState({
      snippetDescription: '',
      showSnippetForm: false
    })
  }

  handlePlaySnippet = () => {
    this.player.play();
    this.setState({ snippetStop: true });
  }

  updateSeekDirect = (seconds: number) => {
    this.player.seek(Math.floor(seconds));
  };

  getleftValue(start: any) {
    return start * 100 / this.state.durationSeconds;
  };

  getWidthValue(start: any, end: any) {
    return (end - start) * 100 / this.state.durationSeconds;
  };

  getParticipantName = (participant: any, showFullName = false) => {
    let participantName = '';

    if (participant.first_name || participant.last_name) {
      participantName = `${participant.first_name ?? ''} ${participant.last_name ?? ''}`;
    } else if (participant.email) {
      participantName = participant.email;
    } else if (participant.phone) {
      participantName = participant.phone;
    }

    if (showFullName) {
      return participantName;
    }

    if (participantName.length > 30) {
      participantName = `${participantName.substring(0, 30)}...`;
    }

    return participantName;
  };

  getTimelineParticipantName = (participant: any) => {
    return participant.firstName || participant.lastName
      ? `${participant.firstName ?? ''} ${participant.lastName ?? ''}`
      : participant.email ? participant.email : participant.phone
      ;
  };

  getColor = (number: any) => {
    const colors: string[] = ['2F8FD4', '00B388', 'e8cb31', 'f1734f', '6f42c1', 'e83e8c', 'AF9AB2', '564ab1', 'F25058', '589B9B'];
    return `#${colors[number % colors.length]}`;
  }

  handleSnippetClick(startTime: number, endTime: number) {
    this.recenterSnippetDivBySeconds(startTime, endTime);
    window.scrollTo(0, 0);
  }

  isLoading = () => {
    const {
      getTimeLine: getTimeLineAction,
      getMeetingAIAnalytics: getMeetingAIAnalyticsAction,
      getRecordingSnippets: getRecordingSnippetsAction,
    } = this.props;

    return (
      (!this.timelineDataLoaded && getTimeLineAction)
      || (!this.aiAnalyticsLoaded && getMeetingAIAnalyticsAction)
      || (!this.snippetsLoaded && getRecordingSnippetsAction)
    );
  }

  toggleAddSnippet = () => {
    this.setState({ showSnippetForm: false, showShareModal: false });
  };

  handleChangeSnippetName = (snippetName: string) => {
    this.setState({ snippetName: snippetName })
  }

  handleChangeSnippetDescription = (snippetDescription: string) => {
    this.setState({ snippetDescription: snippetDescription })
  }

  handleLanguage = (language: OptionTypeBase | null) => {
    const { sessionId } = this.props;

    if (language?.value) {
      this.setState({ language: language.value });
      updateSessionLanguage(sessionId, language.value);
    }
  };

  handleSnippetShare = (name: string, description: string, uuid: string, startTime: number, endTime: number) => {
    const { handleSnippetShare: handleSnippetShareAction } = this.props;

    this.setState((prevState) => (
      {
        showShareModal: !prevState.showShareModal,
        shareRecording: true,
        showSnippetDiv: false,
        snippetStartSeconds: startTime,
        snippetEndSeconds: endTime,
        snippetUuid: uuid,
        snippetName: name,
        snippetDescription: description,
      }
    ));

    if (handleSnippetShareAction) {
      handleSnippetShareAction(uuid);
    }
  }

  calculateVirtualizedListItemHeight = (index: number) => {
    let itemSize = 100;
    const windowWidth = window.screen.width;
    let breakpoint = 1;
    let columnCount = 3;
    const padding = 20;
    const lineHeight = 10; // based on line-height
    const aproxCharWidth = 6; // based on font-size
    const { recordingTranscript } = this.props;

    if (windowWidth >= 992) {
      breakpoint = 2;
      columnCount = 1;
    }
    if (windowWidth < 992 && windowWidth >= 768) {
      breakpoint = 3;
    }

    if (recordingTranscript?.transcript?.length) {
      const element = recordingTranscript.transcript[index];
      const numberOfChar = element?.transcript?.length | 1;
      const containerWidth = window.screen.width / breakpoint;
      const numberOfRows = numberOfChar / (containerWidth / aproxCharWidth);
      itemSize = Math.ceil(numberOfRows) * lineHeight + columnCount * lineHeight;
    }

    return itemSize + padding;
  }

  scrollToVirtualizedListItem = (itemIndex: number) => {
    this.virtualizedList.current?.scrollToItem(Number(itemIndex), 'center');
  }

  handleActiveTranscriptItemChange = () => {
    const { recordingTranscript } = this.props;
    const { currentTimeSeconds, activeTranscriptItem } = this.state;
    const closestItem = this.findClosestIndex(currentTimeSeconds, recordingTranscript?.transcript || []);

    if (closestItem >= 0 && closestItem !== activeTranscriptItem) {
      this.setState({ activeTranscriptItem: closestItem });
    }
  }

  findClosestIndex = (number: number, transcripts: ITranscriptItem[]) => {
    const closesTranscriptIndex = findIndex(
      transcripts,
      (transcript: ITranscriptItem) => number < transcript.start
    );

    return closesTranscriptIndex - 1;
  }

  findSearchItems = () => {
    const { recordingTranscript } = this.props;
    const { searchQuery } = this.state;

    const items = keys(
      pickBy(
        recordingTranscript?.transcript,
        (transcript) => transcript.transcript.toLocaleLowerCase().includes(searchQuery?.toLocaleLowerCase() || '')
      )
    );
    const searchItemIds = items.map((index) => Number(index));

    this.setState({
      searchItemIds,
      searchItem: 0
    })

    if (!isEmpty(searchItemIds) && !isEmpty(searchQuery)) {
      this.scrollToVirtualizedListItem(searchItemIds[0]);
    }
  }

  searchTranscript = debounce(() => {
    this.findSearchItems();
  }, 300);

  requestTranscript = () => {
    const { sessionId } = this.props;

    requestRecordingTranscription(sessionId);
  }

  requestParseNaming = () => {
    const { sessionId } = this.props;

    requestRecordingTranscriptParseNaming(sessionId);
  }

  handleSearchTranscript = (event: any) => {
    const value = event.target.value;
    this.setState({
      searchQuery: value,
      transcriptAutoScroll: !value,
    }, this.searchTranscript);
    event.preventDefault();
  }

  handleSearchTranscriptPrev = () => {
    const { searchItem, searchItemIds } = this.state;
    const prev = searchItem - 1;

    if (prev >= 0 && prev !== searchItem) {
      this.setState({ searchItem: prev });
      this.scrollToVirtualizedListItem(searchItemIds[prev]);
    }
  }

  handleSearchTranscriptNext = () => {
    const { searchItem, searchItemIds } = this.state;
    const next = searchItemIds[searchItem + 1] ? searchItem + 1 : searchItem;

    if (next && next !== searchItem) {
      this.setState({ searchItem: next });
      this.scrollToVirtualizedListItem(searchItemIds[next]);
    }
  }

  handleSearchTranscriptCancel = () => {
    this.setState({
      searchQuery: '',
      searchItemIds: [],
      searchItem: -1,
      transcriptAutoScroll: true,
    })
  }

  getParticipantDisplayName = (
    transcript: { speakerFirstName?: string; speakerLastName?: string; speakerId?: string, originalSpeakerId?: string },
    participants: IAnalyticsMeetingAIAnalytics[]
  ): string => {
    const { speakerFirstName, speakerLastName, speakerId, originalSpeakerId } = transcript;

    if (speakerFirstName || speakerLastName) {
      return `${speakerFirstName ?? ''} ${speakerLastName ?? ''}`.trim();
    }

    if (speakerId) {
      const participant = participants.find(p => p.participant.id === speakerId)?.participant;

      return (
        `${participant?.first_name ?? ''} ${participant?.last_name ?? ''}`.trim() ||
        participant?.email ||
        participant?.phone ||
        ''
      );
    }

    return '';
  };

  handleAgentTranscriptSeek = (numberOfSentence: string) => {
    const { recordingTranscript } = this.props;

    if (!recordingTranscript || isEmpty(recordingTranscript.transcript)) {
      return;
    }

    const startTime = findStartTime(recordingTranscript.transcript, numberOfSentence);

    if (startTime !== null) {
      this.player.seek(startTime);
      this.player.play();
    }
  };

  VirtualizedListRow = ({ data, index, style }: any) => {
    const { activeTranscriptItem, searchQuery } = this.state;
    const { meetingAIAnalytics } = this.props;
    const transcript = data[index];
    let displayName = meetingAIAnalytics && this.getParticipantDisplayName(transcript, meetingAIAnalytics);

    return (
      <Row
        style={style}
        className={`transcript__item ${activeTranscriptItem === index ? 'active' : ''}`}
        onClick={() => {
          this.player.seek(transcript.start.toFixed(6));
          this.player.play();
        }}
        data-id={index}
        data-speaker={transcript.speaker}
      >
        <Col lg="2" md="12">
          <div className="transcript__item--details">
            <span>
              {formatTime(transcript.start)}
            </span>
            {this.state.showSpeakerId && (
              <span className="badge" style={{ backgroundColor: this.getColor(transcript.speakerIndex) }}>
                ({transcript.originalSpeakerId})
              </span>
            )}
            {this.state.showFixSpeakers && (
              <span className="badge" style={{
                backgroundColor: this.getColor(transcript.speakerIndex),
                width: 'fit-content',
              }}>
                {this.state.speakerDisplayNames[transcript.originalSpeakerId]}
              </span>
            )}
            <span className='badge' style={{ backgroundColor: this.getColor(transcript.speakerIndex) }}>
              {displayName}
            </span>
          </div>
        </Col>
        <Col lg="10">
          {this.renderTranscriptText(transcript.transcript, searchQuery)}
          <hr />
        </Col>
      </Row>
    );
  };

  QAndAVirtualizedRow = ({ data, index, style }: any) => {
    const { searchQuery } = this.state;
    const transcript = data[index];

    return (
      <Row
        style={style}
        className="transcript__item"
        onClick={() => {
          this.player.seek(transcript.start.toFixed(6));
          this.player.play();
        }}
        data-id={index}
      >
        <Col lg="2" md="12">
          <div className="transcript__item--details">
            <span>
              {formatTime(transcript.start)}
            </span>
            <span className='badge' style={{ backgroundColor: this.getColor(transcript.speakerIndex) }}>
              {
                transcript.speakerFirstName || transcript.speakerLastName
                  ? `${transcript.speakerFirstName} ${transcript.speakerLastName}`
                  : ``
              }
            </span>
          </div>
        </Col>
        <Col lg="10">
          {this.renderTranscriptText(transcript.transcript, searchQuery)}
          <hr />
        </Col>
      </Row>
    );
  };

  renderTranscriptText = (transcriptText: string, searchQuery: string) => {
    if (!searchQuery) {
      return <span>
        {transcriptText}
      </span>
    }

    const splitText = split(transcriptText.toUpperCase(), searchQuery.toUpperCase());
    let splitIndex = 0;

    return map(splitText, (text) => {
      const originalText = transcriptText.slice(splitIndex, splitIndex + text.length);
      splitIndex += text.length;
      const highlightedText = transcriptText.slice(splitIndex, splitIndex + searchQuery.length);
      splitIndex += searchQuery.length;

      return (
        <>
          <span>
            {originalText}
          </span>
          <span style={{ backgroundColor: 'yellow' }}>
            {highlightedText}
          </span>
        </>
      )
    })
  }

  sortByKey = (array: any, key: string) => {
    return array.sort(function (a: any, b: any) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  checkExists = (value: string) => {
    return this.state.shareToUser.map((user) => user.value).includes(value);
  }

  onSelectSharedTarget = (target: any) => {
    if (!this.checkExists(target.value)) {
      target.can_view = 'none';
      target.can_comment = false;
      this.setState({ shareToUser: this.sortByKey([...this.state.shareToUser, target], 'type') });
      setTimeout(() => {
        this.handleSubmitCreateAndShare({ isClose: false })
      }, 1);
    }
  }

  onHandleGeneralAccess = (option: string) => {
    this.setState({ generalAccess: option });
    setTimeout(() => {
      this.handleSubmitCreateAndShare({ isClose: false })
    }, 1);
  }

  removeShareUser = (label: string) => {
    this.setState({ shareToUser: this.state.shareToUser.filter((user) => user.label !== label) });
    setTimeout(() => {
      this.handleSubmitCreateAndShare({ isClose: false })
    }, 1);
  }

  canViewHandler = (label: string, permission: any) => {
    this.setState({
      shareToUser: this.state.shareToUser.map((user) => {
        user.can_view = 'none' // Comments viewing on snippets not allowed
        return user;
      })
    });

    setTimeout(() => {
      this.handleSubmitCreateAndShare({ isClose: false })
    }, 1);
  }

  canCommentHandler = (label: string) => {
    this.setState({
      shareToUser: this.state.shareToUser.map((user) => {
        user.can_comment = false; // Creating comments on snippets not allowed
        return user;
      })
    });
    setTimeout(() => {
      this.handleSubmitCreateAndShare({ isClose: false })
    }, 1);
  }

  changeShareRecording = () => {
    this.setState({
      shareRecording: !this.state.shareRecording,
      shareToUser: [],
    });
  }

  handleGetShareLinkPermissions = (): any => {
    const userPermissions = this.state.shareToUser.map((user) => {
      let type = 'account';
      if (user.type === 1) {
        type = 'team';
      } else if (user.type === 2) {
        type = 'user';
      }
      return ({
        type: type,
        user_id: user.user_id ? user.user_id : null,
        team_id: user.team_id ? user.team_id : null,
        can_view: user.can_view,
        can_comment: user.can_comment,
      })
    })

    if (this.state.generalAccess === "anyone") {
      const generalPermissions = {
        type: 'other',
        can_view: 'none',
        can_comment: false,
        user_id: null,
        team_id: null,
      }

      return [
        ...userPermissions,
        generalPermissions
      ];
    }

    return userPermissions;
  }

  handleSuccess = () => {
    if (!this.state.showClipoardMessage) {
      this.setState({
        showClipoardMessage: true,
      })
    }

    setTimeout(() => {
      this.setState({
        showClipoardMessage: false
      });
    }, 3000);
  };

  handleSubmitCreateAndShare = ({ isClose = false }: { isClose: boolean }) => {
    const {
      sessionId,
      postRecordingSnippet: postRecordingSnippetAction,
      getRecordingSnippets: getRecordingSnippetsAction,
      createShareLink: createShareLinkAction,
      updateShareLink: updateShareLinkAction,
      updateSnippetAction,
      snippetShareLinkId
    } = this.props;

    const {
      snippetUuid,
      snippetName,
      snippetDescription,
      snippetStartSeconds,
      snippetEndSeconds
    } = this.state;

    let data = {
      name: snippetName,
      description: snippetDescription,
      start_time: snippetStartSeconds,
      end_time: snippetEndSeconds,
    }

    if (snippetUuid && updateSnippetAction) {
      const payload = {
        permission: this.handleGetShareLinkPermissions(),
        snippet_uuid: snippetUuid,
        recording_session_id: this.props.recordingSessionId,
        name: snippetName,
        emails: [],
        user_ids: [],
        include_enriched_timeline: true,
        description: snippetDescription,
      };

      updateSnippetAction(snippetUuid, payload,( link: string) => {
        if (isClose) {
          this.setState({
            showShareModal: false,
          });
        } else {
          this.handleSuccess();
        }
      });

      if (snippetShareLinkId && updateShareLinkAction) {
        updateShareLinkAction(
          snippetShareLinkId,
          payload,
          (link: string) => {
            if (isClose) {
              this.setState({
                showShareModal: false,
              });
            } else {
              navigator.clipboard.writeText(link);
              this.handleSuccess();
            }
          }
        );

      } else if (createShareLinkAction) {
        createShareLinkAction(
          payload,
          (link: string) => {
            if (isClose) {
              this.setState({
                showShareModal: false,
              });
            } else {
              navigator.clipboard.writeText(link);
              this.handleSuccess();
            }
          }
        );
      }
    }  else if (postRecordingSnippetAction) {
      this.toggleTab("2");
      postRecordingSnippetAction(
        sessionId,
        data,
        (snippet: any) => {
          this.setState({ snippetUuid: snippet.uuid });
          if (createShareLinkAction) {
            const payload = {
              permission: this.handleGetShareLinkPermissions(),
              snippet_uuid: snippet.uuid,
              name: snippet.name,
              emails: [],
              user_ids: [],
              include_enriched_timeline: true
            };
            if (getRecordingSnippetsAction) {
              getRecordingSnippetsAction(this.props.recordingSessionId);
            }
            createShareLinkAction(
              payload,
              (link: string) => {
                if (isClose) {
                  this.setState({
                    showShareModal: false,
                  })
                } else {
                  navigator.clipboard.writeText(link);
                  this.handleSuccess();
                }
              }
            );
          }
        }
      );
    }
  }

  changeShowAddCommentModal = () => {
    this.setState({ showAddCommentModal: !this.state.showAddCommentModal });
  }

  closeAddCommentModal = () => {
    this.setState({ showAddCommentModal: false });
  }

  closeShareModalHandler = (isCreateSnippet: boolean) => {
    if(!isCreateSnippet) {
      return this.setState({
        showShareModal: false,
      });
    }

    this.handleSubmitCreateAndShare({ isClose: true })
  }

  closeRndTooltip = () => {
    this.setState(() => ({ showSnippetDiv: false }));
  }

  formatSummary(summary: string) {
    let formattedSummary;

    if (summary.includes('**')) {
      formattedSummary = summary.replace(/\*\*(.*?)\*\*/g, '<span style="font-weight: bold !important;">$1</span>');
    } else {
      formattedSummary = summary.replace(/([^:]*:)(.*)/gm, '<span style="font-weight: bold !important;">$1</span><span style="font-weight: normal !important;">$2</span>');
    }

    // Replace numbered list lines properly with breaks
    const formattedSummary2 = formattedSummary
      .replace(/\n(\d+\.)/g, '<br/>$1')
      .replace(/\n+/g, '<br/>');

    return { __html: formattedSummary2 };
  }

  handleDownloadTranscriptCSV = async () => {
    const { sessionId } = this.props;
    await downloadTranscriptCsv(sessionId);
  }

  getMeetingEndDate = (meetingDate: string | Date, durationInSeconds: number): Date => {
    const startDate = (typeof meetingDate === "string") ? new Date(meetingDate) : meetingDate;

    return new Date(startDate.getTime() + (durationInSeconds * 1000));
  };

  durationInSeconds = (duration: string): number => {
    const parts = duration.split(':').map(Number);

    if (parts.length === 3) {
      const [hours, minutes, seconds] = parts;
      return (hours * 3600) + (minutes * 60) + seconds;
    } else if (parts.length === 2) {
      const [minutes, seconds] = parts;
      return (minutes * 60) + seconds;
    }
    return 0;
  };

  isWithinLast3Hours = (dateString: string | Date): boolean => {
    const meetingDateTime = (dateString instanceof Date) ? dateString.getTime() : new Date(dateString).getTime();
    const now = new Date().getTime();

    return (now - meetingDateTime) <= THREE_HOURS_IN_MS;
  };

  isShorterThan5Minutes = (meetingTime: number): boolean => {
    return meetingTime < 300;
  };

  restoreSpeakerMap = () => {
    this.setState({
      showFixSpeakers: false,
      speakerMap: this.state.speakerMapBeforeEdit,
    });
  };

  saveSpeakerMap = () => {
    saveMeetingSpeakerRemapping(
      this.props.sessionId,
      {
        ...this.ensureNumericIdsStayNumeric(this.state.speakerMap),
        UNKNOWN_SPEAKER: this.state.speakerMap['null'],
        null: undefined,
      }
    );
    this.setState({
      showFixSpeakers: false,
      speakerMapBeforeEdit: this.state.speakerMap,
      timelineDeleted: true,
    });
  };

  ensureNumericIdsStayNumeric = (speakerMap: any) => Object.fromEntries(
    Object.entries(speakerMap).map(([originalSpeakerId, speakerId]) => [
      originalSpeakerId,
      ('' + parseInt('' + speakerId, 10)) === speakerId ? parseInt(speakerId, 10) : speakerId,
    ]),
  );

  resetSpeakerMap = () => {
    this.setState({
      showResetSpeakersModal: true,
    })
  }

  confirmResetSpeakers = async () => {
    this.setState({
      showFixSpeakers: false,
      showResetSpeakersModal: false,
      speakerMapBeforeEdit: this.state.speakerMap,
      timelineDeleted: true,
    });
    return resetMeetingSpeakerMapping(this.props.sessionId);
  }

  cancelResetSpeakers = () => {
    this.setState({
      showResetSpeakersModal: false,
    })
  }

  renderSwapDropdown = (subject: any, transcriptCanBeRemapped: boolean) => (
    <Dropdown
      className="swap-participants-dropdown"
      isOpen={this.state.swapDropdownParticipantId === subject.id}
      toggle={() => {
        if (transcriptCanBeRemapped) {
          this.setState({
            swapDropdownParticipantId: !!this.state.swapDropdownParticipantId ? null : subject.id
          });
        }
      }}
    >
      <DropdownToggle tag="button">
        <h6>
          <span title={this.getTimelineParticipantName(subject)}>
            {this.getTimelineParticipantName(subject)}
          </span>
        </h6>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Switch transcript sentences with...</DropdownItem>
        {this.props.timeLineData
          .filter(({ participant }: any) => participant.id !== subject.id)
          .map(({ participant }: any) => (
            <DropdownItem
              className={`force-${participant.type === 'presenter' ? 'speaker' : 'client'}-color`}
              tag="button"
              key={participant.id}
              onClick={() => this.swapWith(participant)}
            >
              <h6>
                <span>
                  {this.getTimelineParticipantName(participant)}
                </span>
              </h6>
            </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );

  swapWith = (participant: any) => {
    const swapDropdownParticipantId = this.state.swapDropdownParticipantId;
    this.setState({ showFixSpeakers: true }, async () => {
      this.matchSpeakersEl.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      await sleep(500);
      const speakerMap = Object.fromEntries(
        Object.entries(this.state.speakerMap)
          .map(([originalSpeakerId, speakerId]) => [
            originalSpeakerId,
            `${speakerId}` === `${participant.id}` ? `${swapDropdownParticipantId}` : (
              `${speakerId}` === `${swapDropdownParticipantId}` ? `${participant.id}` : `${speakerId}`
            )
          ])
      );
      this.setState({ speakerMap });
    });
  };

  goToLongestSpeechRegion = (originalSpeakerId: string, remappedTranscript?: ITranscript | null) => {
    let longestRegionStart: ITranscriptItem | null = null;
    let longestRegionDuration: number = 0;
    let currentRegionStart: ITranscriptItem | null = null;
    let currentRegionDuration: number = 0;

    remappedTranscript?.transcript.forEach((transcriptItem, i) => {
      if (`${transcriptItem.originalSpeakerId}` === `${originalSpeakerId}`) {
        if (!currentRegionStart) {
          currentRegionStart = transcriptItem;
        }
        currentRegionDuration = transcriptItem.end - currentRegionStart.start;
      }
      if (
        currentRegionStart && `${transcriptItem.originalSpeakerId}` !== `${originalSpeakerId}`
        || i === remappedTranscript.transcript.length - 1
      ) {
        if (currentRegionDuration > longestRegionDuration) {
          longestRegionStart = currentRegionStart;
          longestRegionDuration = currentRegionDuration;
        }
        currentRegionStart = null;
        currentRegionDuration = 0;
      }
    });

    if (longestRegionStart) {
      this.player.seek((longestRegionStart as ITranscriptItem).start.toFixed(6));
      this.player.play();
    } else {
      this.setState({ showNoSentencesModal: true });
    }
  }

  getUniquePlaybookAgentsDropdown = (): DropdownOption[] => {
    const { allPlaybooksForSession } = this.props;

    if (!allPlaybooksForSession) return [];

    const uniquePlaybookAgents = new Set<number>();

    return allPlaybooksForSession
      .filter((playbook) => {
        if (uniquePlaybookAgents.has(playbook.agentId)) {
          return false;
        }
        uniquePlaybookAgents.add(playbook.agentId);
        return true;
      })
      .map((playbook) => ({
        id: playbook.agentId,
        label: playbook.agentName ?? 'Unknown agent',
        value: playbook.id,
      }));
  };

  getUniqueMagicVariableAgentsDropdown = (): DropdownOption[] => {
    const { allMagicVariableForSession } = this.props;

    if (!allMagicVariableForSession) return [];

    const uniqueMagicVariableAgents = new Set<number>();

    return allMagicVariableForSession
      .filter((playbook) => {
        if (uniqueMagicVariableAgents.has(playbook.agentId)) {
          return false;
        }
        uniqueMagicVariableAgents.add(playbook.agentId);
        return true;
      })
      .map((playbook) => ({
        id: playbook.agentId,
        label: playbook.agentName ?? 'Unknown agent',
        value: playbook.id,
      }));
  };

  handlePlaybookSelect = (option: DropdownOption) => {
    const { allPlaybooksForSession } = this.props;

    if (null === allPlaybooksForSession || undefined === allPlaybooksForSession) {
      return;
    }

    const selectedPlaybook = allPlaybooksForSession.find(
      (playbook) => playbook.id === option.value
    );

    const selectedPlaybookDropdown = this.getUniquePlaybookAgentsDropdown().find(
      (opt) => opt.id === option.id
    );

    this.setState({
      selectedPlaybook: selectedPlaybook ?? null,
      selectedPlaybookDropdown: selectedPlaybookDropdown ?? null,
    });
  };

  handleMagicVariableSelect = (option: DropdownOption) => {
    const { allMagicVariableForSession } = this.props;

    if (null === allMagicVariableForSession || undefined === allMagicVariableForSession) {
      return;
    }

    const selectedMagicVariables = allMagicVariableForSession.find(
      (dataInsight) => dataInsight.id === option.value
    );

    const selectedMagicVariablesDropdown = this.getUniqueMagicVariableAgentsDropdown().find(
      (opt) => opt.id === option.id
    );

    this.setState({
      selectedMagicVariables: selectedMagicVariables ?? null,
      selectedMagicVariablesDropdown: selectedMagicVariablesDropdown ?? null,
    });
  };

  cancelEditParticipants = () => {
    this.setState({ isEditingParticipants: false });
    this.setParticipantsDraftFromMeeting();
  };

  hasAnySentences = (participant: any) => {
    return !this.props.recordingTranscript?.transcript?.length
      || this.props.recordingTranscript?.transcript?.some(
        ({ speakerId }) => `${speakerId}` === `${participant.id}`
      );
  }

  render() {
    const {
      progress,
      isVideoReady,
      playing,
      isVideoAvailable,
      currentTimeSeconds,
      durationSeconds,
      currentTime,
      duration,
      snippetStartSeconds,
      snippetEndSeconds,
      showSnippetDiv,
      showSnippetForm,
      searchQuery,
      searchItem,
      searchItemIds,
      showShareModal,
      useTranslation,
      selectedQuestionObjection,
      showFixSpeakers,
      speakerMap,
      showResetSpeakersModal,
      showNoSentencesModal,
      timelineDeleted,
      selectedPlaybook,
      selectedMagicVariables,
      selectedPlaybookDropdown,
      selectedMagicVariablesDropdown,
      isEditingParticipants,
    } = this.state;
    const {
      accountId,
      allPlaybooksForSession,
      allMagicVariableForSession,
      currentuser,
      currentaccountselected,
      timeLineData,
      meetingAIAnalytics,
      recording,
      recordingLoader,
      meeting,
      snippets,
      showComments,
      canComment,
      showSnippets,
      showAddSnippet,
      recordingTranscript,
      showTranscription,
      showTabs,
      history,
      users,
      teams,
      canExportCSV,
      accountTags,
      entityTags,
      sessionId,
      onAddTag,
      deleteTag,
      getAllTagsByAccount,
    } = this.props;

    const meetingName = meeting?.name ?? '';
    const meetingDate = meeting?.date ?? '';
    document.title = meetingName.toString();
    let aiScore: any = 0;
    let maxDivision: number = 0;
    let maxShifts: number = 0;
    let maxInterruptions: number = 0;
    let maxEngagement: number = 0;
    if (meetingAIAnalytics && !isEmpty(meetingAIAnalytics)) {
      aiScore = meetingAIAnalytics?.find(el => el.meeting_score > 0);
      aiScore = typeof aiScore === 'undefined' ? 0 : aiScore.meeting_score;
      maxDivision = Math.max(...meetingAIAnalytics.map(o => o.division));
      maxShifts = Math.max(...meetingAIAnalytics.map(o => o.shifts_per_minute));
      maxInterruptions = Math.max(...meetingAIAnalytics.map(o => o.interruptions));
      maxEngagement = Math.max(...meetingAIAnalytics.map(o => o.meeting_engagement_percentage));
    }

    const questions: ITranscriptItem[] = filter(
      recordingTranscript?.transcript ?? [],
      (item: ITranscriptItem) =>
        item.isQuestion
    );

    const translatedQuestions: ITranscriptItem[] = filter(
      recordingTranscript?.translatedTranscription ?? [],
      (item: ITranscriptItem) =>
        item.isQuestion
    );

    const objections: ITranscriptItem[] = filter(
      recordingTranscript?.transcript ?? [],
      (item: ITranscriptItem) =>
        item.isObjection
    );

    const userOptions = users?.map((user) => ({ value: user.id, label: user.first_name + ' ' + user.last_name, email: user.email, type: 2, user_id: user.id, can_comment: false, can_view: 'all' }));
    const teamOptions = teams?.map((team) => ({ value: team.id, label: team.name, type: 1, team_id: team.id, can_comment: false, can_view: 'all' }));

    const groupedOptions = [
      {
        label: "Account members",
        options: [{ value: "Account members", label: "Account members", type: 0, can_comment: false, can_view: 'all' }]
      },
      {
        label: "Team",
        options: teamOptions
      },
      {
        label: "Users",
        options: userOptions
      }
    ];

    const meetingEndDate = meeting && this.getMeetingEndDate(meeting.date.toString(), meeting.meeting_time);

    let message = "No transcript available for this meeting, please contact support!";
    if (meetingEndDate && meeting.call === false && this.isShorterThan5Minutes(meeting.meeting_time)) {
      message = "This meeting is less than 5 minutes, therefore no transcript was generated.";
    } else if (meetingEndDate && this.isWithinLast3Hours(meetingDate.toString())) {
      message = "Transcript generating, please come back later!";
    }

    const speakerOptions = !meeting
      ? []
      : [meeting.user, ...meeting.participants].map((participant: any) => ({
        value: participant.id,
        label: this.getTimelineParticipantName(participant) || this.getParticipantName(participant),
      }));

    const transcriptCanBeRemapped = !!recordingTranscript &&
      recordingTranscript.status !== 'pending' &&
      recordingTranscript.transcript?.some(({ originalSpeakerId }) => !!originalSpeakerId);

    const remappedTranscript = transcriptCanBeRemapped ? this.remapTranscript(recordingTranscript) : recordingTranscript;
    const transcriptListData = useTranslation
      ? remappedTranscript?.translatedTranscription
      : remappedTranscript?.transcript;

    const playbookAgentsDropdown = this.getUniquePlaybookAgentsDropdown();
    const dataInsightsAgentDropdown = this.getUniqueMagicVariableAgentsDropdown();

    return (
      <>
        {this.isLoading() ? (
          <div className="loader-container">
            <div className="loader">
              <img src={loaderImg} alt="loader" />
            </div>
          </div>
        ) : (
          <div className='d-flex justify-content-around' id='meeting-ai-page'>
            {meetingAIAnalytics
              && !isEmpty(meetingAIAnalytics)
              && !isEmpty(currentuser) &&
              <div className='d-flex flex-column' id='meeting-ai-column'>
                <h4 className='border-bottom my-2 pl-3'>AI Insights</h4>
                <div id='meeting-ai-analytics' className='open'>
                  <p className='score'>
                    Meeting score
                    <span className='color-yellow ml-2'>
                      {formatPercentage(aiScore)}
                    </span>
                  </p>

                  <h3>Talk:listen ratio</h3>
                  {meetingAIAnalytics.map((data: IAnalyticsMeetingAIAnalytics, key: number) => {
                    if (!this.hasAnySentences(data.participant)) {
                      return null;
                    }
                    return (
                      data.participant.type === 'presenter' &&
                      <div key={key} title={this.getParticipantName(data.participant, true)}>
                        {this.getParticipantName(data.participant)}
                        <span className='color-blue ml-2'>
                          {formatPercentage(data.division)}
                        </span>
                        {data.division > 0 &&
                          <div
                            className='bar'
                            style={{
                              width: data.division / maxDivision * 100 + '%',
                            }}
                          >
                          </div>
                        }
                      </div>
                    );
                  })}
                  {meetingAIAnalytics.map((data: IAnalyticsMeetingAIAnalytics, key: number) => {
                    if (!this.hasAnySentences(data.participant)) {
                      return null;
                    }
                    return (
                      data.participant.type !== 'presenter' &&
                      <div key={key} title={this.getParticipantName(data.participant, true)}>
                        {this.getParticipantName(data.participant)}
                        <span className='color-blue ml-2'>
                          {formatPercentage(data.division)}
                        </span>
                        {data.division > 0 &&
                          <div
                            className='bar'
                            style={{
                              width: data.division / maxDivision * 100 + '%',
                            }}
                          >
                          </div>
                        }
                      </div>
                    );
                  })}

                  <h3>Conversation shifts / min</h3>
                  {meetingAIAnalytics.map((data: IAnalyticsMeetingAIAnalytics, key: number) => {
                    if (!this.hasAnySentences(data.participant)) {
                      return null;
                    }
                    return (
                      data.participant.type === 'presenter' &&
                      <div key={key} title={this.getParticipantName(data.participant, true)}>
                        {this.getParticipantName(data.participant)}
                        <span className='color-blue ml-2'>
                          {formatDecimal(data.shifts_per_minute)}
                        </span>
                        {data.shifts_per_minute > 0 &&
                          <div
                            className='bar'
                            style={{
                              width: data.shifts_per_minute / maxShifts * 100 + '%',
                            }}
                          >
                          </div>
                        }
                      </div>
                    );
                  })}
                  {meetingAIAnalytics.map((data: IAnalyticsMeetingAIAnalytics, key: number) => {
                    if (!this.hasAnySentences(data.participant)) {
                      return null;
                    }
                    return (
                      data.participant.type !== 'presenter' &&
                      <div key={key} title={this.getParticipantName(data.participant, true)}>
                        {this.getParticipantName(data.participant)}
                        <span className='color-blue ml-2'>
                          {formatDecimal(data.shifts_per_minute)}
                        </span>
                        {data.shifts_per_minute > 0 &&
                          <div
                            className='bar'
                            style={{
                              width: data.shifts_per_minute / maxShifts * 100 + '%',
                            }}
                          >
                          </div>
                        }
                      </div>
                    );
                  })}

                  <h3>Engagement</h3>
                  {meetingAIAnalytics.map((data: IAnalyticsMeetingAIAnalytics, key: number) => {
                    if (!this.hasAnySentences(data.participant)) {
                      return null;
                    }
                    return (
                      data.participant.type !== 'presenter' &&
                      <div key={key} title={this.getParticipantName(data.participant, true)}>
                        {this.getParticipantName(data.participant)}
                        <span className='color-blue ml-2'>
                          {formatPercentage(data.meeting_engagement_percentage)}
                        </span>
                        {data.meeting_engagement_percentage > 0 &&
                          <div
                            className='bar'
                            style={{
                              width: data.meeting_engagement_percentage / maxEngagement * 100 + '%',
                            }}
                          >
                          </div>
                        }
                      </div>
                    );
                  })}

                  {/* recording tags */}
                  {sessionId && onAddTag && deleteTag && (
                    <div className='tags'>
                      <h3>Inspiration tags</h3>
                      <Tags
                        currentaccountselected={currentaccountselected}
                        accountTags={accountTags ? accountTags : []}
                        tags={recording.tags ? recording.tags : []}
                        onAddTag={onAddTag}
                        getAllTags={getAllTagsByAccount}
                        deleteTag={deleteTag}
                      />
                    </div>
                  )}
                </div>
              </div>
            }

            <div className='d-flex flex-column w-100'>
              <Row className={`metting-main video-container-bg m-0 py-0`}>
                <Container className={`video-container ${this.state.minimized ? 'minimized' : ''}`}>
                  <div className={`video-player-player-box`}>
                    <Player.Player
                      playsInline
                      src={recording?.url}
                      ref={(player: any) => { this.player = player }}
                    >
                      <Player.BigPlayButton
                        className={this.showPlayButton ? '' : 'big-play-button-hide'}
                        position="center"
                      />
                      <Player.ControlBar autoHide={true} disableDefaultControls={this.state.minimized}>
                        <Player.PlayToggle />
                        <Player.ReplayControl seconds={10} order={2.1} />
                        <Player.ForwardControl seconds={10} order={3.1} />
                        <Player.PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.75, 0.5]} order={6.1} />
                      </Player.ControlBar>
                    </Player.Player>
                  </div>
                </Container>
              </Row>
              {isVideoReady ? <div>
                <Row className="metting-main m-0 pt-4 pb-0">
                  <Container>
                    <Col lg="12">
                        <div className='back-btn'>
                          {isEmpty(meetingDate)
                            ? ''
                            : adaptDateToTimezone(
                            meetingDate as string,
                            currentaccountselected?.company_timezone,
                          )}
                        </div>
                      {!isVideoAvailable && <span>Video isn't available</span>}
                    </Col>
                  </Container>
                </Row>
                {!isEmpty(timeLineData) && isVideoReady &&
                  <Row className="metting-main m-0">
                    <Container>
                      <Col lg="12">
                        <h3 className="meeting-name-header ">{meetingName}</h3>
                      </Col>

                      {/* snippet tags */}
                      {recordingLoader && onAddTag && deleteTag && (
                        <div className='snippet-tags'>
                          <Tags
                            currentaccountselected={currentaccountselected}
                            accountTags={accountTags ? accountTags : []}
                            tags={entityTags ? entityTags : []}
                            getAllTags={getAllTagsByAccount}
                            onAddTag={onAddTag}
                            deleteTag={deleteTag}
                          />
                        </div>
                      )}
                    </Container>

                    {!(meeting?.live && recordingTranscript && !recordingTranscript?.areSpeakersRemapped) && (
                      <Container className="mt-2" style={{ position: 'relative' }}>
                        {timelineDeleted && (
                          <>
                            <div className="timeline-deleted-notice background"></div>
                            <span className="timeline-deleted-notice text">
                              Timeline is being generated, please come back later.
                            </span>
                          </>
                        )}
                        <Col lg="12">
                          <div className="header-control">
                            <div className="title">
                              <div className="attendee-header">
                                Attendees
                              </div>
                              <div className="timeline-header">
                                TimeLine
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col
                          lg="12"
                          id='timeline-wrapper'
                        >
                          <div className="video-line-main">
                            {isVideoReady && !isEmpty(timeLineData) && timeLineData.map((data: any, key: number) =>
                              this.hasAnySentences(data.participant) &&
                              data.participant.type === 'presenter' && (
                                <div className="speaker-line-main" key={key}>
                                  {this.renderSwapDropdown(data.participant, transcriptCanBeRemapped)}

                                  <div className="speaker-inner-main" id="speaker-line" onClick={(event) => this.updateSeek(event)}>
                                    {!isEmpty(data.talking) && data.talking.map((item: any, key: number) => (
                                        <div className="speaker-line"
                                          key={key}
                                          style={{
                                            display: isEmpty(data.talking) ? 'none' : 'block',
                                            left: this.getleftValue(item.start) + '%',
                                            width: this.getWidthValue(item.start, item.end) + '%'
                                          }}>
                                        </div>
                                      )
                                    )}

                                    {!isEmpty(objections) && objections.map((item: any, key: number) => {
                                      let uniqueIdentifier = `${key}${item.start}`;
                                      return ((
                                        data.participant.id.toString() === item.speakerId &&
                                        <div className={`speaker-objection-orange ${uniqueIdentifier === selectedQuestionObjection ? 'active-question-speaker' : ''}`}
                                          key={key}
                                          style={{
                                            cursor: "pointer",
                                            display: 'block',
                                            left: this.getleftValue(item.start) + '%'
                                          }}
                                          onClick={(event) => this.updateSeek(event, item.start, uniqueIdentifier)}
                                          >
                                            <div className='line-icon'><img src={objectionImg} alt='objection'/></div>
                                        </div>
                                      ))
                                    })}

                                    {!isEmpty(questions) && questions.map((item: any, key: number) => {
                                      let uniqueIdentifier = `${key}${item.start}`;
                                      return ((
                                        data.participant.id.toString() === item.speakerId &&
                                        <div className={`speaker-stop-btn-yellow ${uniqueIdentifier === selectedQuestionObjection ? 'active-question-speaker' : ''}`}
                                          key={key}
                                          style={{
                                            cursor: "pointer",
                                            display: 'block',
                                            left: this.getleftValue(item.start) + '%'
                                          }}
                                          onClick={(event) => this.updateSeek(event, item.start, uniqueIdentifier)}
                                          >
                                            <div className='line-icon'><img src={questionImg} alt='objection' /></div>
                                        </div>
                                      ))
                                    })}
                                  </div>
                                </div>
                              )
                            )}

                            {isVideoReady && !isEmpty(timeLineData) && timeLineData.map((data: any, key: number) =>
                              this.hasAnySentences(data.participant) && (
                                data.participant.type !== 'presenter' &&
                                <div className='client-line-main' key={key}>
                                  {this.renderSwapDropdown(data.participant, transcriptCanBeRemapped)}

                                  <div className='client-line' id="client-line">
                                    {!isEmpty(data.engagement) && data.engagement.map((item: any, key: number) => {
                                      return (
                                        (
                                          item.start > 0 || item.end < this.state.durationSeconds
                                          || (item.start < 0 && item.end > this.state.durationSeconds)
                                        ) && item.status.toLowerCase() == 'active' && <div
                                          key={key}
                                          className="client-green-line"
                                          id={data.participant.id + 'client-green-line' + key}
                                          style={{
                                            left: this.getleftValue(item.start > 0 ? item.start : 0) + '%',
                                            width: this.getWidthValue(
                                              item.start > 0 ? item.start : 0,
                                              item.end < this.state.durationSeconds ? item.end : this.state.durationSeconds) + '%'
                                          }}
                                        ></div>
                                      )
                                    })}
                                    {!isEmpty(data.talking) && data.talking.map((item: any, key: number) => {
                                      return (
                                        <div className="client-stop-btn" id={data.participant.id + 'client-stop-btn' + key}
                                          key={key}
                                          style={{
                                            left: this.getleftValue(item.start) + '%',
                                            width: this.getWidthValue(item.start, item.end) + '%'
                                          }}
                                        ></div>
                                      )
                                    })}

                                    {!isEmpty(objections) && objections.map((item: any, key: number) => {
                                      let uniqueIdentifier = `${key}${item.start}`;
                                      return ((
                                        data.participant.id == item.speakerId &&
                                        <div className={`objection-orange ${uniqueIdentifier === selectedQuestionObjection ? 'active-question' : ''}`} id={data.participant.id + 'objection-orange' + key}
                                          key={key}
                                          style={{
                                            left: this.getleftValue(item.start) + '%'
                                          }}
                                          onClick={(event) => this.updateSeek(event, item.start, uniqueIdentifier)}
                                          >
                                            <div className='line-icon'><img src={objectionImg} alt='objection'/></div>
                                          </div>
                                      ))
                                    })}

                                    {!isEmpty(questions) && questions.map((item: any, key: number) => {
                                      let uniqueIdentifier = `${key}${item.start}`;
                                      return ((
                                        data.participant.id == item.speakerId &&
                                        <div className={`stop-btn-yellow ${uniqueIdentifier === selectedQuestionObjection ? 'active-question' : ''}`} id={data.participant.id + 'stop-btn-yellow' + key}
                                          key={key}
                                          style={{
                                            left: this.getleftValue(item.start) + '%'
                                          }}
                                          onClick={(event) => this.updateSeek(event, item.start, uniqueIdentifier)}
                                          >
                                            <div className='line-icon'><img src={questionImg} alt='question'/></div>
                                          </div>
                                      ))
                                    })}

                                  </div>
                                </div>
                              ))
                            }

                            {currentuser?.feature_flags?.add_participants_nonlive && meeting && (
                              <button
                                className="btn btn-secondary edit-participants-btn"
                                onClick={() => { this.setState({ isEditingParticipants: true }) }}
                              >
                                Edit
                              </button>
                            )}

                            <div className="video-line" id="video-line-main" onClick={(event) => this.updateSeek(event)}>
                              <div id="video-inner-line"
                                className={playing ? "playing-transition video-inner-line" : 'video-inner-line'}
                                style={{ left: `${progress}%` }}
                              >
                                <span>{currentTime}</span>
                              </div>
                              {showSnippetDiv &&
                                <div id='resizable-div' style={{ width: '100%', height: '35px' }}>
                                  <Rnd
                                    id='resizable-rnd-div'
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      background: "rgb(47, 143, 212, 0.45)",
                                      borderRadius: "5px",
                                      height: `calc(${meetingAIAnalytics?.length || 1} * 35px + 15)`,
                                      border: "3px solid #3C64F0",
                                    }}
                                    default={{
                                      x: this.state.axisX,
                                      y: this.state.axisY,
                                      width: this.state.snippetDivWidth,
                                      height: (meetingAIAnalytics?.length || 1) * 35 + 15
                                    }}
                                    size={{ width: this.state.snippetDivWidth, height: (meetingAIAnalytics?.length || 1) * 35 + 15 }}
                                    position={{ x: this.state.axisX, y: this.state.axisY }}
                                    enableResizing={{ right: true }}
                                    dragAxis="x"
                                    bounds='#resizable-div'
                                    onDragStop={(e, d) => {
                                      let childElement = document.getElementById('snippet-show-icons');
                                      let childElement2 = document.getElementById('snippet-play-icon');
                                      if (e.target !== childElement && e.target !== childElement2) {
                                        this.setState({ axisX: d.x });
                                        this.recenterSnippetDiv(false);
                                      }
                                    }}
                                    onResizeStop={(e, direction, ref, delta, position) => {
                                      this.recenterSnippetDiv(false);
                                      this.setState({
                                        snippetDivWidth: parseInt(ref.style.width),
                                      });
                                    }}
                                  >
                                  <div className="d-flex pl-2 pr-2 w-100">
                                    <i className={showSnippetForm ? 'fa fa-minus' : 'fa fa-plus'}
                                      id="snippet-show-icons"
                                      aria-hidden="true"
                                      style={{ cursor: 'pointer' }}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        this.setState((prevState) => ({ showSnippetForm: !prevState.showSnippetForm }))
                                      }}
                                    >
                                    </i>
                                    <i
                                      className="fa fa-times-circle ml-auto"
                                      id="snippet-remove-icon"
                                      aria-hidden="true"
                                      style={{ cursor: 'pointer' }}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        this.setState(() => ({ showSnippetDiv: false }))
                                      }}
                                    >
                                    </i>
                                    <i className="fa fa-play ml-2"
                                      id="snippet-play-icon"
                                      aria-hidden="true"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        this.handlePlaySnippet();
                                      }}
                                      style={{ cursor: 'pointer' }}
                                    >
                                    </i>
                                  </div>
                                    <div className='rnd-tooltip'>
                                      <div
                                        onClick={(event) => {
                                          event.preventDefault();
                                          this.setState((prevState) => ({
                                            showShareModal: !prevState.showShareModal,
                                            shareRecording: true,
                                            showSnippetDiv: false,
                                            snippetUuid: null,
                                            snippetName: `${meetingName} ${formatTime(snippetStartSeconds)}-${formatTime(snippetEndSeconds)}`,
                                            shareToUser: [],
                                          }))
                                        }}
                                      >Share snippet</div>
                                      <div
                                        onClick={(event) => {
                                          event.preventDefault();
                                          this.setState((prevState) => ({
                                            showSnippetForm: !prevState.showSnippetForm,
                                            showSnippetDiv: false,
                                            shareRecording: false,
                                            snippetUuid: null,
                                            snippetName: `${meetingName} ${formatTime(snippetStartSeconds)}-${formatTime(snippetEndSeconds)}`,
                                            shareToUser: [],
                                          }))
                                        }}
                                      >Save to snippets</div>
                                      <div
                                        onClick={(event) => {
                                          event.preventDefault();
                                          this.handlePlaySnippet();
                                        }}
                                      >Play selection</div>
                                      <div
                                        onClick={(event) => {
                                          event.preventDefault();
                                          this.setState(() => ({ showSnippetDiv: false }));
                                        }}
                                      >Cancel</div>
                                    </div>
                                  </Rnd>
                                </div>
                              }
                            </div>
                          </div>

                          <div className="video-thumbnail">
                            <div className="d-flex justify-content-between mt-2">
                              <span>{currentTime}</span>
                              <span>{duration}</span>
                            </div>
                          </div>

                          {!isEmpty(recording?.screenshots) &&
                            <>
                              <h6 className='thumbnails-title'>Thumbnails</h6>
                              {
                                this.props.recordingTranscript?.phases && (<ThumbnailPhases data={this.props.recordingTranscript.phases} />)
                              }
                              <List
                                className='thumbnails-line'
                                type='unstyled'
                              >
                                {recording.screenshots.map((url: string, key: number) => {
                                  return (
                                    <li key={key}>
                                      <img src={resizeImage(url, 'thumbnail')} alt='thumbnail' />
                                    </li>
                                  );
                                })}
                              </List>
                            </>
                          }
                        </Col>
                        {showComments && (
                          <Col lg="12">
                            <Comments
                              accountId={accountId}
                              progress={progress}
                              timeLineSessionId={this.props.sessionId}
                              timeLineTargetTime={currentTimeSeconds}
                              timeLineDuration={durationSeconds}
                              timeLineUpdateSeek={this.updateSeek}
                              timeLineUpdateSeekDirect={this.updateSeekDirect}
                              timeLineHandleCut={this.recenterSnippetDiv}
                              //Snippets
                              showAddSnippet={showAddSnippet}
                              showSnippetForm={showSnippetForm}
                              toggleAddSnippet={this.toggleAddSnippet}
                              snippetStartSeconds={snippetStartSeconds}
                              snippetEndSeconds={snippetEndSeconds}
                              handleChangeSnippetName={this.handleChangeSnippetName}
                              handleSnippetSubmit={this.handleSnippetSubmit}
                              showAddComment={this.state.showAddCommentModal}
                              changeShowAddCommentModal={this.changeShowAddCommentModal}
                              closeRndTooltip={this.closeRndTooltip}
                              closeAddCommentModal={this.closeAddCommentModal}
                            />
                          </Col>
                        )}
                      </Container>
                    )}
                    <Container>
                      {recordingTranscript?.transcript && (
                        <Row className="mb-3 mt-3 transcript">
                          <Col md="10">
                            <div className="transcript-search pl-3">
                              <div className="input-group">
                                <div className="transcript-search__icon">
                                  <span className="input-group-text"><i className="fas fa-search" /></span>
                                </div>
                                <input
                                  className="transcript-search__input"
                                  type="text"
                                  placeholder="Search transcript"
                                  onChange={this.handleSearchTranscript}
                                  value={this.state.searchQuery}
                                />
                                <div className="transcript-search__result">
                                  <span>
                                    {searchQuery && `(${searchItemIds.length ? searchItem + 1 : 0}/${searchItemIds.length})`}
                                  </span>
                                </div>
                                <div className="transcript-search__icon" onClick={this.handleSearchTranscriptPrev} >
                                  <span className="input-group-text">
                                    <i className="fas fa-chevron-up" />
                                  </span>
                                </div>
                                <div className="transcript-search__icon" onClick={this.handleSearchTranscriptNext} >
                                  <span className="input-group-text">
                                    <i className="fas fa-chevron-down" />
                                  </span>
                                </div>
                                <div className="transcript-search__icon" onClick={this.handleSearchTranscriptCancel} >
                                  <span className="input-group-text">
                                    <i className="fas fa-times" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col
                            md="2"
                            className="transcript-actions m-auto"
                          >
                            <FormGroup check className="checkbox">
                              <Input
                                type="checkbox"
                                onChange={() => this.setState({ transcriptAutoScroll: !this.state.transcriptAutoScroll })}
                                checked={this.state.transcriptAutoScroll}
                                id="transcriptAutoScroll"
                              />
                              <Label
                                for="transcriptAutoScroll"
                                check
                              >
                                Transcript Auto Scroll
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                      {showTranscription && (
                        <div className="transcription">
                          <Col className="transcript__nav p-2 pl-4">
                            <Nav
                              tabs
                              className="tab-navigation"
                            >
                              {meetingNavArray.map((item, index) => {
                                const showTab = !showTabs
                                  || some(showTabs, (tab) => tab === item.activeTab);

                                return (showTab ? (
                                  <NavItem
                                    key={index}
                                    className={`d-flex ${item.activeTab}`} style={{ margin: 0 }}
                                  >
                                    <NavLink
                                      style={{ cursor: "pointer", margin: 0, padding: 0 }}
                                      className={classnames({
                                        active: this.state.activeMeetingTab === item.activeTab,
                                      })}
                                      onClick={() => {
                                        this.toggleMeetingTab(item.activeTab);
                                      }}
                                    >
                                      {item.name}
                                    </NavLink>
                                  </NavItem>
                                ) : null);
                              })}
                            </Nav>
                            <div className="transcript__nav__action-buttons">
                              { recordingTranscript?.translation && (
                                <div className="video-player-switch-component">
                                  <SwitchComponent
                                    label={'English'}
                                    checked={this.state.useTranslation}
                                    onChange={() => this.setState(
                                      prevState => ({
                                        useTranslation: !prevState.useTranslation,
                                      }),
                                    )}
                                  />
                                </div>
                              )}
                              {this.state.activeMeetingTab === 'tab-transcript' &&
                                  transcriptCanBeRemapped &&
                                  !this.state.showFixSpeakers && (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="modal-dropdown d-inline-block ml-auto">
                                      <button
                                        className="btn btn-primary ml-1"
                                        onClick={() => this.setState({ showFixSpeakers: true })}
                                      >
                                        Edit speakers
                                      </button>
                                    </div>
                                  </div>
                                )}
                              {recordingTranscript &&
                                recordingTranscript.status !== 'pending' && (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="modal-dropdown d-inline-block ml-auto">
                                      {canExportCSV && (
                                        <Button
                                          className="btn-blue ml-1"
                                          onClick={this.handleDownloadTranscriptCSV}
                                        >
                                          Export Transcript
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </Col>
                          <Col lg="12" className={this.state.activeMeetingTab}>
                            <TabContent activeTab={this.state.activeMeetingTab}>
                              <TabPane tabId="tab-transcript">
                                <div className="video-player-tab-content-wrapper">
                                  <Container className="transcript mt-2 pl-0">
                                    <Col lg="12" className="pl-0">
                                        {recordingTranscript ? (
                                          recordingTranscript.status === 'pending' ? (
                                            <h4 className="mt-4 text-center">{message}</h4>
                                          ) : (
                                            <>
                                              {showFixSpeakers && meeting && (
                                                <div className="match-speakers-to-participants" ref={this.matchSpeakersEl}>
                                                  {Object.keys(speakerMap)
                                                    .sort()
                                                    .map((originalSpeakerId: any) => (
                                                      <div className="speaker-match-row" key={originalSpeakerId}>
                                                        <div className="speaker-label">
                                                          {
                                                            this.state.speakerDisplayNames?.[originalSpeakerId] ?
                                                              `Speaker ${this.state.speakerDisplayNames?.[originalSpeakerId]}` :
                                                              originalSpeakerId
                                                          }
                                                        </div>
                                                        <button
                                                          className="btn btn-secondary longest-speech-region-btn"
                                                          onClick={() => this.goToLongestSpeechRegion(
                                                            originalSpeakerId,
                                                            remappedTranscript
                                                          )}
                                                        >
                                                          Play longest section
                                                        </button>
                                                        <div>
                                                          <Select
                                                            options={speakerOptions}
                                                            value={speakerOptions.find(({ value }) => `${value}` === `${speakerMap[originalSpeakerId]}`)}
                                                            onChange={(e: any) => this.onSpeakerSelected(originalSpeakerId, e)}
                                                          />
                                                        </div>
                                                      </div>
                                                  ))}
                                                  <div className="speaker-match-row speaker-match-actions">
                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                      <button
                                                        className="btn btn-secondary"
                                                        onClick={() => this.resetSpeakerMap()}
                                                      >
                                                        Reset to Default
                                                      </button>
                                                      <button
                                                        className="btn btn-secondary"
                                                        onClick={() => { this.setState({ isEditingParticipants: true }) }}
                                                      >
                                                        Edit participants
                                                      </button>
                                                    </div>


                                                    <div>
                                                      <button
                                                        className="btn btn-secondary"
                                                        onClick={this.restoreSpeakerMap}
                                                      >
                                                        Cancel
                                                      </button>
                                                      <button
                                                        className="btn btn-primary ml-1"
                                                        onClick={this.saveSpeakerMap}
                                                      >
                                                        Save
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                              <VirtualizedList
                                                ref={this.virtualizedList}
                                                style={{ width: '100%' }}
                                                height={400}
                                                width={1000}
                                                itemCount={transcriptListData!.length}
                                                itemData={transcriptListData}
                                                estimatedItemSize={100}
                                                itemSize={this.calculateVirtualizedListItemHeight}
                                              >
                                                {this.VirtualizedListRow}
                                              </VirtualizedList>
                                            </>
                                          )
                                        ) : (
                                          <h4 className="mt-4 text-center">{message}</h4>
                                        )}
                                    </Col>
                                  </Container>
                                </div>
                              </TabPane>
                              <TabPane tabId="tab-questions">
                                <div className="video-player-tab-content-wrapper">
                                  <Container className="transcript mt-2 pl-0">
                                    <Col lg="12" className="pl-0">
                                      {
                                        questions.length ? (
                                          <VirtualizedList
                                            style={{ width: '100%' }}
                                            height={400}
                                            width={1000}
                                            itemCount={useTranslation ? translatedQuestions.length : questions.length}
                                            itemData={useTranslation ? translatedQuestions : questions}
                                            estimatedItemSize={100}
                                            itemSize={this.calculateVirtualizedListItemHeight}
                                          >
                                            {this.QAndAVirtualizedRow}
                                          </VirtualizedList>
                                        ) : (
                                          <div>
                                            <h4 className="mt-4 text-center">
                                              {
                                                'There are no questions.'
                                              }
                                            </h4>
                                          </div>
                                        )
                                      }
                                    </Col>
                                  </Container>
                                </div>
                              </TabPane>
                              <TabPane tabId="tab-summary">
                                <div className="video-player-tab-content-wrapper">
                                  <Container className="transcript mt-2 pl-0">
                                    <Col lg="12" className="pl-0">
                                      {
                                        recordingTranscript?.summary ? (
                                          <div className="transcript-summary">
                                            <div className="transcript-summary-section">
                                              <p className="transcript-summary-text"
                                                 dangerouslySetInnerHTML={formatSummary(recordingTranscript.summary.summary_dashboard ?? '')}></p>
                                            </div>
                                          </div>
                                        ) : (
                                          <div>
                                            <h4 className="mt-4 text-center">
                                              {
                                                'There is no summary.'
                                              }
                                            </h4>
                                          </div>
                                        )
                                      }
                                    </Col>
                                  </Container>
                                </div>
                              </TabPane>
                              <TabPane
                                className="video-player-playbook-tab"
                                tabId="tab-playbook"
                              >
                                <div className="video-player-tab-content-wrapper">
                                  <Container className="transcript mt-2 pl-0">
                                    <Col lg="12" className="pl-0">
                                      {
                                        null !== allPlaybooksForSession
                                        && undefined !== allPlaybooksForSession
                                        && allPlaybooksForSession.length > 0 && (
                                          <div className="video-player-playbook-tab__agent-selector">
                                            <Label className="video-player-playbook-tab__agent-selector__label">
                                              Select Playbook agent:
                                            </Label>
                                            <div style={{ width: '50%' }}>
                                              <Select
                                                className="video-player-playbook-tab__agent-selector__select"
                                                value={selectedPlaybookDropdown}
                                                options={playbookAgentsDropdown}
                                                onChange={(option) => this.handlePlaybookSelect(option!)}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      {selectedPlaybook && !isEmpty(selectedPlaybook.response) ? (
                                        <AIAgentResponsePreview
                                          className="video-player-playbook"
                                          agentType={selectedPlaybook.agentType}
                                          aiResponse={selectedPlaybook.response}
                                          playbookSummary={selectedPlaybook.playbookSummary}
                                          playbookSummaryPosition="top"
                                          isStatusLoading={false}
                                          onClickTranscript={this.handleAgentTranscriptSeek}
                                        />
                                      ) : (
                                        <div>
                                          <h4 className="mt-4 text-center">
                                            {
                                              'No playbooks were followed for this meeting.'
                                            }
                                          </h4>
                                        </div>
                                      )}
                                    </Col>
                                  </Container>
                                </div>
                              </TabPane>
                              <TabPane
                                className="video-player-playbook-tab"
                                tabId="tab-data_insights"
                              >
                                <div className="video-player-tab-content-wrapper">
                                  <Container className="transcript mt-2 pl-0">
                                    <Col lg="12" className="pl-0">
                                      {
                                        null !== allMagicVariableForSession
                                        && undefined !== allMagicVariableForSession
                                        && allMagicVariableForSession.length > 0 && (
                                          <div className="video-player-playbook-tab__agent-selector">
                                            <Label className="video-player-playbook-tab__agent-selector__label">
                                              Select Magic Variables agent:
                                            </Label>
                                            <div style={{ width: '50%' }}>
                                              <Select
                                                className="video-player-playbook-tab__agent-selector__select"
                                                value={selectedMagicVariablesDropdown}
                                                options={dataInsightsAgentDropdown}
                                                onChange={(option) => this.handleMagicVariableSelect(option!)}
                                              />
                                            </div>
                                          </div>
                                        )}
                                      {selectedMagicVariables && !isEmpty(selectedMagicVariables.response) ? (
                                        <AIAgentResponsePreview
                                          className="video-player-playbook"
                                          agentType={selectedMagicVariables.agentType}
                                          aiResponse={selectedMagicVariables.response}
                                          playbookSummary={undefined}
                                          playbookSummaryPosition="top"
                                          isStatusLoading={false}
                                          onClickTranscript={this.handleAgentTranscriptSeek}
                                        />
                                      ) : (
                                        <div>
                                          <h4 className="mt-4 text-center">
                                            {
                                              'No data insights for this meeting.'
                                            }
                                          </h4>
                                        </div>
                                      )}
                                    </Col>
                                  </Container>
                                </div>
                              </TabPane>
                              <TabPane tabId="tab-chat">
                                <div className="video-player-tab-content-wrapper">
                                  <Container className="transcript pl-0">
                                    <Col lg="12" className="pl-0">
                                      {recordingTranscript?.transcript?.length ? (
                                        <GptPrompt sessionId={sessionId}/>
                                      ) : (
                                        <div>
                                          <h4 className="mt-4 text-center">
                                            Unable to send prompt for a meeting with no transcript.
                                          </h4>
                                        </div>
                                      )}
                                    </Col>
                                  </Container>
                                </div>
                              </TabPane>
                            </TabContent>
                          </Col>
                        </div>
                      )}
                    </Container>
                  </Row>
                }
                {showResetSpeakersModal && (
                  <SweetAlert
                    title="Reset Speakers to AI-Generated Settings?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => this.confirmResetSpeakers()}
                    onCancel={() => this.cancelResetSpeakers()}
                  >
                    <p className="video-player-modal-text" style={{ marginBottom: '1rem' }}>
                      This will revert all speaker settings to their default state and regenerate statistics
                      if any changes are detected. Please note, this action is irreversible.
                    </p>
                  </SweetAlert>
                )}
                {showNoSentencesModal && (
                  <SweetAlert
                    title="No speech region found"
                    info
                    confirmBtnBsStyle="success"
                    onConfirm={() => this.setState({ showNoSentencesModal: false })}
                  >
                    <p className="video-player-modal-text" style={{ marginBottom: 0 }}>
                      No sentences spoken by this participant were found in the transcript.
                    </p>
                    <p className="video-player-modal-text">
                      If this is incorrect, please edit the transcript by clicking on the
                      "Edit speakers" button and selecting the appropriate participants.
                    </p>
                  </SweetAlert>
                )}
              </div>
                : !recordingLoader ?
                  <Row className="metting-main m-0 pt-4 pb-0 h-90vh">
                    <Container>

                      <Col lg="12" className="d-flex justify-content-center align-items-center h-100 ">

                        <div className="text-center">
                          <div className="loader-wrapper">
                            <img src={loaderImg} alt="" />
                          </div>
                        </div>

                      </Col>
                    </Container>
                  </Row> :
                  <Row className="metting-main m-0 pt-4 pb-0">
                    <Container className="text-center">
                      <div className="loader-wrapper">
                        <img src={loaderImg} alt="" />
                      </div>
                    </Container>
                  </Row>
              }
            </div>
            {showComments && (
              <div className="meeting-right-sidebar d-flex flex-column">
                {showSnippets && (
                  <Nav tabs className="mt-2 mb-0 border-bottom">
                    {navArray.map((item, index) => {
                      return (
                        <NavItem key={index} className="d-flex border-right">
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: this.state.activeTab === item.activeTab,
                            })}
                            onClick={() => {
                              this.toggleTab(item.activeTab);
                            }}
                          >
                            {item.name}
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Nav>
                )}
                <Card className="tab-wrapper">
                  <CardBody className="pt-2 px-0">
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <CommentList
                          accountId={accountId}
                          progress={progress}
                          timeLineSessionId={this.props.sessionId}
                          timeLineUpdateSeekDirect={this.updateSeekDirect}
                          showAddSnippet={showAddSnippet}
                          timeLineTargetTime={currentTimeSeconds}
                          canComment={canComment}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        {snippets && (
                          <Col lg="12">
                            <Snippets
                              snippets={snippets}
                              onSnippetClick={this.handleSnippetClick}
                              onSnippetShare={this.handleSnippetShare}
                              addNewSnippet={this.recenterSnippetDiv}
                              history={history}
                            />
                          </Col>
                        )}
                      </TabPane>
                    </TabContent>
                    {(showShareModal || showSnippetForm) && (
                      <SaveAndShareModal
                        isEdit={!!this.state.snippetUuid}
                        snippetStartSeconds={snippetStartSeconds}
                        snippetEndSeconds={snippetEndSeconds}
                        snippetName={this.state.snippetName}
                        snippetDescription={this.state.snippetDescription}
                        handleChangeSnippetName={this.handleChangeSnippetName}
                        handleChangeSnippetDescription={this.handleChangeSnippetDescription}
                        shareRecording={this.state.shareRecording}
                        handleSnippetSubmit={this.handleSnippetSubmit}
                        toggleAddSnippet={this.toggleAddSnippet}
                        meeting={meeting}
                        onSelectSharedTarget={this.onSelectSharedTarget}
                        onHandleGeneralAccess={this.onHandleGeneralAccess}
                        groupedOptions={groupedOptions}
                        shareToUser={this.state.shareToUser}
                        generalAccess={this.state.generalAccess}
                        removeShareUser={this.removeShareUser}
                        canViewHandler={this.canViewHandler}
                        canCommentHandler={this.canCommentHandler}
                        handleSubmitCreateAndShare={this.handleSubmitCreateAndShare}
                        showClipboardMessage={this.state.showClipoardMessage}
                        changeShareRecording={this.changeShareRecording}
                        closeShareModal={this.closeShareModalHandler}
                      />
                    )}
                  </CardBody>
                </Card>
              </div>
            )}

            {meeting && (
              <Modal centered size="xl"
                className="edit-participants-modal"
                isOpen={isEditingParticipants}
                toggle={this.cancelEditParticipants}
              >
                <ModalHeader
                  close={
                    <CloseButton handleClose={this.cancelEditParticipants} />
                  }
                >
                  Edit participants
                </ModalHeader>
                <ModalBody>
                  <EditParticipantsList
                    participants={this.state.participantsDraft}
                    onUpdate={(participants: any[]) => {
                      this.setState({ participantsDraft: participants });
                    }}
                    disabledParticipantIds={
                      meeting!.participants
                        ?.filter((participant: any) => !participant.isAddedLater)
                        .map(({ id }: { id: any }) => id)
                      }
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => {
                      updateMeetingParticipants(
                        meeting!.id,
                        this.state.participantsDraft,
                      );
                      this.setState({ isEditingParticipants: false });
                    }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected } = state.Profile;

  return { currentaccountselected };
}

export default connect(mapStateToProps, { getAllTagsByAccount })(VideoPlayer);
